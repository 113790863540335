import {
  GetOperatorsByOwnerAddress,
  GetValidatorsByOwnerAddress,
} from "../../Redux/Apis/ApiService";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import calculateTransactionFee from "../../Utils/calculateTransactionFee";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import CopyButton from "../../Helpers/CopyButton";
import SearchBar from "../../Helpers/SearchBar";
import { Tooltip } from "@mui/material";
import { Table } from "../Tables";

export default function TxnDetailsByAddress() {
  const { address, blockNumber, source, blockHash } = useParams();
  const [Tab, setTab] = useState("Txns");
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const txnDetails = useSelector((state) => state.queryInputData);
  const operatorData = useSelector((state) => state.operatorByAddress);
  const validatorData = useSelector((state) => state.validatorByAddress);

  useEffect(() => {
    document.title =
      "Block Sail | Details By Wallet Address, Block Number & Block Hash";
  }, []);

  const columns = useMemo(
    () => [
      {
        id: "hash",
        label: "TRANSACTION HASH",
        minWidth: 170,
        style: {
          color: "#04C2AE",
        },
        anchor: true,
        format: (hash) => {
          const has1 = hash?.slice(0, 6);
          const has2 = hash?.slice(hash.length - 6, hash.length);
          return has1 && has2 ? has1 + "..." + has2 : "NA";
        },
      },
      {
        id: "blockNumber",
        label: "BLOCK",
        minWidth: 170,
        anchor: true,
        style: {
          color: "#04C2AE",
        },
        toolTip: false,
        format: (value) => value,
      },
      {
        id: "from",
        label: "FROM",
        minWidth: 170,
        anchor: true,
        format: (hash) => {
          const has1 = hash?.slice(0, 6);
          const has2 = hash?.slice(hash.length - 6, hash.length);
          return has1 && has2 ? has1 + "..." + has2 : "NA";
        },
        style: {
          color: "#04C2AE",
        },
      },
      {
        id: "to",
        label: "TO",
        minWidth: 170,
        anchor: true,
        format: (hash) => {
          const has1 = hash?.slice(0, 6);
          const has2 = hash?.slice(hash.length - 6, hash.length);
          return has1 && has2 ? has1 + "..." + has2 : "NA";
        },
        style: {
          color: "#04C2AE",
        },
      },
      {
        id: "value",
        label: "VALUE",
        minWidth: 170,
        format: (value) => `${parseInt(value, 16)} ETH`,
        toolTip: false,
      },
      {
        id: "gasUsed",
        label: "TXN FEE",
        minWidth: 170,
        align: "center",
        toolTip: false,
        format: (value, row) => {
          const txnFee = calculateTransactionFee(value, row.gasPrice, 11);
          return `${txnFee ? txnFee : ""} ETH`;
        },
      },
    ],
    [txnDetails]
  );

  useEffect(() => {
    dispatch(GetOperatorsByOwnerAddress({ value: address }));
    dispatch(GetValidatorsByOwnerAddress({ value: address }));
  }, [dispatch, address]);

  const columns1 = useMemo(
    () => [
      {
        id: "publicKey",
        label: "PUBLIC KEY",
        minWidth: 170,
        style: {
          color: "#01BF97",
        },
        anchor: true,
        format: (hash) => {
          const has1 = hash?.slice(0, 6);
          const has2 = hash?.slice(hash.length - 6, hash.length);
          return has1 && has2 ? has1 + "..." + has2 : "NA";
        },
      },
      {
        id: "operatorIds",
        label: "OPERATORS",
        minWidth: 170,
        align: "center",
        toolTip: false,
        format: (value) => {
          return (
            <div className="flex space-x-2 justify-center items-center text-center w-full">
              {value?.map((item, i) => (
                <Tooltip
                  title={`Click to see operator details`}
                  placement="top"
                  arrow
                >
                  <span
                    onClick={() =>
                      navigate(`/explorer/${source}/operators/${item}`)
                    }
                    className="px-2 py-1 text-xs cursor-pointer rounded-lg bg-slate-100 border shadow-sm"
                    key={i}
                  >
                    {item}
                  </span>
                </Tooltip>
              ))}
            </div>
          );
        },
      },
      {
        minWidth: 170,
        style: {
          color: "#01BF97",
        },
        align: "right",
        toolTip: false,
        format: (_, row) => {
          return (
            <div className="flex justify-end space-x-3">
              <span className="px-1 py-1 rounded-lg border h-6 border-gray-200 -mt-1 hover:border-[#04D1BE]/50">
                <CopyButton value={row.publicKey} />
              </span>
              <Link
                to={`https://prater.beaconcha.in/validator/${row.publicKey}`}
                target="_blank"
                className="cursor-pointer flex pr-10"
              >
                <span className="px-1 py-1 h-6 rounded-lg -mt-1 -mr-2 border border-gray-200 hover:border-[#04D1BE]/50 ">
                  <svg
                    className="ml-0.5 mt-0.5 text-[#7D8398] hover:text-[#04D1BE]/50"
                    width="14"
                    height="11"
                    viewBox="0 0 16 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.888 7.35662C13.3025 1.42948 6.10773 -1.39402 0.181151 1.1927L0.98087 3.02553C5.91549 0.872393 11.902 3.22172 14.0551 8.15634L15.888 7.35662ZM12.2119 9.15284C11.3712 7.22447 9.7956 5.72633 7.77558 4.9336C5.75556 4.14087 3.58157 4.16753 1.65375 5.00947L2.45347 6.84229C3.89225 6.2145 5.52239 6.19679 7.04532 6.79444C8.56788 7.39302 9.75124 8.51378 10.379 9.95256L12.2119 9.15284ZM7.44538 11.2495C7.04187 12.2777 5.88121 12.7842 4.85298 12.3806C3.82476 11.9771 3.31833 10.8165 3.72184 9.78825C4.12535 8.76002 5.28601 8.25359 6.31423 8.65711C7.34246 9.06062 7.84889 10.2213 7.44538 11.2495Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </Link>
            </div>
          );
        },
      },
    ],
    [validatorData]
  );

  const columns2 = useMemo(
    () => [
      {
        id: "operatorId",
        label: "OPERATOR ID",
        minWidth: 170,
        style: {
          color: "#01BF97",
        },
        anchor: true,
        toolTip: false,
      },
      {
        id: "ownerAddress",
        label: "OWNER ADDRESS",
        minWidth: 170,
        style: {
          color: "#01BF97",
        },
        anchor: true,
        format: (hash) => {
          const has1 = hash?.slice(0, 6);
          const has2 = hash?.slice(hash.length - 6, hash.length);
          return has1 && has2 ? has1 + "..." + has2 : "NA";
        },
      },
      {
        id: "transactionHash",
        label: "TXN HASH",
        minWidth: 170,
        style: {
          color: "#01BF97",
        },
        anchor: true,
        format: (hash) => {
          const has1 = hash?.slice(0, 6);
          const has2 = hash?.slice(hash.length - 6, hash.length);
          return has1 && has2 ? has1 + "..." + has2 : "NA";
        },
      },
      {
        id: "noOfValidators",
        label: "VALIDATORS",
        minWidth: 170,
        // style: {
        //     color: "#01BF97"
        // },
        // toolTip: false,
        // anchor: true,
      },
      {
        id: "isRegistered",
        label: "STATUS",
        minWidth: 170,
        align: "center",
        toolTip: false,
        format: (value) => {
          return (
            <span
              className={`${
                value
                  ? "bg-green-100 text-green-500"
                  : "bg-red-100 text-red-500"
              } text-xs font-medium border px-2 py-1.5 rounded-md`}
            >
              {value ? "REGISTERED" : "REMOVED"}
            </span>
          );
        },
      },
    ],
    [operatorData]
  );

  return (
    <div className="w-full mb-8">
      <div className="py-8 mb-4 border-b border-t bg-white px-4 flex justify-between">
        <div className="lg:px-3 px-1 py-1 flex justify-between w-full items-center lg:flex-row flex-col lg:space-y-0 space-y-2">
          <div className="flex space-x-1 text-center justify-start items-center">
            <div className="flex space-x-1">
              <h1 className="text-xl font-[500] text-gray-800">
                {state?.parameter === "blockNumber"
                  ? "Block"
                  : state?.parameter === "blockHash"
                  ? "Block Hash"
                  : "Address"}
                :
              </h1>
              <span className="text-black mt-1 font-normal truncate w-20 lg:w-fit">
                {blockHash ? blockHash : blockNumber ? blockNumber : address}
              </span>
              <span className="mt-2 text-gray-500 cursor-pointer hover:text-[#04D1BE]/50">
                <CopyButton
                  value={
                    blockHash ? blockHash : blockNumber ? blockNumber : address
                  }
                />
              </span>
            </div>
          </div>
          <div className="max-w-xl w-full">
            <SearchBar id={"Small"} />
          </div>
        </div>
      </div>
      <div className="w-full py-12">
        {address ? (
          <div className="space-y-3">
            <div className="flex lg:space-x-4 space-x-1">
              <div
                onClick={() => setTab("Txns")}
                className={`${
                  Tab === "Txns"
                    ? "bg-[#01BF97] text-white"
                    : "bg-[#E5E5E5] text-[#0B2244]"
                } rounded-xl font-semibold px-4 py-1.5 w-36 cursor-pointer`}
              >
                <span className="w-full flex text-center justify-center text-lg items-center">
                  Transactions
                </span>
              </div>
              {validatorData?.data?.length ? (
                <div
                  onClick={() => setTab("Validators")}
                  className={`${
                    Tab === "Validators"
                      ? "bg-[#01BF97] text-white"
                      : "bg-[#E5E5E5] text-[#0B2244]"
                  } rounded-xl font-semibold px-4 py-1.5 w-36 cursor-pointer`}
                >
                  <span className="w-full flex text-center justify-center text-lg items-center">
                    Validators
                  </span>
                </div>
              ) : null}
              {operatorData?.data?.length ? (
                <div
                  onClick={() => setTab("Operators")}
                  className={`${
                    Tab === "Operators"
                      ? "bg-[#01BF97] text-white"
                      : "bg-[#E5E5E5] text-[#0B2244]"
                  } rounded-xl font-semibold px-4 py-1.5 w-36 cursor-pointer`}
                >
                  <span className="w-full flex text-center justify-center text-lg items-center">
                    Operators
                  </span>
                </div>
              ) : null}
            </div>
            {Tab === "Txns" && (
              <Table
                columns={columns}
                rows={txnDetails?.data?.length ? txnDetails?.data : []}
                tableId={"AddressTxn"}
                isLoading={txnDetails.isLoading}
                pagiNation={true}
                totalPages={txnDetails?.totalPages}
                totalRecords={txnDetails?.totalRecords}
              />
            )}
            {Tab === "Validators" && (
              <Table
                columns={columns1}
                rows={validatorData?.data?.length ? validatorData?.data : []}
                tableId={"validatorsByAddress"}
                isLoading={validatorData.isLoading}
                pagiNation={true}
                totalPages={validatorData?.totalPages}
                totalRecords={validatorData?.totalRecords}
              />
            )}
            {Tab === "Operators" && (
              <Table
                columns={columns2}
                rows={operatorData?.data?.length ? operatorData?.data : []}
                tableId={"operatorsByAddress"}
                isLoading={operatorData.isLoading}
                pagiNation={true}
                totalPages={operatorData?.totalPages}
                totalRecords={operatorData?.totalRecords}
              />
            )}
          </div>
        ) : (
          <Table
            heading={"Transactions"}
            columns={columns}
            rows={txnDetails?.data?.length ? txnDetails?.data : []}
            tableId={"AddressTxn"}
            isLoading={txnDetails.isLoading}
            pagiNation={true}
            totalPages={txnDetails?.totalPages}
            totalRecords={txnDetails?.totalRecords}
          />
        )}
      </div>
    </div>
  );
}

