import { GetDuties } from "../Apis/ApiService";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
    isLoading: true,
    totalRecords: null,
    totalPages: null,
    error: null,
};
export const DutiesSlice = createSlice({
    name: "duties",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetDuties.pending, (state, action) => {
                if (action?.meta?.arg?.limit && action?.meta?.arg?.page) {
                    if (action?.meta?.arg?.limit * action?.meta?.arg?.page <= state?.data?.length) {
                        state.isLoading = false;
                    } else {
                        state.isLoading = true;
                    }
                }
            })
            .addCase(GetDuties.fulfilled, (state, action) => {
                if (action?.meta?.arg?.limit && action?.meta?.arg?.page > 1) {
                    state.data = [...state.data, ...action?.payload?.duties?.filter(obj2 => !state?.data?.some(obj1 => obj1?._id === obj2?._id))];
                    state.totalRecords = action?.payload?.totalRecords;
                    state.totalPages = action?.payload?.totalPages
                    state.isLoading = false;
                } else {
                    state.data = action.payload.duties
                    state.totalRecords = action?.payload?.totalRecords;
                    state.totalPages = action?.payload?.totalPages
                    state.isLoading = false;
                }
            })
            .addCase(GetDuties.rejected, (state, action) => {
                state.data = []
                state.error = action.error.message;
                state.isLoading = false;
            });
    },

});

export default DutiesSlice.reducer;