import { GetChatData } from "../Apis/ApiService";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  isLoading: false,
  error: null,
};
export const AiData = createSlice({
  name: "AiData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetChatData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetChatData.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      })
      .addCase(GetChatData.rejected, (state, action) => {
        state.error = action.error.message;
        state.data = action.payload;
        state.isLoading = false;
      });
  },
});

export default AiData.reducer;
