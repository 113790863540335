// ---> Auth API's EndPoints
export const changePassword = "/user/changePassword";
export const getNewAccessToken = "/user/refreshToken";
export const login = "/user/login";

// ---> User API's EndPoints
export const getUserByid = "/user/get";

//---> Data API's EndPoints
export const getInputData = "/inputData/get";
export const getSearchData = "/inputData/show";
export const getDataSource = "/dataSource/show";
export const getBlockData = "/inputData/display";
export const createInputData = "/dataSource/create";
export const getQueryInputData = "/inputData/getDataFromDB";
// export const getLogsData = "/transactionReceipt/getHashData"
export const getLogsData = "/transactionReceipt/getData";

//Operator API's
export const getTestnetOperatorsDetails =
  "https://api.ssv.network/api/v4/prater/operators";
export const getMainnetOperatorsDetails =
  "https://api.ssv.network/api/v4/mainnet/operators";
export const getOperators = "/operator/get";
export const getOperatorsByAddress = "/operator/getData";
export const getValidatorByAddress = "/validator/getData";

//Validator API's
export const getValidators = "/validator/show";
export const getDuties = (network) => `/duties/${network}/get`;

// Chat Gpt API's
export const getTextToMql = "/AI";
export const getAlldata = "/AI/findall";
export const getDatabyID = "/AI/findbyId";

// ---> Auth Chat Gpt API's

export const ChatSignup = "/chat/signup";
export const ChatVerifyEmail = "/chat/verifyemail";
export const ChatSignin = "/chat/login";
