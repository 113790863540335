import { GetQueryInputData } from "../../Redux/Apis/ApiService";
import { MdSettingsBackupRestore } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { TextareaAutosize } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { FiCodepen } from "react-icons/fi";

export default function BlockDetails() {
  const headings = [
    ["Transaction Hash:", "Status:", "Block:"],
    ["From:", "To"],
    ["Value:", "Transaction Fee:", "Gas Price:"],
    ["Gas Limit & Usage by Txn:", "Gas Fees:", "Burnt & Txn Savings Fees:"],
    ["Other Attributes:", "Input Data:"],
  ];
  const [showMore, setShowMore] = useState(false);
  const [val, setVal] = useState([]);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const txnDetails = useSelector((state) => state.queryInputData);

  useEffect(() => {
    dispatch(GetQueryInputData(state));
  }, [dispatch]);
  useEffect(() => {
    if (txnDetails?.data?.length) {
      const val = txnDetails?.data[0];
      const value = [
        [val?.hash, "Success", val?.blockNumber],
        [val?.from, val?.to],
        [val?.value, val?.gas, val?.gasPrice],
      ];
      setVal(value);
    }
  }, [txnDetails?.data]);

  return (
    <div className="w-full mb-8">
      <div className="px-2 py-2 mb-4">
        <h1 className="text-xl font-[500] text-gray-800">
          Transaction Details
        </h1>
      </div>
      <div className="space-y-4">
        <div className="w-full rounded-xl px-6 py-6 bg-white border shadow-sm">
          <div className="divide-y space-y-2">
            <div className="flex space-x-16 w-full">
              <div className="flex flex-col w-[300px]">
                {headings[0]?.map((head) => (
                  <span className="px-2 py-2 text-ellipsis text-gray-500 font-medium">
                    {head}
                  </span>
                ))}
              </div>
              <div className="flex flex-col">
                {val[0]?.map((head) => (
                  <>
                    {head === "Success" ? (
                      <div className="py-2 px-2">
                        <span className="px-2 py-1 text-green-600 border text-ellipsis text-xs w-fit  font-medium border-green-500 rounded-md bg-green-100">
                          {head}
                        </span>
                      </div>
                    ) : (
                      <span className="px-2 py-2 text-gray-800 text-clip">
                        {head}
                      </span>
                    )}
                  </>
                ))}
              </div>
            </div>
            <div className="flex space-x-16 w-full">
              <div className="flex flex-col w-[300px]">
                {headings[1]?.map((head) => (
                  <span className="px-2 py-2 text-ellipsis text-gray-500 font-medium">
                    {head}
                  </span>
                ))}
              </div>
              <div className="flex flex-col">
                {val[1]?.map((head) => (
                  <span className="px-2 py-2  text-gray-800 text-clip">
                    {head}
                  </span>
                ))}
              </div>
            </div>
            <div className="flex space-x-16 w-full">
              <div className="flex flex-col w-[300px]">
                {headings[2]?.map((head) => (
                  <span className="px-2 py-2 text-ellipsis text-gray-500 font-medium">
                    {head}
                  </span>
                ))}
              </div>
              <div className="flex flex-col">
                {val[2]?.map((head) => (
                  <span className="px-2 py-2  text-gray-800 text-clip">
                    {head}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full rounded-xl px-6 py-4 bg-white border shadow-sm">
          <div className="flex w-full flex-col">
            {showMore ? (
              <div className="divide-y space-y-2 border-b">
                <div className="flex space-x-16">
                  <div className="flex flex-col w-[300px]">
                    {headings[3]?.map((head) => (
                      <span className="px-2 py-2 text-ellipsis text-gray-500 font-medium">
                        {head}
                      </span>
                    ))}
                  </div>
                  <div className="flex flex-col">
                    {val[3]?.map((head) => (
                      <span className="px-2 py-2  text-gray-800 text-clip">
                        {head}
                      </span>
                    ))}
                  </div>
                </div>
                <div className="flex space-x-16 py-2">
                  <div className="flex flex-col w-[300px]">
                    {headings[4]?.map((head) => (
                      <span className="px-2 py-2 text-ellipsis text-gray-500 font-medium">
                        {head}
                      </span>
                    ))}
                  </div>
                  <div className="flex flex-col lg:w-[1200px]">
                    <div className="flex space-x-2 py-2">
                      <div className="border px-2 py-0.5 w-fit rounded-lg bg-slate-100 ">
                        <div className="flex space-x-1 text-center justify-center items-center">
                          <span className="text-xs text-gray-500 text-center">
                            Txn Type :
                          </span>
                          <span className="text-sm text-center pt-0.5">
                            {parseInt(txnDetails?.data[0]?.type)}
                          </span>
                        </div>
                      </div>
                      <div className="border px-2 py-0.5 w-fit rounded-lg bg-slate-100">
                        <div className="flex space-x-1 text-center justify-center items-center">
                          <span className="text-xs text-gray-500 text-center">
                            Nonce :
                          </span>
                          <span className="text-sm text-center pt-0.5">
                            {parseInt(txnDetails?.data[0]?.nonce)}
                          </span>
                        </div>
                      </div>
                      <div className="border px-2 py-0.5 w-fit rounded-lg bg-slate-100">
                        <div className="flex space-x-1 text-center justify-center items-center">
                          <span className="text-xs text-gray-500 text-center">
                            Position in Block :
                          </span>
                          <span className="text-sm text-center pt-0.5">
                            {txnDetails?.data[0]?.transactionIndex}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="w-full py-2 space-y-1">
                      <TextareaAutosize
                        maxRows={10}
                        disabled
                        className=" w-full p-2 text-xs border-1 rounded-lg bg-slate-100 focus:border-[#04C2AE]/20 ring-2 ring-gray-300 outline-none"
                      >
                        {txnDetails?.data[0]?.input}
                      </TextareaAutosize>
                      <button className="px-2 flex py-1 text-gray-600 tracking-tight text-sm font-normal border rounded-md bg-gray-200 hover:bg-gray-100">
                        <FiCodepen className="text-lg" />
                        <span className="pl-1">Decode Input Data</span>
                      </button>
                      <button className="px-2 flex py-1 text-gray-600 tracking-tight text-sm font-normal border rounded-md bg-gray-200 hover:bg-gray-100">
                        <MdSettingsBackupRestore className="text-lg" />
                        <span className="pl-1">Switch Back</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="flex w-full space-x-16">
              <div className="flex flex-col w-[300px]">
                <span className="px-2 py-2 text-ellipsis text-gray-500 font-medium">
                  More Details
                </span>
              </div>
              <div className="flex justify-start">
                <button
                  onClick={() => setShowMore(!showMore)}
                  className="px-2 py-2 text-ellipsis text-blue-500 text-sm font-medium"
                >
                  + Click to show {showMore ? "less" : "more"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

