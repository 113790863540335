import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import SearchBar from "../../Helpers/SearchBar";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Table } from "../Tables";

export default function Dashboard() {
  const Navigate = useNavigate();
  const input = useSelector((state) => state.inputData);
  const block = useSelector((state) => state.blockData);

  useEffect(() => {
    document.title = "Block Sail | Dashboard, Latest Transactions And Blocks ";
  }, []);

  const columns = useMemo(
    () => [
      {
        id: "blockNumber",
        label: "BLOCK NUMBER",
        minWidth: 170,
        style: {
          color: "#04C2AE",
        },
        anchor: true,
        toolTip: false,
        format: (value) => {
          let val = value;
          return val;
        },
      },
      {
        id: "blockHash",
        label: "BLOCK HASH",
        minWidth: 170,
        style: {
          color: "#04C2AE",
        },
        anchor: true,
        align: "center",
        format: (hash) => {
          const has1 = hash?.slice(0, 6);
          const has2 = hash?.slice(hash.length - 6, hash.length);
          return has1 && has2 ? has1 + "..." + has2 : "NA";
        },
      },
      {
        id: "noOfTransaction",
        label: "TRANSACTIONS",
        align: "right",
        minWidth: 170,
        toolTip: false,
      },
    ],
    [block]
  );

  const columns1 = useMemo(
    () => [
      {
        id: "hash",
        label: "HASH",
        minWidth: 170,
        style: {
          color: "#04C2AE",
        },
        anchor: true,
        format: (hash) => {
          const has1 = hash?.slice(0, 6);
          const has2 = hash?.slice(hash.length - 6, hash.length);
          return has1 && has2 ? has1 + "..." + has2 : "NA";
        },
      },
      {
        id: "from",
        label: "FROM",
        minWidth: 170,
        style: {
          color: "#04C2AE",
        },
        anchor: true,
        format: (hash) => {
          const has1 = hash?.slice(0, 6);
          const has2 = hash?.slice(hash.length - 6, hash.length);
          return has1 && has2 ? has1 + "..." + has2 : "NA";
        },
      },
      {
        id: "to",
        label: "TO",
        minWidth: 170,
        style: {
          color: "#04C2AE",
        },
        anchor: true,

        format: (hash) => {
          const has1 = hash?.slice(0, 6);
          const has2 = hash?.slice(hash.length - 6, hash.length);
          return has1 && has2 ? has1 + "..." + has2 : "NA";
        },
      },
      {
        id: "value",
        label: "AMOUNT",
        minWidth: 170,
        align: "right",
        toolTip: false,
        format: (value) => {
          let val = parseInt(value, 16);
          return val + " " + "ETH";
        },
      },
    ],
    [input]
  );

  const footer = () => {
    return (
      <div className="w-full border-t px-2 py-3 bg-slate-100">
        <div
          className="flex space-x-2 justify-center items-center text-center cursor-pointer"
          onClick={() => Navigate("blocks")}
        >
          <span className="text-gray-600 text-base font-semibold">
            View All Blocks
          </span>
          <span className="text-2xl mt-0.5 text-gray-600">
            <HiOutlineArrowNarrowRight />
          </span>
        </div>
      </div>
    );
  };

  const footer1 = () => {
    return (
      <div className="w-full border-t px-2 py-3 bg-slate-100">
        <div
          className="flex space-x-2 justify-center items-center text-center cursor-pointer"
          onClick={() => Navigate("txns")}
        >
          <span className="text-gray-600 text-base font-semibold">
            View All Transactions
          </span>
          <span className="text-2xl mt-0.5 text-gray-600">
            <HiOutlineArrowNarrowRight />
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full space-y-6 mb-4">
      <div className="w-full px-6 py-8 flex justify-center items-center">
        <div className="w-full max-w-3xl">
          <SearchBar />
        </div>
      </div>
      <div className="flex lg:justify-between lg:flex-row flex-col lg:space-y-0 space-y-4 lg:space-x-6">
        <div className="w-full">
          <Table
            columns={columns}
            rows={block.data.length ? block.data : []}
            heading={"Latest Blocks"}
            isLoading={block.isLoading}
            tableId={"Block"}
            Footer={footer}
          />
        </div>
        <div className="w-full">
          <Table
            columns={columns1}
            rows={input.data.length ? input.data : []}
            heading={"Latest Transactions"}
            isLoading={input.isLoading}
            tableId={"Txn"}
            Footer={footer1}
          />
        </div>
      </div>
    </div>
  );
}

