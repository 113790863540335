import SearchBar from '../../Helpers/SearchBar';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Table } from '.';

export default function OperatorsTable() {
    const operator = useSelector((state) => state.operator);

    useEffect(() => {
        document.title = "Block Sail | Operators"
    }, [])

    const columns = useMemo(() => [
        {
            id: 'operatorId',
            label: 'OPERATOR ID',
            minWidth: 170,
            style: {
                color: "#01BF97"
            },
            anchor: true,
            toolTip: false,
        },
        {
            id: 'ownerAddress',
            label: 'OWNER ADDRESS',
            minWidth: 170,
            style: {
                color: "#01BF97"
            },
            anchor: true,
            format: (hash) => {
                const has1 = hash?.slice(0, 6);
                const has2 = hash?.slice(hash.length - 6, hash.length)
                return has1 && has2 ? has1 + "..." + has2 : "NA"
            },
        },
        {
            id: 'transactionHash',
            label: 'TXN HASH',
            minWidth: 170,
            style: {
                color: "#01BF97",
            },
            anchor: true,
            format: (hash) => {
                const has1 = hash?.slice(0, 6);
                const has2 = hash?.slice(hash.length - 6, hash.length)
                return has1 && has2 ? has1 + "..." + has2 : "NA"
            },
        },
        {
            id: 'noOfValidators',
            label: 'VALIDATORS',
            toolTip: false,
            minWidth: 170,
            // style: {
            //     color: "#01BF97"
            // },
            // toolTip: false,
            // anchor: true,
        },
        {
            id: 'isRegistered',
            label: 'STATUS',
            minWidth: 170,
            align: 'center',
            toolTip: false,
            format: (value) => {
                return (
                    <span className={`${value ? "bg-green-100 text-green-500" : "bg-red-100 text-red-500"} text-xs font-medium border px-2 py-1.5 rounded-md`}>{value ? "REGISTERED" : "REMOVED"}</span>
                )
            },
        },
    ], [operator]);

    return (
        <div className="w-full space-y-6 mb-4">
            <div className='px-3 py-1 flex justify-between w-full items-center lg:flex-row flex-col lg:space-y-0 space-y-2'>
                <span className='text-2xl font-bold text-gray-600 uppercase'>
                    Operators
                </span>
                <div className='max-w-xl w-full'>
                    <SearchBar id={"Small"} />
                </div>
            </div>
            <div className='flex lg:justify-between lg:flex-row flex-col lg:space-y-0 space-y-4 lg:space-x-6'>
                <div className='w-full'>
                    <Table
                        columns={columns} rows={operator?.data?.length ? operator.data : []}
                        isLoading={operator.isLoading}
                        tableId={"Operator"}
                        pagiNation={true}
                        totalRecords={operator.totalRecords}
                        totalPages={operator.totalPages}
                    />
                </div>
            </div>
        </div>
    )
}
