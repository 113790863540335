import AuthService from "./AuthService";
import Axios from "axios";

export default class ApiServices {
  static getConfig() {
    const authService = AuthService.get();
    var config = {
      headers: {
        Authorization: `Bearer ${authService.isAuthenticated()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        dataSource: `${authService.authenticationType()}`,
        chatToken: `${authService.chatisAuthenticated()}`,
      },
    };
    return config;
  }
  //Post Request Function
  static post(path, body) {
    const authService = AuthService.get();
    if (authService.isAuthenticated() || authService.authenticationType()) {
      var config = this.getConfig();
    }
    return Axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${path}`,
      body,
      config
    );
  }
  // Get Request Function
  static get(path) {
    const authService = AuthService.get();
    if (authService.isAuthenticated() || authService.authenticationType()) {
      var config = this.getConfig();
    }
    return Axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${path}`,
      config
    );
  }
  //Get Request Function by ID
  static getById(path, id) {
    const authService = AuthService.get();
    if (authService.isAuthenticated() || authService.authenticationType()) {
      var config = this.getConfig();
    }
    return Axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${path}/${id}`,
      config
    );
  }
  //Get Request Function by body
  static getByBodyId(path, body) {
    const authService = AuthService.get();
    if (authService.isAuthenticated() || authService.authenticationType()) {
      var config = this.getConfig();
    }
    return Axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${path}`,
      config,
      body
    );
  }
  // Update Request Function
  static put(path, id, body) {
    const authService = AuthService.get();
    if (authService.isAuthenticated() || authService.authenticationType()) {
      var config = this.getConfig();
    }
    return Axios.put(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${path}/${id}`,
      body,
      config
    );
  }
  // Update Request Function by body
  static putByBody(path, body) {
    const authService = AuthService.get();
    if (authService.isAuthenticated() || authService.authenticationType()) {
      var config = this.getConfig();
    }
    return Axios.put(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${path}`,
      body,
      config
    );
  }
  // Delete Request Function
  static delete(path, body) {
    const authService = AuthService.get();
    if (authService.isAuthenticated() || authService.authenticationType()) {
      var config = this.getConfig();
    }
    return Axios.delete(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${path}/${body}`,
      config,
      body
    );
  }
}
