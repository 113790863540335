import React from "react";
import "./style.css";
function Typingloader() {
  return (
    <div className="loader">
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}

export default Typingloader;
