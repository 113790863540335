import { MdOutlineLock, MdOutlineLockOpen } from 'react-icons/md';
import { changePassword, login } from '../../Apis/EndPoints';
import { setUserInfo } from '../../Redux/Slices/userSlice';
import { ButtonLoader } from '../../Assets/Icons/Icons';
import { userLogin } from '../../Middleware/useAuth';
import ApiServices from '../../Services/ApiServices';
import logo from '../../Assets/images/logo.svg'
import { useNavigate } from "react-router-dom";
import { HiOutlineMail } from 'react-icons/hi';
import { useEffect, useState } from 'react';
import useRegex from '../../Utils/useRegex';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const Login = () => {
    const [changeDisabledBtn, setChangeDisabledBtn] = useState(false);
    const [isValidPassword, setisValidPassword] = useState(false);
    const [showConfirmPass, setShowConfirmPass] = useState(true);
    const [changeLoading, setChangeLoading] = useState(false);
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const [confirmNewPass, setConfirmNewPass] = useState("");
    const [loginLoading, setLoginLoading] = useState(false);
    const [hasUppercase, sethasUppercase] = useState(false);
    const [hasLowercase, sethasLowercase] = useState(false);
    const [disabledBtn, setDisabledBtn] = useState(false);
    const [showNewPass, setShowNewPass] = useState(true);
    const [errorMessage, setErrorMessage] = useState({})
    const [isVerified, setIsVerified] = useState(true);
    const [hasNumber, sethasNumber] = useState(false);
    const [hasSymbol, sethasSymbol] = useState(false);
    const [showPass, setShowPass] = useState(true);
    const [password, setPassword] = useState("");
    const [newPass, setNewPass] = useState("");
    const [email, setMail] = useState("");
    const navigate = useNavigate();
    let dispatch = useDispatch()

    const handleEmail = (e) => {
        let mail = e.target.value
        let isValid = useRegex.useEmailRegex(mail);
        if (isValid) {
            setMail(mail);
        } else {
            setMail("");
        }
    };

    const handlePassword = (e) => {
        let password = e.target.value;
        setPassword(password)
    };

    const handleChangePassword = (e) => {
        let { value, id } = e.target;
        if (id === "changePass") {
            setNewPass(value)
        }
        if (id === "confirmPass") {
            setConfirmNewPass(value)
        }
    }
    const handleFocus = () => {
        setIsTooltipOpen(true);
    };

    const handleBlur = () => {
        setIsTooltipOpen(false);
    };

    useEffect(() => {
        if (!email || !password) {
            setDisabledBtn(true);
        } else {
            setDisabledBtn(false);
        }
    }, [email, password]);

    useEffect(() => {
        CheckPasswordValid()
    }, [newPass])

    useEffect(() => {
        if (!newPass || !confirmNewPass) {
            setChangeDisabledBtn(true);
        } else if (newPass !== confirmNewPass) {
            setChangeDisabledBtn(true);
        } else if (!isValidPassword) {
            setChangeDisabledBtn(true)
        } else {
            setChangeDisabledBtn(false)
            setErrorMessage({});
        }
    }, [newPass, confirmNewPass]);

    useEffect(() => {
        isValid()
    }, [confirmNewPass])

    useEffect(() => {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            navigate("/dataSources")
        }
    }, [])

    const SubmitLogin = async (e) => {
        e.preventDefault();
        try {
            const body = {
                name: email,
                password: password,
            }
            setLoginLoading(true);
            const isUserValidated = await userLogin(login, body);
            dispatch(setUserInfo(isUserValidated.user));
            if (isUserValidated.isConfirmed === false) {
                setIsVerified(false)
                toast.error(isUserValidated.message);
            } else if (isUserValidated.status) {
                setTimeout(() => {
                    navigate("/dataSources");
                    toast.success(isUserValidated.message);
                    setLoginLoading(false);
                }, 500);
            } else {
                setLoginLoading(false);
                toast.error(isUserValidated.message);
            }
        } catch (error) {
            setLoginLoading(false);
            console.log("error", error);
            toast.error("Something went wrong");
        }
    };

    const submitChangePassword = (e) => {
        e.preventDefault();
        const body = {
            username: email,
            temporaryPassword: password,
            newPassword: newPass
        }
        setChangeLoading(true)
        setLoginLoading(false);
        ApiServices.post(changePassword, body).then((res) => {
            setChangeLoading(false)
            setIsVerified(true)
            toast.success("Password changed successfully")
        }).catch((err) => {
            setChangeLoading(false)
        })

    }

    const isValid = () => {
        let isError = false;
        let error = {};
        if (newPass !== confirmNewPass) {
            error.newpass = "Password doesn't match";
            isError = true;
        } else {
            error.newpass = "";
            isError = true;
        }
        setErrorMessage(error);
        return isError;
    }

    const CheckPasswordValid = () => {
        const hasUpper = /[A-Z]/.test(newPass);
        if (hasUpper) {
            sethasUppercase(hasUpper)
        } else {
            sethasUppercase(false)
        }
        const hasLower = /[a-z]/.test(newPass);
        if (hasLower) {
            sethasLowercase(hasLower)
        } else {
            sethasLowercase(false)
        }
        const hasNum = /\d/.test(newPass);
        if (hasNum) {
            sethasNumber(hasUpper)
        } else {
            sethasNumber(false)
        }
        const hasSmbol = /[-!$%^&@*()_+|~=`{}\[\]:";'<>?,.\/]/.test(newPass);
        if (hasSmbol) {
            sethasSymbol(hasSmbol)
        } else {
            sethasSymbol(false)
        }
        const isValidPass = hasUppercase && hasLowercase && hasNumber && hasSymbol && newPass.length >= 8;
        if (isValidPass) {
            setisValidPassword(isValidPass)
        } else {
            setisValidPassword(false)
        }
    }


    return (
        <>
            <div className="loginBG flex flex-col items-center justify-center" style={{ minHeight: "100VH", maxHeight: "100%", padding: "20px" }}>
                <div className="w-full bg-white rounded-xl shadow border md:mt-0 sm:max-w-lg xl:p-0 border-[white] sm:px-8 ">
                    <div className="p- space-y-6 md:space-y-6 sm:p-12">
                        <img className="w-40" src={logo} alt="logo" />
                        <>
                            {
                                isVerified
                                    ?
                                    <>
                                        <h1 className="text-xl font-bold leading-tight text-gray-700 md:text-2xl pb-2 lg:text-3xl">
                                            Log In
                                        </h1>
                                        <span>Welcome back! Please enter your details.</span>
                                        <form onSubmit={SubmitLogin} className="space-y-4 md:space-y-6">
                                            <div className='relative'>
                                                <label className="block mb-2 text-lg font-medium ">Email</label>
                                                <input onChange={(e) => handleEmail(e)} name="email" className="reletive border text-gray-900 lg:text-lg text-sm rounded-lg block w-full p-2.5 placeholder-gray-400" placeholder="Enter Your Email" required="" />
                                                <span className='absolute inset-y-0 right-4 mt-9 flex items-center pl-6'>
                                                    < HiOutlineMail color='#04D1BE' className="h-6 w-6" />
                                                </span>
                                            </div>
                                            <div className='relative'>
                                                <label className="block mb-2 text-lg font-medium ">Password</label>
                                                <input
                                                    onChange={(e) => handlePassword(e)}
                                                    onCopy={(e) => e.preventDefault()}
                                                    onPaste={(e) => e.preventDefault()}
                                                    type={`${showPass ? "password" : "text"}`} name="password" placeholder="Enter Your Password" className="border text-gray-900 lg:text-lg text-sm rounded-lg block w-full p-2.5 placeholder-gray-400" required="" />
                                                <span onClick={() => setShowPass(!showPass)}
                                                    className='absolute inset-y-0 right-4 mt-8 flex items-center pl-6 cursor-pointer'>
                                                    {
                                                        showPass
                                                            ?
                                                            < MdOutlineLock color='#04D1BE' className="h-6 w-6" />
                                                            :
                                                            < MdOutlineLockOpen color='#04D1BE' className="h-6 w-6" />
                                                    }
                                                </span>
                                            </div>
                                            <button
                                                disabled={loginLoading || disabledBtn ? true : false}
                                                type="submit"
                                                className={`w-full text-white font-semibold rounded-lg text-lg px-5 py-2.5 text-center ${loginLoading || disabledBtn ? "cursor-not-allowed bg-[#04D1BE]/50" : "bg-[#04D1BE]"}`}>
                                                {loginLoading
                                                    ?
                                                    <ButtonLoader text={"Logging In"} />
                                                    :
                                                    "Login"
                                                }
                                            </button>
                                        </form>
                                    </>
                                    :
                                    <>
                                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-700 md:text-2xl pb-2 lg:text-2xl">
                                            Change Password
                                        </h1>
                                        <form onSubmit={submitChangePassword} className="space-y-4 md:space-y-6">
                                            <div className='relative'>
                                                <label className="block mb-2 text-sm font-medium ">Enter New Password</label>
                                                <input id="changePass"
                                                    value={newPass}
                                                    onFocus={handleFocus}
                                                    onBlur={handleBlur}
                                                    onCopy={(e) => e.preventDefault()}
                                                    onChange={(e) => handleChangePassword(e)}
                                                    onPaste={(e) => e.preventDefault()}
                                                    type={`${showNewPass ? "password" : "text"}`} placeholder="Enter New Password" className="border text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 placeholder-gray-400" required="" />
                                                <span onClick={() => setShowNewPass(!showNewPass)} className='absolute inset-y-0 right-4 mt-6 flex items-center pl-6 cursor-pointer'>
                                                    {
                                                        showNewPass
                                                            ?
                                                            < MdOutlineLock color='#664DE5' className="h-6 w-6" />
                                                            :
                                                            < MdOutlineLockOpen color='#664DE5' className="h-6 w-6" />
                                                    }
                                                </span>
                                                {isTooltipOpen && (
                                                    <div className="absolute top-full left-0 mt-2 w-full z-40 bg-gray-400 p-2 rounded-lg shadow-lg">
                                                        <div className="text-white text-sm">Password must contain:</div>
                                                        <ul className="text-white text-sm mt-1">
                                                            <li className={hasUppercase ? 'text-green-400' : ''}>At least one uppercase letter</li>
                                                            <li className={hasLowercase ? 'text-green-400' : ''}>At least one lowercase letter</li>
                                                            <li className={hasNumber ? 'text-green-400' : ''}>At least one number</li>
                                                            <li className={hasSymbol ? 'text-green-400' : ''}>At least one symbol</li>
                                                            <li className={newPass.length >= 8 ? 'text-green-400' : ''}>At least 8 characters</li>
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                            <div className='relative'>
                                                <label className="block mb-2 text-sm font-medium ">Confirm New Password</label>
                                                <input id='confirmPass'
                                                    onChange={(e) => handleChangePassword(e)}
                                                    onCopy={(e) => e.preventDefault()}
                                                    onPaste={(e) => e.preventDefault()}
                                                    type={`${showConfirmPass ? "password" : "text"}`} placeholder="Confirm New Password" className="border text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 placeholder-gray-400" required="" />
                                                <span onClick={() => setShowConfirmPass(!showConfirmPass)} className='absolute inset-y-0 right-4 mt-6 flex items-center pl-6 cursor-pointer'>
                                                    {
                                                        showConfirmPass
                                                            ?
                                                            < MdOutlineLock color='#664DE5' className="h-6 w-6" />
                                                            :
                                                            < MdOutlineLockOpen color='#664DE5' className="h-6 w-6" />
                                                    }
                                                </span>
                                            </div>
                                            <span className='text-[red]/80 text-[12px] h-[15px]'>{errorMessage && errorMessage.newpass ? errorMessage.newpass : ''}</span>
                                            <button
                                                disabled={changeLoading || changeDisabledBtn ? true : false}
                                                type="submit"
                                                className={`w-full text-white font-semibold rounded-lg text-sm px-5 py-2.5 text-center ${changeLoading || changeDisabledBtn ? "cursor-not-allowed bg-[#04D1BE]/50 disabled:" : "bg-[#04D1BE]"}`}>
                                                {changeLoading
                                                    ?
                                                    <ButtonLoader text={"Changing Password"} />
                                                    :
                                                    "Submit"
                                                }
                                            </button>
                                        </form>
                                    </>
                            }
                        </>

                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
