import { GetValidators } from "../Apis/ApiService";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
    validatorInfo: '',
    transaction: {
        transactions: [],
        totalRecords: null,
        totalPages: null
    },
    totalRecords: null,
    isLoading: false,
    error: null,
};
export const ValidatorSlice = createSlice({
    name: "Validators",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetValidators.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(GetValidators.fulfilled, (state, action) => {
                if (action?.payload.docs) {
                    state.data = [...state?.data, ...action?.payload?.docs?.filter(obj2 => !state?.data?.some(obj1 => obj1._id === obj2._id))];
                    state.totalRecords = action?.payload?.total;
                    state.isLoading = false;
                } else {
                    state.validatorInfo = action.payload.data
                    state.transaction.transactions = [...state?.transaction.transactions, ...action?.payload?.transactions?.docs?.filter(obj2 => !state.transaction.transactions?.some(obj1 => obj1._id === obj2._id))]
                    state.transaction.totalRecords = action.payload?.transactions?.total
                    state.transaction.totalPages = action.payload?.transactions?.pages
                    state.isLoading = false;
                }
            })
            .addCase(GetValidators.rejected, (state, action) => {
                state.error = action.error.message;
                state.data = action.payload;
                state.isLoading = false;
            });
    },
});

export default ValidatorSlice.reducer;