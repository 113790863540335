import convertTimestampToRelativeTime from "../../Utils/convertRelativeTimeStamp";
import SearchBar from "../../Helpers/SearchBar";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Table } from "../Tables";

export default function AllBlocks() {
  const block = useSelector((state) => state.blockData);

  useEffect(() => {
    document.title = "Block Sail | Blocks";
  }, []);

  const columns = useMemo(
    () => [
      {
        id: "blockNumber",
        label: "BLOCK NUMBER",
        minWidth: 170,
        style: {
          color: "#01BF97",
        },
        anchor: true,
        toolTip: false,
        format: (value) => {
          let val = value;
          return val;
        },
      },
      {
        id: "blockHash",
        label: "BLOCK HASH",
        minWidth: 170,
        style: {
          color: "#01BF97",
        },
        anchor: true,
        format: (hash) => {
          const has1 = hash?.slice(0, 6);
          const has2 = hash?.slice(hash.length - 6, hash.length);
          return has1 && has2 ? has1 + "..." + has2 : "NA";
        },
      },
      {
        id: "blockTimeStamp",
        label: "TIMESTAMP",
        minWidth: 170,
        toolTip: false,
        format: (value) => {
          let inputString = convertTimestampToRelativeTime(value);
          const parts = inputString.split("(");
          const resultString = parts[0].trim();
          return (
            <span className="py-1 text-gray-600 text-clip truncate text-xs">
              {resultString}
            </span>
          );
        },
      },
      {
        id: "noOfTransaction",
        label: "TRANSACTIONS",
        align: "center",
        toolTip: false,
        minWidth: 170,
      },
    ],
    [block]
  );

  return (
    <div className="w-full space-y-6 mb-4">
      <div className="px-3 py-1 flex justify-between w-full items-center lg:flex-row flex-col lg:space-y-0 space-y-2">
        <span className="text-2xl font-bold text-gray-600 uppercase">
          Blocks
        </span>
        <div className="max-w-xl w-full">
          <SearchBar id={"Small"} />
        </div>
      </div>
      <div className="flex lg:justify-between lg:flex-row flex-col lg:space-y-0 space-y-4 lg:space-x-6">
        <div className="w-full">
          <Table
            columns={columns}
            rows={block?.data?.length ? block?.data : []}
            isLoading={block.isLoading}
            tableId={"Block"}
            pagiNation={true}
            totalRecords={block?.totalRecords}
            totalPages={block?.totalPages}
          />
        </div>
      </div>
    </div>
  );
}

