import { Outlet } from "react-router-dom";
import AppBar from "./AppBar";
import { TbBrandHipchat } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import AuthService from "../../Services/AuthService";
export default function MainLayout({ children }) {
  const Navigate = useNavigate();
  const authService = AuthService.get();
  const authType = authService.authenticationType();
  return (
    <>
      <AppBar />
      {/* <div className='homeHalfBG h-[45vh] relative w-full'> */}
      <div className="lg:px-28 md:px-4 px-2 mt-24 mb-6 absolute z-50 w-full">
        {children || <Outlet />}
        {authType === "private" ? (
          <></>
        ) : (
          <button
            onClick={() => Navigate("/chat/login")}
            className="fixed bottom-10 right-10 shadow-md hover:shadow-2xl hover:shadow-[#01bf97] rounded-full bg-[#01bf97] text-white p-1  "
          >
            <TbBrandHipchat className="p-1 rounded-full text-[44px] hover:text-[42px] border-2 border-white " />
          </button>
        )}
      </div>
      {/* </div>/ */}
    </>
  );
}

