import { GetCurrentUser } from "../Apis/ApiService";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userInfo: {},
    isLoading: false,
    error: null,
};
export const user = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUserInfo: (state, action) => {
            state.userInfo = action.payload;
        },
        logout: (state) => {
            // state.userInfo = action.payload;
            // From here we can take action only at this "counter" state
            // But, as we have taken care of this particular "logout" action
            // in rootReducer, we can use it to CLEAR the complete Redux Store's state
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetCurrentUser.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(GetCurrentUser.fulfilled, (state, action) => {
                state.userInfo = action.payload;
                state.isLoading = false;
            })
            .addCase(GetCurrentUser.rejected, (state, action) => {
                state.error = action.error.message;
                state.isLoading = false;
            });
    },

});

export const { setUserInfo, logout } = user.actions;

export default user.reducer;