import { Avatar, Paper, Skeleton, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import { BsSun, BsArrowRight, BsLightningCharge } from "react-icons/bs";
import { RiSendPlane2Fill } from "react-icons/ri";
import { PiWarning } from "react-icons/pi";
import Gtp from "../../../Assets/images/gpt.svg";
import "./style.css";
import ApiServices from "../../../Services/ApiServices";
import { useParams } from "react-router-dom";
import Typingloader from "./typingloader";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import { GetChatData } from "../../../Redux/Apis/ApiService";
import { useDispatch } from "react-redux";
import { RotateLoading } from "../../../Assets/Icons/Icons";
import * as Scroll from "react-scroll";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

const data = [
  {
    icons: <BsSun className="text-[26px]" />,
    name: "Example",
    details: [
      "“Explain quantum computing in simple terms”",
      "“Got any creative ideas for a 10 year old’s birthday?”",
      "“How do I make an HTTP request in Javascript?”",
    ],
  },
  {
    icons: <BsLightningCharge className="text-[26px]" />,
    name: "Capabilities",
    details: [
      "Remembers what user said earlier in the conversation",
      "Allows user to provide follow-up corrections",
      "Trained to decline inappropriate requests",
    ],
  },
  {
    icons: <PiWarning className="text-[26px]" />,
    name: "Limitations",
    details: [
      "May occasionally generate incorrect information",
      "May occasionally produce harmful instructions or biased content",
      "Limited knowledge of world and events after 2021",
    ],
  },
];

function TypingEffect({ text, typingSpeed, setChat, scrollToBottom }) {
  const [displayText, setDisplayText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    scrollToBottom();
  }, [displayText, currentIndex, scrollToBottom]);
  useEffect(() => {
    const typingInterval = setInterval(() => {
      if (currentIndex < text.length) {
        setDisplayText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      } else {
        clearInterval(typingInterval);
        setChat("");
      }
    }, typingSpeed);

    return () => {
      clearInterval(typingInterval);
    };
  }, [text, typingSpeed, currentIndex]);

  return <div>{displayText}</div>;
}

function Gpt({
  open,
  isValue,
  setisValue,
  uuid,
  v4Id,
  GenerateUID,
  prevChatData,
  setPrevChatData,
  ChatDataLoader,
  Setuuid,
  setChatloading,
  chatloading,
  setChatLoader,
  ChatLoader,
}) {
  const { id } = useParams();
  const [Value, setValue] = useState("");
  const [Chat, setChat] = useState("");
  const dispatch = useDispatch();
  const [tempValue, setTempValue] = useState("");
  const Navigate = useNavigate();

  const user = localStorage.getItem("Chatuser");
  const userI = JSON.parse(user);
  const userId = userI?.userId;
  const userName = userI?.name;
  console.log("userId1", userId);
  const handleSubmit = (event) => {
    event.preventDefault();
    BottomScroll();
    setChatLoader(true);
    setisValue(true);

    setTempValue("");
    const body1 = {
      text: Value,
    };
    if (!id) {
      Navigate(`/chat/${uuid}`);
      ApiServices.post(`/AI/query?userId=${userId}&sessionId=${uuid}`, body1)
        .then((chatdata) => {
          setChat(chatdata.data);
          dispatch(GetChatData({ userId }));
          if (id === undefined) {
            Navigate(`/chat/${uuid}`);
          }
        })
        .catch((error) => {
          setChatLoader(false);
          console.log("error", error);
        });
      console.log("form submitted ");
    } else {
      ApiServices.post(`/AI/query?userId=${userId}&sessionId=${id}`, body1)
        .then((chatdata) => {
          setChat(chatdata.data);
          dispatch(GetChatData({ userId }));
          if (id === undefined) {
            Navigate(`/chat/${uuid}`);
          }
        })
        .catch((error) => {
          setChatLoader(false);
          console.log("error", error);
        });
      console.log("form submitted ");
    }
  };

  const BottomScroll = () => {
    var scroll = Scroll.animateScroll;

    scroll.scrollToBottom();
  };
  useEffect(() => {
    if (id != null) {
      ApiServices.post(`/AI/findbyId?userId=${userId}&sessionId=${id}`)
        .then((chatdata) => {
          setPrevChatData(chatdata.data);
          setChatLoader(false);
          setValue("");
          setChatloading(false);
        })
        .catch((err) => {
          console.log(err);
          setChatloading(false);
          // setChatLoader(false);
        });
    } else {
      setisValue(false);
    }
  }, [id, Chat]);
  // console.log("prevChatData", prevChatData);
  const scrollableRef = useRef(null); // Ref for the scrollable element

  // Function to scroll to the bottom
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "auto",
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    });
  };

  const inputRef = useRef(null);

  useEffect(() => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "auto",
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    });
  }, [prevChatData]);

  useEffect(() => {
    // Focus the input element on component mount
    inputRef.current.focus();
  }, [isValue, id]);
  // Simulate a paragraph change (you can trigger this based on your actual logic)
  const handleChangeParagraph = () => {
    // Update the paragraph content here
    // Call scrollToBottom to scroll to the bottom when content changes
    scrollToBottom();
  };

  return (
    <div
      className="flex justify-center mt-16 mb-32  "
      ref={scrollableRef}
      open={open}
    >
      {/* <DrawerHeader /> */}

      {open === true ? <div className="lg:w-[380px] mb-4 "></div> : <></>}
      {isValue ? (
        <div className="w-full  flex flex-col justify-between ">
          {chatloading ? (
            <div
              className=" flex mx-12 shadow-md  overflow-auto  w-auto rounded-xl mt-4 bg-white p-4"
              style={{ scrollSnapAlign: "end" }}
            >
              <Skeleton variant="circular" width={50} height={50} />
              <div
                className="px-8 flex flex-col"
                style={{ width: "-webkit-fill-available" }}
              >
                <Skeleton variant="rounded" width={"auto"} height={60} />
              </div>
            </div>
          ) : (
            <div className="">
              {prevChatData?.data?.conversation &&
                prevChatData?.data?.conversation.length &&
                prevChatData?.data?.conversation.map((item, i) => (
                  <div
                    className=" flex mx-12 shadow-md  overflow-auto  w-auto rounded-xl mt-4 bg-white p-4"
                    style={{ scrollSnapAlign: "end" }}
                  >
                    {item?.role === "User" ? (
                      <Avatar
                        alt={`${userName}`}
                        // style={{ backgroundColor: "#623838" }}
                        src="/static/images/avatar/2.jpg"
                      />
                    ) : (
                      <img
                        alt="Ceicnp"
                        style={{
                          height: "40px",
                          width: "40px",
                          // backgroundColor: "#01BF97",
                        }}
                        src={Gtp}
                      ></img>
                    )}
                    {item?.role === "User" ? (
                      <div className="px-8 flex flex-col">
                        <span className="text-[16px] font-semibold mb-1 break-all">
                          {item?.message}
                        </span>
                        <span className="text-[12px] text-gray-600">
                          <Moment format=" MMM D, YYYY HH:MM A">
                            {item?.timestamp}
                          </Moment>{" "}
                        </span>{" "}
                      </div>
                    ) : (
                      <span className=" px-8 w-auto text-[16px] font-400 tracking-normal  leading-7">
                        {Chat.suggestions === item.message &&
                        prevChatData?.data?.conversation.length - 1 === i &&
                        !ChatLoader ? (
                          <TypingEffect
                            text={item?.message}
                            typingSpeed={20}
                            setChat={setChat}
                            scrollToBottom={BottomScroll}
                          />
                        ) : (
                          item?.message
                        )}
                      </span>
                    )}
                  </div>
                ))}

              {ChatLoader ? (
                <>
                  <div className=" flex mx-12 shadow-md  overflow-auto w-auto rounded-xl mt-4 bg-white p-4">
                    <Avatar
                      alt={`${userName}`}
                      src="/static/images/avatar/2.jpg"
                    ></Avatar>

                    <div className="px-8 flex flex-col">
                      <span className="text-[16px] font-semibold mb-1 break-all">
                        {Value}
                      </span>
                      <span className="text-[12px] text-gray-600">
                        <Moment format=" MMM D, YYYY HH:MM A" />
                      </span>{" "}
                    </div>
                  </div>
                  <div className=" flex mx-12 shadow-md  overflow-auto w-auto rounded-xl mt-4 bg-white p-4">
                    <Avatar
                      alt="Ceicnp"
                      style={{ backgroundColor: "#01BF97" }}
                      src={Gtp}
                    ></Avatar>
                    {/* <span className=" px-8 text-[18px] font-400 tracking-normal">
                    {Chat.suggestions}
                  </span> */}
                    <div className=" px-8 flex items-center">
                      {" "}
                      <Typingloader />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          )}
          <form
            className="w-auto justify-center items-center flex bg-[#FAFAFB] "
            onSubmit={handleSubmit}
          >
            <div
              className={`flex flex-col ${
                open ? "lg:w-[80vw] w-full" : ""
              }  w-full bottom-0 p-4 px-11 fixed bg-[#FAFAFB]`}
            >
              <div className="w-full relative mb-4">
                <input
                  ref={inputRef}
                  disabled={ChatLoader || chatloading ? true : false}
                  value={tempValue}
                  className="w-full  rounded-xl  shadow-md p-4 "
                  placeholder="Send a message "
                  onChange={(e) => {
                    setValue(e.target.value);
                    setTempValue(e.target.value);
                  }}
                />
                <span className="absolute text-[#7D8398] font-bold text-2xl z-50 right-6 top-4">
                  <RiSendPlane2Fill onClick={(e) => handleSubmit(e)} />
                </span>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="w-full flex flex-col justify-between">
          <div className="flex justify-center  ">
            <span className="font-bold text-[36px] mt-8 mb-8">Coindelta</span>
          </div>
          <div className="flex flex-col md:flex-row md:justify-center items-center">
            {data &&
              data.length &&
              data.map((items) => (
                <div className="flex flex-col px-8">
                  <div className="items-center content-center justify-center flex flex-col mb-8">
                    {items.icons}
                    <span className="font-500 text-[28px]">{items.name}</span>
                  </div>
                  {items.details &&
                    items.details.length &&
                    items.details.map((items) => (
                      <div className="p-4 h-24 text-center w-64 shadow-sm m-2 bg-white items-center flex font-poppins justify-center  rounded-xl">
                        <span className="text-[16px] font-500 ">
                          {items}
                          {items.name === "Example" ? (
                            <span className=" text-[18px] ">&#8594;</span>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    ))}
                </div>
              ))}
          </div>
          <form
            className="w-auto justify-center items-center flex bg-[#FAFAFB] "
            onSubmit={handleSubmit}
          >
            <div
              className={`flex flex-col ${
                open ? "lg:w-[80vw] w-full" : ""
              }  w-full bottom-0 p-4 px-11 fixed bg-[#FAFAFB] `}
            >
              <div className="w-full relative mb-4">
                <input
                  ref={inputRef}
                  value={tempValue}
                  disabled={ChatLoader || chatloading ? true : false}
                  className="w-full  rounded-xl  shadow-sm p-4"
                  placeholder="Send a message "
                  onChange={(e) => {
                    setValue(e.target.value);
                    setTempValue(e.target.value);
                  }}
                />
                <span className="absolute text-[#7D8398] font-bold text-2xl z-50 right-6 top-4">
                  <RiSendPlane2Fill onClick={(e) => handleSubmit(e)} />
                </span>
              </div>
              {/* <span className="text-[16px] font-400">
                Free Research Preview. ChatGPT may produce inaccurate
                information about people, places, or facts.
              </span> */}
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default Gpt;

