import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { FiChevronDown } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import ApiServices from "../../../Services/ApiServices";
import AuthService from "../../../Services/AuthService";
const settings = ["Dashboard", "Logout"];
function Navbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const user = localStorage.getItem("Chatuser");
  const userI = JSON.parse(user);
  const userName = userI?.name;
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleLogout = () => {
    localStorage.removeItem("ChatrefreshTokenExp");
    localStorage.removeItem("ChataccessTokenExp");
    localStorage.removeItem("ChatrefreshToken");
    localStorage.removeItem("ChataccessToken");
    localStorage.removeItem("Chatuser");
    localStorage.removeItem("Chatid");
    navigate("/chat/login");
  };

  return (
    <div className="w-full bg-[#fffff] flex justify-end  ">
      <Tooltip title="Open settings">
        <IconButton
          onClick={handleOpenUserMenu}
          sx={{ p: 0, width: "auto", height: "30px", borderRadius: "10%" }}
        >
          <Avatar
            style={{
              p: 0,
              height: "40px",
              width: "40px",
              backgroundColor: "#01BF97",
              lineHeight: 0,
            }}
            alt={`${userName}`}
            src="/static/images/avatar/2.jpg"
          />
          <span className="text-[14px] font-bold  mx-2">{userName}</span>
          <FiChevronDown />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {/* {settings.map((setting) => (
          <MenuItem key={setting} onClick={handleCloseUserMenu}>
            <Typography textAlign="center">{setting}</Typography>
          </MenuItem>
        ))} */}
        <MenuItem onClick={() => navigate("/")}>
          <Typography textAlign="center">Dashboard</Typography>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <Typography textAlign="center">Logout</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default Navbar;
