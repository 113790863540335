
export default class useRegex {

    static useEmailRegex(input) {
        let regex = /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/
        return regex.test(input.trim()) === true;
    };

    static useNumberRegex(input) {
        let regex = /^[0-9/]+$/;
        return regex.test(input);
    };

    static useFloatRegex(input) {
        // let regex1 = /^[0-9]\d{0,9}(\.\d{1,3})?%?$/;
        let regex = /^[0-9]\d*(\.\d+)?$/;
        return regex.test(input);
    }

    static useText(input) {
        let regex = /^[a-zA-Z]+$/;
        return regex.test(input);
    }

    static usePassword(input) {
        // let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#&*])[A-Za-z\d@#&*]{8,}$/;
        return regex.test(input);
    }
}

