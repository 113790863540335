
export default function convertTimestampToRelativeTime(timestamp) {
    const currentTimestamp = Math.floor(new Date().getTime() / 1000);
    const userTimestamp = Math.floor(timestamp / 1000)
    const differenceInSeconds = currentTimestamp - userTimestamp;

    const days = Math.floor(differenceInSeconds / (60 * 60 * 24));
    const hours = Math.floor((differenceInSeconds % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((differenceInSeconds % (60 * 60)) / 60);

    let customFormat = '';
    if (days > 0) {
        customFormat += `${days} day${days > 1 ? 's' : ''} `;
    }
    if (hours > 0) {
        customFormat += `${hours} hr${hours > 1 ? 's' : ''} ${minutes} min${minutes > 1 ? 's' : ''} `;
    }
    if (minutes > 0 && days === 0 && hours === 0) {
        customFormat += `${minutes} min${minutes > 1 ? 's' : ''} `;
    }

    customFormat += 'ago';

    const formattedDate = new Date(userTimestamp * 1000);
    const month = formattedDate.toLocaleString('default', { month: 'short' });
    const day = formattedDate.getDate();
    const year = formattedDate.getFullYear();
    const time = formattedDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
    const timeZoneOffset = formattedDate.getTimezoneOffset();
    const timeZoneOffsetHours = Math.floor(Math.abs(timeZoneOffset) / 60);
    const timeZoneOffsetMinutes = Math.abs(timeZoneOffset) % 60;
    const timeZoneOffsetSign = timeZoneOffset < 0 ? '+' : '-';
    const timeZoneOffsetFormatted = `${timeZoneOffsetSign}${timeZoneOffsetHours.toString().padStart(2, '0')}:${timeZoneOffsetMinutes.toString().padStart(2, '0')}`;

    return `${customFormat} (${month}-${day}-${year} ${time} ${timeZoneOffsetFormatted} +UTC)`;

}
