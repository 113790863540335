import {
  getMainnetOperatorsDetails,
  getTestnetOperatorsDetails,
} from "../../Apis/EndPoints";
import calculateTransactionFee from "../../Utils/calculateTransactionFee";
import { Link, useNavigate, useParams } from "react-router-dom";
import StickyHeadTable from "../Tables/StickyHeadTable";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useEffect, useMemo, useState } from "react";
import CopyButton from "../../Helpers/CopyButton";
import SearchBar from "../../Helpers/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import { Tooltip } from "@mui/material";
import { GetDuties, GetValidators } from "../../Redux/Apis/ApiService";

export default function ValidatorInfo() {
  const [operatorData, setOperatorData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [Tab, setTab] = useState("Txns");
  const { key, source } = useParams();
  const Navigate = useNavigate();
  const dispatch = useDispatch()
  const validator = useSelector((state) => state.validator);
  const duties = useSelector((state) => state.duties);

  useEffect(() => {
    document.title = "Block Sail | Validator Details";
    dispatch(GetValidators({ key }));
    dispatch(GetDuties({ key, parameter: "publicKey" }))

  }, [dispatch, key, Navigate]);

  console.log(key);

  useEffect(() => {
    if (validator?.validatorInfo?.operatorIds?.length) {
      GetOperatorrDetails(validator?.validatorInfo?.operatorIds);
    }
  }, [validator?.validatorInfo?.operatorIds]);

  const GetOperatorrDetails = (ids) => {
    let data = []
    ids.forEach(async (id) => {
      await Axios.get(
        `${source === "SSV-Testnet"
          ? getTestnetOperatorsDetails
          : getMainnetOperatorsDetails
        }/${id}`
      )
        .then((res) => {
          setIsLoading(false);
          data.push(res.data);
          // setOperatorData((prev) => [
          //   ...prev,
          //   ...data?.filter(
          //     (obj2) => !prev?.some((obj1) => obj1.id === obj2.id)
          //   ),
          // ]);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    });
    setOperatorData(data)
  };

  const columns = useMemo(
    () => [
      {
        id: "hash",
        label: "HASH",
        minWidth: 170,
        style: {
          color: "#01BF97",
        },
        anchor: true,
        format: (hash) => {
          const has1 = hash?.slice(0, 6);
          const has2 = hash?.slice(hash.length - 6, hash.length);
          return has1 && has2 ? has1 + "..." + has2 : "NA";
        },
      },
      {
        id: "typeName",
        label: "METHOD",
        minWidth: 170,
        format: (hash, row) => {
          return (
            <div
              className={`${row?.decodeData[0]?.typeName ? "w-32" : "hidden"}`}
            >
              <p className="px-3 truncate py-1.5 rounded-lg bg-slate-100 text-gray-600 font-mono tracking-tighter font-semibold border border-gray-300 text-xs">
                {row?.decodeData[0]?.typeName
                  ? row?.decodeData[0]?.typeName
                  : ""}
              </p>
            </div>
          );
        },
      },
      {
        id: "blockNumber",
        label: "BLOCK",
        minWidth: 170,
        style: {
          color: "#01BF97",
        },
        toolTip: false,
        anchor: true,
        format: (value) => {
          let val = value;
          return val;
        },
      },
      {
        id: "from",
        label: "FROM",
        minWidth: 170,
        style: {
          color: "#01BF97",
        },
        anchor: true,
        format: (hash) => {
          const has1 = hash?.slice(0, 6);
          const has2 = hash?.slice(hash.length - 6, hash.length);
          return has1 && has2 ? has1 + "..." + has2 : "NA";
        },
      },
      {
        id: "to",
        label: "TO",
        minWidth: 170,
        style: {
          color: "#01BF97",
        },
        anchor: true,

        format: (hash) => {
          const has1 = hash?.slice(0, 6);
          const has2 = hash?.slice(hash.length - 6, hash.length);
          return has1 && has2 ? has1 + "..." + has2 : "NA";
        },
      },
      {
        id: "value",
        label: "AMOUNT",
        minWidth: 170,
        toolTip: false,
        format: (value) => {
          let val = parseInt(value, 16);
          return val + " " + "ETH";
        },
      },
      {
        id: "gasUsed",
        label: "TXN FEE",
        minWidth: 170,
        toolTip: false,
        align: "center",
        format: (value, row) => {
          const txnFee = calculateTransactionFee(value, row.gasPrice, 11);
          return `${txnFee ? txnFee : ""} ETH`;
        },
      },
    ],
    [validator]
  );

  const column = useMemo(
    () => [
      {
        id: "name",
        label: "NAME",
        minWidth: 170,
        style: {
          color: "#01BF97",
        },
        toolTip: false,
        anchor: true,
        format: (_, row) => {
          return (
            <span
              onClick={() =>
                Navigate(`/explorer/${source}/operators/${row.id}`, {})
              }
            >
              {row.name}
            </span>
          );
        },
      },
      {
        id: "id",
        label: "ID",
        minWidth: 170,
        style: {
          color: "#01BF97",
        },
        toolTip: false,
        anchor: true,
      },
      {
        id: "validators_count",
        label: "VALIDATORS",
        minWidth: 170,
        toolTip: false,
      },
      {
        id: "status",
        label: "STATUS",
        minWidth: 170,
        toolTip: false,
        align: "center",
        format: (value) => {
          return (
            <div className="flex justify-center items-center text-center w-full">
              <span
                className={`w-fit space-x-10 ${value === "No validators"
                  ? "bg-[#B9B826]/40"
                  : value === "Active"
                    ? "bg-green-100"
                    : "bg-red-100"
                  } px-4 py-2 rounded-md justify-center text-center items-center`}
              >
                <span
                  className={`text-xs font-semibold ${value === "No validators"
                    ? "text-[#B9B826]"
                    : value === "Active"
                      ? "text-green-500"
                      : "text-[red]/60"
                    }`}
                >
                  {value}
                </span>
              </span>
            </div>
          );
        },
      },
    ],
    [operatorData]
  );

  const columnn = useMemo(
    () => [
      {
        id: "epoch",
        label: "EPOCH",
        minWidth: 170,
        style: {
          color: "#01BF97",
        },
        toolTip: false,
      },
      {
        id: "slot",
        label: "SLOT",
        minWidth: 170,
        style: {
          color: "#01BF97",
        },
        toolTip: false,
      },
      {
        id: "duty",
        label: "DUTY",
        minWidth: 170,
        toolTip: false,
      },
      {
        id: "status",
        label: "STATUS",
        minWidth: 170,
        toolTip: false,
        align: "center",
        format: (value) => {
          return (
            <div className="flex justify-center items-center text-center w-full">
              <span
                className={`w-fit space-x-10 ${value === "Failed"
                  ? "bg-[#B9B826]/40"
                  : value === "Success"
                    ? "bg-green-100"
                    : "bg-red-100"
                  } px-4 py-2 rounded-md justify-center text-center items-center`}
              >
                <span
                  className={`text-xs font-semibold ${value === "Failed"
                    ? "text-[#B9B826]"
                    : value === "Success"
                      ? "text-green-500"
                      : "text-[red]/60"
                    }`}
                >
                  {value}
                </span>
              </span>
            </div>
          );
        },
      },
      {
        id: "operators",
        label: "OPERATORS CONSENSUS BREAKDOWN",
        minWidth: 170,
        toolTip: false,
        align: "center",
        format: (value) => {
          const missing = operatorData.filter((item) => !value.some((item1) => item1 === item.id))
          const active = operatorData.filter((item) => value.some((item1) => item1 === item.id))
          return (
            <div className='flex space-x-2 justify-center items-center text-center w-full'>
              {
                active?.map((item, index) => (
                  <Tooltip key={index} title={`Click to see operator details`} placement='top' arrow>
                    <span
                      onClick={() => Navigate(`/explorer/${source}/operators/${item.id}`)}
                      className='px-2 py-1 text-xs cursor-pointer rounded-lg bg-green-200 text-green-600 border shadow-sm'>{item?.name}</span>
                  </Tooltip>
                ))
              }
              {
                missing.map((item, index) => (
                  <Tooltip key={index} title={`Click to see operator details`} placement='top' arrow>
                    <span
                      onClick={() => Navigate(`/explorer/${source}/operators/${item.id}`)}
                      className='px-2 py-1 text-xs cursor-pointer rounded-lg bg-red-200 border text-red-600 shadow-sm'>{item?.name}</span>
                  </Tooltip>
                ))
              }
            </div>
          )
        }
      },
    ],
    [duties, operatorData, validator]
  );

  return (
    <div className="space-y-10 mb-4">
      <div className="space-y-8">
        <div className="flex justify-between w-full items-center lg:flex-row flex-col lg:space-y-0 space-y-2">
          <h1 className="text-xl font-semibold text-[#0B2244] uppercase ml-2">
            Validators
          </h1>
          <div className="max-w-xl w-full">
            <SearchBar id={"Small"} />
          </div>
        </div>
        <div className="flex lg:space-x-10 lg:flex-row flex-col space-y-3 lg:space-y-0">
          <div className="w-full">
            <div className="px-6 py-6 bg-white rounded-2xl shadow-2xl shadow-[#EFF0F6]">
              <div className="flex justify-between">
                <div className="space-y-4 ">
                  <h1 className="text-[#0B2244] text-lg font-bold tracking-tight">
                    Validator
                  </h1>
                  <div className="flex space-x-6 bg-[#EEEEEE] px-4 py-2 rounded-md justify-center text-center items-center">
                    <span className="text-xs font-medium text-gray-600">{`${key.slice(0, 6) +
                      "..." +
                      key.slice(key.length - 6, key.length)
                      }`}</span>
                    <span className="text-xs">
                      <CopyButton value={key} />
                    </span>
                  </div>
                </div>
                <Link
                  to={`https://prater.beaconcha.in/validator/${key}`}
                  target="_blank"
                  className="bg-[#EEEEEE] px-1 py-1 h-6 rounded-xl -mt-3 -mr-2 cursor-pointer"
                >
                  <svg
                    className="ml-0.5 mt-0.5"
                    width="14"
                    height="11"
                    viewBox="0 0 16 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.888 7.35662C13.3025 1.42948 6.10773 -1.39402 0.181151 1.1927L0.98087 3.02553C5.91549 0.872393 11.902 3.22172 14.0551 8.15634L15.888 7.35662ZM12.2119 9.15284C11.3712 7.22447 9.7956 5.72633 7.77558 4.9336C5.75556 4.14087 3.58157 4.16753 1.65375 5.00947L2.45347 6.84229C3.89225 6.2145 5.52239 6.19679 7.04532 6.79444C8.56788 7.39302 9.75124 8.51378 10.379 9.95256L12.2119 9.15284ZM7.44538 11.2495C7.04187 12.2777 5.88121 12.7842 4.85298 12.3806C3.82476 11.9771 3.31833 10.8165 3.72184 9.78825C4.12535 8.76002 5.28601 8.25359 6.31423 8.65711C7.34246 9.06062 7.84889 10.2213 7.44538 11.2495Z"
                      fill="#7D8398"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
          <div className="w-full">
            <div className="px-6 py-6 bg-white rounded-2xl shadow-2xl shadow-[#EFF0F6]">
              <div className="flex justify-between">
                <div className="space-y-4 ">
                  <div className="flex space-x-1">
                    <h1 className="text-[#0B2244] text-lg font-bold tracking-tight">
                      Status
                    </h1>
                    <span className="text-sm mt-2">
                      <AiOutlineInfoCircle />
                    </span>
                  </div>
                  <div
                    className={`${validator?.data?.data?.isRemoved
                      ? "bg-red-100"
                      : "bg-green-100"
                      } flex space-x-10  px-4 py-2 rounded-md justify-center text-center items-center`}
                  >
                    <span
                      className={`text-xs font-semibold  ${validator?.data?.data?.isRemoved
                        ? "text-red-500"
                        : "text-green-500"
                        }`}
                    >{`${validator?.data?.data?.isRemoved ? "INACTIVE" : "ACTIVE"
                      }`}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className='w-full'>
                        <div className='px-6 py-6 bg-white rounded-2xl shadow-2xl shadow-[#EFF0F6]'>
                            <div className='flex justify-between'>
                                <div className='space-y-4 '>
                                    <div className='flex space-x-1'>
                                        <h1 className='text-[#0B2244] text-lg font-bold tracking-tight'>Performance</h1>
                                        <span className='text-sm mt-2'><AiOutlineInfoCircle /></span>
                                    </div>
                                    <div className='text-left ml-1'>
                                        <span className='text-2xl font-medium text-gray-800'>{`99.98 %`}</span>
                                    </div>
                                </div>
                                <div className='space-x-2'>
                                    <button className='text-xs font-medium'>1D</button>
                                    <button className='text-xs font-medium' >1M</button>
                                </div>
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>
      <div className="space-y-3">
        <div className="flex space-x-4">
          <div
            onClick={() => setTab("Txns")}
            className={`${Tab === "Txns"
              ? "bg-[#01BF97] text-white"
              : "bg-[#E5E5E5] text-[#0B2244]"
              } rounded-xl font-semibold px-4 py-1.5 w-36 cursor-pointer`}
          >
            <span className="w-full flex text-center justify-center text-lg items-center">
              Transactions
            </span>
          </div>
          {
            validator?.validatorInfo?.operatorIds?.length ?
              <div
                onClick={() => setTab("Operators")}
                className={`${Tab === "Operators"
                  ? "bg-[#01BF97] text-white"
                  : "bg-[#E5E5E5] text-[#0B2244]"
                  } rounded-xl font-semibold px-4 py-1.5 w-36 cursor-pointer`}
              >
                <span className="w-full flex text-center justify-center text-lg items-center">
                  Operators
                </span>
              </div>
              :
              null
          }
          <div
            onClick={() => setTab("Duties")}
            className={`${Tab === "Duties"
              ? "bg-[#01BF97] text-white"
              : "bg-[#E5E5E5] text-[#0B2244]"
              } rounded-xl font-semibold px-4 py-1.5 w-36 cursor-pointer`}
          >
            <span className="w-full flex text-center justify-center text-lg items-center">
              Duties
            </span>
          </div>
        </div>

        {Tab === "Txns" && (
          <StickyHeadTable
            columns={columns}
            tableId={"Validator"}
            rows={validator?.transaction?.transactions}
            isLoading={validator.isLoading}
            pagiNation={true}
            totalRecords={validator?.transaction?.totalRecords}
          />
        )}

        {Tab === "Operators" && (
          <StickyHeadTable
            columns={column}
            tableId={"9999"}
            rows={operatorData}
            isLoading={isLoading}
            totalRecords={operatorData?.length}
          />
        )}
        {Tab === "Duties" && (
          <StickyHeadTable
            columns={columnn}
            tableId={"Duties"}
            rows={duties.data}
            isLoading={duties.isLoading}
            pagiNation={true}
            totalRecords={duties.totalRecords}
          />
        )}
      </div>
    </div>
  );
}

