import { GetBlockData } from "../Apis/ApiService";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
    isLoading: true,
    totalRecords: null,
    totalPages: null,
    error: null,
};
export const Block = createSlice({
    name: "blockData",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetBlockData.pending, (state, action) => {
                if (action?.meta?.arg?.limit && action?.meta?.arg?.page) {
                    if (action?.meta?.arg?.limit * action?.meta?.arg?.page <= state?.data?.length) {
                        state.isLoading = false;
                    } else {
                        state.isLoading = true;
                    }
                }
            })
            .addCase(GetBlockData.fulfilled, (state, action) => {
                state.data = [...state.data, ...action.payload?.data?.filter(obj2 => !state?.data?.some(obj1 => obj1?.blockNumber === obj2?.blockNumber))];
                state.totalRecords = action?.payload?.totalRecords;
                state.totalPages = action?.payload?.totalPages
                state.isLoading = false;
            })
            .addCase(GetBlockData.rejected, (state, action) => {
                state.error = action.error.message;
                state.isLoading = false;
            });
    },

});

export default Block.reducer;