import { BsArrowRight, BsSearch } from "react-icons/bs";
import { RiArrowDropDownLine } from "react-icons/ri";
import { PiSortAscendingThin } from "react-icons/pi";
import ApiServices from "../../Services/ApiServices";
import { Source } from "../../Assets/Icons/Icons";
import { useNavigate } from "react-router-dom";
import AddSourceModal from "./AddSourceModal";
import { Box, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetDataSources } from "../../Redux/Apis/ApiService";


export default function DataSources() {
    const [showModal, setShowModal] = useState(false);
    const Navigate = useNavigate()
    const dispatch = useDispatch()
    const dataSource = useSelector((state) => state.sources)

    useEffect(() => {
        dispatch(GetDataSources())
    }, [dispatch])

    return (
        <>
            <div className="p-4 my-20 min-h-[65vh] w-full bg-white rounded-xl shadow-md border border-gray-500">
                <div className="p-6 space-y-10">
                    <div className="flex justify-between">
                        <div className="flex flex-col space-y-1">
                            <h1 className="font-bold text-[#04D1BE] text-2xl">
                                Data Sources
                            </h1>
                            <span className="text-base tracking-tight font-normal">
                                View and manage your connected data source connections
                            </span>
                        </div>
                        <div>
                            <button
                                onClick={() => setShowModal(true)}
                                className="text-center text-white text-md font-semibold bg-[#04D1BE] rounded-lg px-3 py-2">+ Add new data source</button>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex justify-between w-full space-x-10">
                            <div className="flex relative w-3/4">
                                <input
                                    placeholder="Search by Name or address"
                                    type="search"
                                    className="border w-full border-gray-500 lg:text-lg lg:pl-12 pr-2 text-gray-800 rounded-lg focus:outline-none focus:border-[#04C2AE] focus:ring-1 focus:ring-[#04C2AE] placeholder-gray-500 lg:placeholder:text-sm"
                                />
                                <span className='absolute lg:text-lg text-sm inset-y-0 lg:left-3 left-1 flex items-center cursor-pointer pr-3 text-[#04C2AE]'>
                                    <BsSearch />
                                </span>
                            </div>
                            <div className="flex relative w-1/4">
                                <select
                                    placeholder="Search by Name or address"
                                    type="search"
                                    className="border w-full border-gray-500 lg:text-sm appearance-none px-2 p-2 lg:pl-8 pr-2 text-gray-800 rounded-lg focus:outline-none focus:border-[#04C2AE] focus:ring-1 focus:ring-[#04C2AE] "
                                >
                                    <option >
                                        Sort by A to Z
                                    </option>
                                    <option>
                                        Sort by Z to A
                                    </option>
                                </select>
                                <span className='absolute lg:text-lg text-sm inset-y-0 lg:left-2 left-1 flex items-center cursor-pointer pr-3 text-[#04C2AE]'>
                                    <PiSortAscendingThin />
                                    {/* <PiSortDescendingThin/> */}
                                </span>
                                <span className='absolute lg:text-lg text-3xl inset-y-0 right-1 flex items-center cursor-pointer pr-3 text-[#04C2AE]'>
                                    <RiArrowDropDownLine />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="space-y-4">
                        {
                            dataSource?.isLoading
                                ?
                                <div className="w-full">
                                    <Box sx={{ width: "100%" }}>
                                        <Skeleton height={60} />
                                        <Skeleton animation="wave" height={60} />
                                        <Skeleton animation="wave" height={60} />
                                        <Skeleton animation={false} height={60} />
                                    </Box>
                                </div>
                                :
                                (dataSource?.data?.length && dataSource?.data?.map((item, i) => (
                                    <div key={i} className="w-full flex justify-between rounded bg-[#C9EFEC80] py-3 text-center">
                                        <div className="px-6 text-center flex justify-center items-center space-x-2">
                                            <Source />
                                            <h1 className="text-lg font-bold uppercase">{item?.dataSource}</h1>
                                        </div>
                                        <div className="px-6" >
                                            <button
                                                onClick={() => {
                                                    localStorage.setItem("source", JSON.stringify(item))
                                                    Navigate(`/explorer/${item.dataSource}`)
                                                }}
                                                className="px-3 mt-1 flex justify-center text-center items-center py-1 border text-sm text-gray-600 border-gray-600 font-medium bg-white">
                                                Explore data source
                                                <span className="mt-0.5 pl-2 text-[#04C2AE]">
                                                    <BsArrowRight />
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                )) ||
                                    <div className="text-center flex justify-center items-center">
                                        <span className="font-bold text-gray-600 text-base"> No Data Source Found!!</span>
                                    </div>
                                )
                        }
                    </div>
                </div>
            </div>
            <AddSourceModal showModal={showModal} setShowModal={setShowModal} />
        </>
    )
}
