import Store, { clearStoreAction } from './Redux/Store/Store';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import React from 'react';
import App from './App';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

window.addEventListener('beforeunload', () => {
  Store.dispatch(clearStoreAction());
});

root.render(
  <>
    <BrowserRouter>
      <Provider store={Store}>
        <App />
      </Provider>
    </BrowserRouter>
  </>
);

