import { GetLogsData } from "../Apis/ApiService";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: {},
    isLoading: false,
    error: null,
};
export const Logs = createSlice({
    name: "logs",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetLogsData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(GetLogsData.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isLoading = false;
            })
            .addCase(GetLogsData.rejected, (state, action) => {
                state.error = action.error.message;
                state.data = action.payload;
                state.isLoading = false;
            });
    },

});

export default Logs.reducer;