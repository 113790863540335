import { Buffer } from "buffer";

class AuthService {
  static instance = null;
  sourceType = null;
  authToken = null;
  chatauthToken = null;
  authType = null;
  source = null;
  token = null;

  static get() {
    if (AuthService.instance == null) {
      console.log("AuthService initializing");
      AuthService.instance = new AuthService();
    }

    return AuthService.instance;
  }
  Login = (token, authUser) => {
    this.token = token;
    this.authUser = authUser;
    localStorage.setItem("refreshTokenExp", token.refreshTokenExp);
    localStorage.setItem("accessTokenExp", token.accessTokenExp);
    localStorage.setItem("refreshToken", token.refreshToken);
    localStorage.setItem("id", JSON.stringify(authUser._id));
    localStorage.setItem("accessToken", token.accessToken);
    localStorage.setItem("user", JSON.stringify(authUser));
  };

  ChatLogin = (token, authUser) => {
    this.chatauthToken = token.accessToken;
    this.authUser = authUser;
    localStorage.setItem("ChatrefreshTokenExp", token.refreshTokenExp);
    localStorage.setItem("ChataccessTokenExp", token.accessTokenExp);
    localStorage.setItem("ChatrefreshToken", token.refreshToken);
    localStorage.setItem("Chatid", JSON.stringify(authUser._id));
    localStorage.setItem("ChataccessToken", token.accessToken);
    localStorage.setItem("Chatuser", JSON.stringify(authUser));
  };
  Logout = () => {
    localStorage.removeItem("refreshTokenExp");
    localStorage.removeItem("accessTokenExp");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    localStorage.removeItem("id");
    if (localStorage.getItem("accessToken" === null)) {
      return true;
    }
    return false;
  };
  ChatLogout = () => {
    localStorage.removeItem("ChatrefreshTokenExp");
    localStorage.removeItem("ChataccessTokenExp");
    localStorage.removeItem("ChatrefreshToken");
    localStorage.removeItem("ChataccessToken");
    localStorage.removeItem("Chatuser");
    localStorage.removeItem("Chatid");
    if (localStorage.getItem("accessToken" === null)) {
      return true;
    }
    return false;
  };

  authenticationType = () => {
    if (this.authType === null) {
      try {
        const authTypeFromStorage = localStorage.getItem("type");
        if (authTypeFromStorage) {
          this.authType = authTypeFromStorage;
        }
      } catch (e) {
        console.log(e);
        this.authType = null;
        this.user = null;
      }
    }
    return this.authType ? this.authType : "public";
  };

  dataSourceTypes = () => {
    if (this.sourceType === null) {
      try {
        const sourceTypeFromStorage = JSON.parse(
          localStorage.getItem("source")
        );
        if (sourceTypeFromStorage) {
          this.sourceType = sourceTypeFromStorage.dataSource;
        }
      } catch (e) {
        console.log(e);
        this.sourceType = null;
      }
    }
    return this.sourceType ? this.sourceType : "SSV_Testnet";
  };

  isAuthenticated = () => {
    if (this.authToken === null) {
      try {
        const authTokenFromStorage = localStorage.getItem("accessToken");
        if (authTokenFromStorage) {
          this.authToken = authTokenFromStorage;
        }
      } catch (e) {
        console.log(e);
      }
    }
    return this.authToken;
  };

  chatisAuthenticated = () => {
    if (this.chatauthToken === null) {
      try {
        const authTokenFromStorage = localStorage.getItem("ChataccessToken");
        if (authTokenFromStorage) {
          this.chatauthToken = authTokenFromStorage;
        }
      } catch (e) {
        console.log(e);
      }
    }
    return this.chatauthToken;
  };

  isAccessTokenExpired = (accessToken) => {
    const decodedToken = JSON.parse(
      Buffer.from(accessToken.split(".")[1], "base64")
    );
    const expirationTime = decodedToken.exp * 1000;
    const currentTime = Date.now();
    if (expirationTime < currentTime) {
      return true;
    } else {
      return false;
    }
  };

  isRefreshTokenExpired = (expirationTime) => {
    const currentTime = Date.now();
    if (expirationTime < currentTime) {
      return true;
    } else {
      return false;
    }
  };

  getSource = () => {
    if (this.source === null) {
      try {
        const source = JSON.parse(localStorage.getItem("source"));
        if (source) {
          this.source = source;
        }
      } catch (error) {
        this.source = null;
      }
    }
    return this.source;
  };
}

export default AuthService;
