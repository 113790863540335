export function ButtonLoader({ text }) {
    return (
        <div className="text-center">
            <div role="status">
                <svg aria-hidden="true" className="inline w-5 h-5 mr-2 text-gray-200 animate-spin fill-[#04D1BE]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                </svg>
                <span>{text}</span>
            </div>
        </div>
    )
}

export function Source({ }) {
    return (
        <svg width="40" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.5" d="M5 36.667H35" stroke="#04D1BE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5 18.3333C5 16.762 5 15.9763 5.48815 15.4881C5.97632 15 6.76198 15 8.33333 15C9.90468 15 10.6903 15 11.1785 15.4881C11.6667 15.9763 11.6667 16.762 11.6667 18.3333V28.3333C11.6667 29.9047 11.6667 30.6903 11.1785 31.1785C10.6903 31.6667 9.90468 31.6667 8.33333 31.6667C6.76198 31.6667 5.97632 31.6667 5.48815 31.1785C5 30.6903 5 29.9047 5 28.3333V18.3333Z" stroke="#04D1BE" strokeWidth="1.5" />
            <path d="M16.6666 11.6668C16.6666 10.0955 16.6666 9.30981 17.1548 8.82165C17.643 8.3335 18.4286 8.3335 20 8.3335C21.5713 8.3335 22.357 8.3335 22.8451 8.82165C23.3333 9.30981 23.3333 10.0955 23.3333 11.6668V28.3335C23.3333 29.9048 23.3333 30.6905 22.8451 31.1787C22.357 31.6668 21.5713 31.6668 20 31.6668C18.4286 31.6668 17.643 31.6668 17.1548 31.1787C16.6666 30.6905 16.6666 29.9048 16.6666 28.3335V11.6668Z" stroke="#04D1BE" strokeWidth="1.5" />
            <path d="M28.3334 6.66683C28.3334 5.09548 28.3334 4.30981 28.8215 3.82165C29.3097 3.3335 30.0954 3.3335 31.6667 3.3335C33.238 3.3335 34.0237 3.3335 34.5119 3.82165C35 4.30981 35 5.09548 35 6.66683V28.3335C35 29.9048 35 30.6905 34.5119 31.1787C34.0237 31.6668 33.238 31.6668 31.6667 31.6668C30.0954 31.6668 29.3097 31.6668 28.8215 31.1787C28.3334 30.6905 28.3334 29.9048 28.3334 28.3335V6.66683Z" stroke="#04D1BE" strokeWidth="1.5" />
        </svg>

    )
}

export function RotateLoading({ }) {
    return (
        <div className="flex justify-center items-center text-center min-h-[88vh] ">
            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="200px" height="200px" viewBox="0 0 100 100">
                <g transform="rotate(0 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#04D1BE">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="2.083333333333333s" begin="-1.909722222222222s" repeatCount="indefinite"></animate>
                    </rect>
                </g><g transform="rotate(30 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#04D1BE">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="2.083333333333333s" begin="-1.736111111111111s" repeatCount="indefinite"></animate>
                    </rect>
                </g><g transform="rotate(60 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#04D1BE">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="2.083333333333333s" begin="-1.5624999999999998s" repeatCount="indefinite"></animate>
                    </rect>
                </g><g transform="rotate(90 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#04D1BE">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="2.083333333333333s" begin="-1.3888888888888886s" repeatCount="indefinite"></animate>
                    </rect>
                </g><g transform="rotate(120 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#04D1BE">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="2.083333333333333s" begin="-1.2152777777777777s" repeatCount="indefinite"></animate>
                    </rect>
                </g><g transform="rotate(150 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#04D1BE">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="2.083333333333333s" begin="-1.0416666666666665s" repeatCount="indefinite"></animate>
                    </rect>
                </g><g transform="rotate(180 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#04D1BE">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="2.083333333333333s" begin="-0.8680555555555555s" repeatCount="indefinite"></animate>
                    </rect>
                </g><g transform="rotate(210 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#04D1BE">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="2.083333333333333s" begin="-0.6944444444444443s" repeatCount="indefinite"></animate>
                    </rect>
                </g><g transform="rotate(240 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#04D1BE">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="2.083333333333333s" begin="-0.5208333333333333s" repeatCount="indefinite"></animate>
                    </rect>
                </g><g transform="rotate(270 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#04D1BE">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="2.083333333333333s" begin="-0.34722222222222215s" repeatCount="indefinite"></animate>
                    </rect>
                </g><g transform="rotate(300 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#04D1BE">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="2.083333333333333s" begin="-0.17361111111111108s" repeatCount="indefinite"></animate>
                    </rect>
                </g><g transform="rotate(330 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#04D1BE">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="2.083333333333333s" begin="0s" repeatCount="indefinite"></animate>
                    </rect>
                </g>
            </svg>
        </div>
    )
}