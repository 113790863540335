import convertTimestampToRelativeTime from "../../Utils/convertRelativeTimeStamp";
import calculateTransactionFee from "../../Utils/calculateTransactionFee";
import SearchBar from "../../Helpers/SearchBar";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Table } from "../Tables";

export default function AllTxns() {
  const input = useSelector((state) => state.inputData);

  useEffect(() => {
    document.title = "Block Sail | Transactions";
  }, []);

  const columns = useMemo(
    () => [
      {
        id: "hash",
        label: "HASH",
        minWidth: 170,
        style: {
          color: "#01BF97",
        },
        anchor: true,
        format: (hash) => {
          const has1 = hash?.slice(0, 6);
          const has2 = hash?.slice(hash.length - 6, hash.length);
          return has1 && has2 ? has1 + "..." + has2 : "NA";
        },
      },
      {
        id: "typeName",
        label: "METHOD",
        minWidth: 170,
        format: (hash, row) => {
          return (
            <div
              className={`${row?.decodeData[0]?.typeName ? "w-28" : "hidden"}`}
            >
              <p className="px-2 truncate py-1 rounded-lg bg-slate-100 text-gray-600 font-mono tracking-tighter font-semibold border border-gray-300 text-xs">
                {row?.decodeData[0]?.typeName
                  ? row?.decodeData[0]?.typeName
                  : ""}
              </p>
            </div>
          );
        },
      },
      {
        id: "blockNumber",
        label: "BLOCK",
        minWidth: 170,
        style: {
          color: "#01BF97",
        },
        toolTip: false,
        anchor: true,
        format: (value) => {
          let val = value;
          return val;
        },
      },
      {
        id: "from",
        label: "FROM",
        minWidth: 170,
        style: {
          color: "#01BF97",
        },
        anchor: true,
        format: (hash) => {
          const has1 = hash?.slice(0, 6);
          const has2 = hash?.slice(hash.length - 6, hash.length);
          return has1 && has2 ? has1 + "..." + has2 : "NA";
        },
      },
      {
        id: "to",
        label: "TO",
        minWidth: 170,
        style: {
          color: "#01BF97",
        },
        anchor: true,

        format: (hash) => {
          const has1 = hash?.slice(0, 6);
          const has2 = hash?.slice(hash.length - 6, hash.length);
          return has1 && has2 ? has1 + "..." + has2 : "NA";
        },
      },
      {
        id: "value",
        label: "AMOUNT",
        minWidth: 170,
        toolTip: false,
        format: (value) => {
          let val = parseInt(value, 16);
          return val + " " + "ETH";
        },
      },
      {
        id: "blockTimeStamp",
        label: "TIMESTAMP",
        minWidth: 170,
        toolTip: false,
        format: (value) => {
          let inputString = convertTimestampToRelativeTime(value);
          const parts = inputString.split("(");
          const resultString = parts[0].trim();
          return (
            <span className="py-1 text-gray-800 text-clip truncate text-xs">
              {resultString}
            </span>
          );
        },
      },
      {
        id: "gasUsed",
        label: "TXN FEE",
        minWidth: 170,
        align: "center",
        toolTip: false,
        format: (value, row) => {
          const txnFee = calculateTransactionFee(value, row.gasPrice, 11);
          return `${txnFee ? txnFee : ""} ETH`;
        },
      },
    ],
    [input]
  );

  return (
    <div className="w-full space-y-6 mb-4">
      <div className="px-3 py-1 flex justify-between w-full items-center lg:flex-row flex-col lg:space-y-0 space-y-2">
        <span className="text-2xl font-bold text-gray-600 uppercase">
          Transactions
        </span>
        <div className="max-w-xl w-full">
          <SearchBar id={"Small"} />
        </div>
      </div>
      <div className="flex lg:justify-between lg:flex-row flex-col lg:space-y-0 space-y-4 lg:space-x-6">
        <div className="w-full">
          <Table
            columns={columns}
            rows={input.data.length ? input.data : []}
            isLoading={input.isLoading}
            tableId={"Txn"}
            pagiNation={true}
            totalRecords={input.totalRecords}
            totalPages={input.totalPages}
          />
        </div>
      </div>
    </div>
  );
}

