import AuthService from '../Services/AuthService';
import { useNavigate } from 'react-router-dom';
import React from 'react';

const RedirectHome = () => {
    const navigate = useNavigate();
    const authService = AuthService.get()
    const type = authService.authenticationType()
    const dataSource = authService.dataSourceTypes()
    const source = authService.getSource()

    React.useEffect(() => {
        if (type === "public") {
            navigate(`/explorer/${dataSource}/`, { replace: true });
        }
    }, [navigate]);

    return null;
};

export default RedirectHome;
