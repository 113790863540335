import { getNewAccessToken } from "../Apis/EndPoints";
import AuthService from "../Services/AuthService";
import ApiServices from "../Services/ApiServices";
import { Buffer } from "buffer";
import Axios from "axios";

export const getConfig = () => {
  const authService = AuthService.get();
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      dataSource: `private`,
    },
  };
  return config;
};

export const ChatgetConfig = () => {
  const authService = AuthService.get();
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      dataSource: `public`,
    },
  };
  return config;
};
export const userLogin = async (path, body) => {
  const authService = AuthService.get();
  const config = getConfig();
  const result = await Axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}${path}`,
    body,
    config
  )
    .then((res) => {
      const token = res?.data?.token;
      const authUser = res?.data?.data;
      authService.Login(token, authUser);
      const result = {
        ...res?.data,
        status: true,
        message: "Login Successfully!",
      };
      return result;
    })
    .catch((error) => {
      console.log("error", error.response);

      if (error?.response?.data?.isConfirmed === false) {
        const result = {
          isConfirmed: false,
          message: error?.response?.data?.message,
        };
        return result;
      } else if (error?.response?.data?.reason) {
        const result = {
          message: error?.response?.data?.reason,
        };
        return result;
      } else {
        const result = {
          status: false,
          message: "Incorrect username or password",
        };
        return result;
      }
    });
  return result;
};

export const ChatuserLogin = async (path, body) => {
  const authService = AuthService.get();
  const config = ChatgetConfig();
  const result = await Axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}${path}`,
    body,
    config
  )
    .then((res) => {
      const token = res?.data?.token;
      const authUser = res?.data?.userData;
      authService.ChatLogin(token, authUser);
      const result = {
        ...res?.data,
        status: true,
        message: "Login Successfully!",
      };
      return result;
    })
    .catch((error) => {
      console.log("error", error.response);

      if (error?.response?.data?.isConfirmed === false) {
        const result = {
          isConfirmed: false,
          message: error?.response?.data?.message,
        };
        return result;
      } else if (error?.response?.data?.reason) {
        const result = {
          message: error?.response?.data?.reason,
        };
        return result;
      } else {
        const result = {
          status: false,
          message: "Incorrect username or password",
        };
        return result;
      }
    });
  return result;
};
export const getAccessToken = async () => {
  try {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log(user);
    const refreshToken = localStorage.getItem("refreshToken");
    const { data } = await ApiServices.post(getNewAccessToken, {
      name: user?.email,
      refreshToken: refreshToken,
    });
    localStorage.setItem("accessToken", data.token.newAccessToken);
    window.location.reload();
  } catch (error) {
    console.log(error);
  }
};

export const isAccessTokenExpired = (accessToken) => {
  const token = accessToken?.split(".")[1];
  if (token) {
    const decodedToken = JSON.parse(Buffer.from(token, "base64"));
    const expirationTime = decodedToken.exp * 1000;
    const currentTime = Date.now();
    if (expirationTime < currentTime) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

export const isTokenExpired = (expirationTime) => {
  const currentTime = Date.now();
  if (expirationTime < currentTime) {
    return true;
  } else {
    return false;
  }
};
