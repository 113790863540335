import BigNumber from 'bignumber.js';

const calculateTransactionFee = (value, gasPrice, length) => {
    try {
        // Convert the hexadecimal values to BigNumber 
        const bigNumberValue = new BigNumber(value);
        const bigNumberGasPrice = new BigNumber(gasPrice);

        // Calculate the transaction fee
        const txnFee = bigNumberValue.times(bigNumberGasPrice).dividedBy(new BigNumber('1000000000000000000'));

        // Format the transaction fee as a string with desired decimal places
        const formattedTxnFee = txnFee.toFixed(length ? length : txnFee.length); // Set the number of decimal places you want to display

        return formattedTxnFee;
    } catch (error) {
        console.error('Error calculating transaction fee:', error);
        return 'Error';
    }
};

export default calculateTransactionFee;
