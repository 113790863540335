import { RxCopy } from 'react-icons/rx';
import React, { useState } from 'react'
import { Tooltip } from './Tooltip';

export default function CopyButton({ value }) {
    const [isCopied, setIsCopied] = useState(false);

    const CopyHandler = async (value) => {
        try {
            if (navigator.clipboard && navigator.clipboard.writeText) {
                await navigator.clipboard.writeText(value ? value : "")
                setIsCopied(true);
            } else {
                copyTextToClipboardFallback(value)
            }
        } catch (error) {
            copyTextToClipboardFallback(value)
        }
    }

    const copyTextToClipboardFallback = (text) => {
        setIsCopied(true);
        const textArea = document.createElement("textarea");
        textArea.value = text ? text : "";
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
    };

    return (
        <Tooltip tooltipText={`${isCopied ? "Copied" : "Copy"}`} orientation="top">
            <RxCopy width="50" className="text-gray-400 cursor-pointer hover:text-[#04D1BE]/50" onClick={() => CopyHandler(value ? value : "")} onMouseLeave={() => setIsCopied(false)} />
        </Tooltip>)
}
