import {
  getMainnetOperatorsDetails,
  getTestnetOperatorsDetails,
} from "../../Apis/EndPoints";
import convertTimestampToRelativeTime from "../../Utils/convertRelativeTimeStamp";
import calculateTransactionFee from "../../Utils/calculateTransactionFee";
import { Link, useNavigate, useParams } from "react-router-dom";
import { RotateLoading } from "../../Assets/Icons/Icons";
import StickyHeadTable from "../Tables/StickyHeadTable";
import { useEffect, useMemo, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import CopyButton from "../../Helpers/CopyButton";
import SearchBar from "../../Helpers/SearchBar";
import { PiKeyFill } from "react-icons/pi";
import { useSelector } from "react-redux";
import Axios from "axios";
import AestusLogo from "../../Assets/images/aestus.svg";
import FlashbotsLogo from "../../Assets/images/flashbots.svg";
import bloXrouteLogo from "../../Assets/images/bloxroute.svg";
import BlocknativeLogo from "../../Assets/images/blocknative.svg";
import EdenLogo from "../../Assets/images/eden_network.svg";
import ManifoldLogo from "../../Assets/images/manifold.svg";
import UltrasoundLogo from "../../Assets/images/ultra_sound.svg";
import AgnosticLogo from '../../Assets/images/agnostic_gnosis.svg';
import LightSvg from '../../Assets/images/light.svg';
import VerifiedLogo from '../../Assets/images/verified.png';
import WhiteLogo from '../../Assets/images/white.png';
import TwitterLogo from '../../Assets/images/twitter.svg';
import LinkedinLogo from '../../Assets/images/linkedin.svg';
import websiteLogo from '../../Assets/images/website.svg';

export default function OperatorInfo() {
  const [operatorData, setOperatorData] = useState({});
  const [MEVOperatorData, setMEVOperatorData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { operatorId, source } = useParams();
  const [swith, setSwitch] = useState("1D");
  const [Tab, setTab] = useState("Txns");
  const navigate = useNavigate();
  const operator = useSelector((state) => state.operator);

  useEffect(() => {
    document.title = "Block Sail | Operator Details";
    if (operatorId) {
      if (source === "SSV-Testnet") {
        console.log("source:", source);
        getOperatorDetails(getTestnetOperatorsDetails);
      } else {
        console.log("source:", source);
        getOperatorDetails(getMainnetOperatorsDetails);
      }
    }
  }, [operatorId, source]);

  const getOperatorDetails = (apiUrl) => {
    const mevData = [];
    setLoading(true);
    Axios.get(apiUrl + `/${operatorId}`)
      .then((res) => {
        console.log("res.data : ", res.data);
        setOperatorData(res.data);
        if (res.data.mev_relays) {
          const values = res.data.mev_relays.split(',');
          values.map((key, i) => {
            if (key === "Flashbots") {
              mevData.push({
                logo: FlashbotsLogo,
                name: "Flashbots"
              })
            } else if (key === "bloXroute Max Profit") {
              mevData.push({
                logo: bloXrouteLogo,
                name: "bloXroute Max Profit"
              })
            } else if (key === "Blocknative") {
              mevData.push({
                logo: BlocknativeLogo,
                name: "Blocknative"
              });
            } else if (key === "Eden Network") {
              mevData.push({
                logo: EdenLogo,
                name: "Eden Network"
              });
            } else if (key === "Manifold") {
              mevData.push({
                logo: ManifoldLogo,
                name: "Manifold"
              });
            } else if (key === "Aestus") {
              mevData.push({
                logo: AestusLogo,
                name: "Aestus"
              });
            } else if (key === "Ultra Sound") {
              mevData.push({
                logo: UltrasoundLogo,
                name: "Ultra Sound"
              });
            } else if (key === 'Agnostic Gnosis') {
              mevData.push({
                logo: AgnosticLogo,
                name: "Agnostic Gnosis"
              });
            } else if (key === 'bloXroute Regulated') {
              mevData.push({
                logo: bloXrouteLogo,
                name: "bloXroute Regulated"
              });
            }
          });
          setMEVOperatorData(mevData);
        } else {
          setMEVOperatorData('');
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("error : ", error);
        setLoading(false);
        setOperatorData("");
        setMEVOperatorData('');
      });
  };

  console.log(operatorData);
  const columns = useMemo(
    () => [
      {
        id: "hash",
        label: "HASH",
        minWidth: 170,
        style: {
          color: "#01BF97",
        },
        anchor: true,
        format: (hash) => {
          const has1 = hash?.slice(0, 6);
          const has2 = hash?.slice(hash.length - 6, hash.length);
          return has1 && has2 ? has1 + "..." + has2 : "NA";
        },
      },
      {
        id: "typeName",
        label: "METHOD",
        minWidth: 170,
        format: (hash, row) => {
          return (
            <div
              className={`${row?.decodeData[0]?.typeName ? "w-32" : "hidden"}`}
            >
              <p className="px-3 truncate py-1.5 rounded-lg bg-slate-100 text-gray-600 font-mono tracking-tighter font-semibold border border-gray-300 text-xs">
                {row?.decodeData[0]?.typeName
                  ? row?.decodeData[0]?.typeName
                  : ""}
              </p>
            </div>
          );
        },
      },
      {
        id: "blockNumber",
        label: "BLOCK",
        minWidth: 170,
        toolTip: false,
        style: {
          color: "#01BF97",
        },
        toolTip: false,
        anchor: true,
        format: (value) => {
          let val = value;
          return val;
        },
      },
      {
        id: "from",
        label: "FROM",
        minWidth: 170,
        style: {
          color: "#01BF97",
        },
        anchor: true,
        format: (hash) => {
          const has1 = hash?.slice(0, 6);
          const has2 = hash?.slice(hash.length - 6, hash.length);
          return has1 && has2 ? has1 + "..." + has2 : "NA";
        },
      },
      {
        id: "to",
        label: "TO",
        minWidth: 170,
        style: {
          color: "#01BF97",
        },
        anchor: true,

        format: (hash) => {
          const has1 = hash?.slice(0, 6);
          const has2 = hash?.slice(hash.length - 6, hash.length);
          return has1 && has2 ? has1 + "..." + has2 : "NA";
        },
      },
      {
        id: "value",
        label: "AMOUNT",
        toolTip: false,
        minWidth: 170,
        toolTip: false,
        format: (value) => {
          let val = parseInt(value, 16);
          return val + " " + "ETH";
        },
      },
      {
        id: "blockTimeStamp",
        label: "TIMESTAMP",
        minWidth: 170,
        toolTip: false,
        format: (value) => {
          let inputString = convertTimestampToRelativeTime(value);
          const parts = inputString.split("(");
          const resultString = parts[0].trim();
          return (
            <span className="py-1 text-gray-800 text-clip truncate text-xs">
              {resultString}
            </span>
          );
        },
      },
      {
        id: "gasUsed",
        label: "TXN FEE",
        minWidth: 170,
        align: "center",
        toolTip: false,
        format: (value, row) => {
          const txnFee = calculateTransactionFee(value, row.gasPrice, 11);
          return `${txnFee ? txnFee : ""} ETH`;
        },
      },
    ],
    [operator]
  );

  const column = useMemo(
    () => [
      {
        id: "publicKeys",
        label: "PUBLIC KEY",
        minWidth: 170,
        style: {
          color: "#01BF97",
        },
        anchor: true,
        format: (key, row) => {
          // const row1 = row?.slice(0, 6);
          // const row2 = row?.slice(row.length - 6, row.length)
          // return row && row2 ? row1 + "..." + row2 : "NA"
          return (
            <span
              onClick={() => navigate(`/explorer/${source}/validators/${row}`)}
            >
              {row}
            </span>
          );
        },
      },
      {
        minWidth: 170,
        style: {
          color: "#01BF97",
        },
        anchor: true,
        format: (_, row) => {
          return (
            <div className="flex justify-end space-x-3  text-[#7D8398] hover:text-[#04D1BE]/50 ">
              <span className="px-1 py-1 rounded-lg border h-6 border-gray-200 -mt-1 hover:border-[#04D1BE]/50">
                <CopyButton value={row} />
              </span>
              <Link
                to={`https://prater.beaconcha.in/validator/${row}`}
                target="_blank"
                className="cursor-pointer flex pr-10"
              >
                <span className="px-1 py-1 h-6 rounded-lg -mt-1 -mr-2 border border-gray-200 hover:border-[#04D1BE]/50">
                  <svg
                    className="ml-0.5 mt-0.5"
                    width="14"
                    height="11"
                    viewBox="0 0 16 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.888 7.35662C13.3025 1.42948 6.10773 -1.39402 0.181151 1.1927L0.98087 3.02553C5.91549 0.872393 11.902 3.22172 14.0551 8.15634L15.888 7.35662ZM12.2119 9.15284C11.3712 7.22447 9.7956 5.72633 7.77558 4.9336C5.75556 4.14087 3.58157 4.16753 1.65375 5.00947L2.45347 6.84229C3.89225 6.2145 5.52239 6.19679 7.04532 6.79444C8.56788 7.39302 9.75124 8.51378 10.379 9.95256L12.2119 9.15284ZM7.44538 11.2495C7.04187 12.2777 5.88121 12.7842 4.85298 12.3806C3.82476 11.9771 3.31833 10.8165 3.72184 9.78825C4.12535 8.76002 5.28601 8.25359 6.31423 8.65711C7.34246 9.06062 7.84889 10.2213 7.44538 11.2495Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </Link>
            </div>
          );
        },
      },
    ],
    [operator]
  );

  const getPerformance = (val) => {
    let percentage = val?.toString();
    percentage = percentage.slice(0, 5);
    return percentage;
  };

  return (
    <>
      {loading ? (
        <RotateLoading />
      ) : (
        <div className="space-y-10 mb-5">
          <div className="space-y-8">
            <div className="flex justify-between w-full items-center lg:flex-row flex-col lg:space-y-0 space-y-2">
              <div className="flex space-x-1">
                <h1 className="text-xl font-semibold text-[#0B2244] uppercase ml-2">
                  Operator /{" "}
                </h1>
                <span className="flex justify-center items-center text-center mt-1 text-gray-600">{` ${operatorId}`}</span>
                <span className="text-x px-2 py-2">
                  <CopyButton value={operatorId} />
                </span>
              </div>
              <div className="max-w-xl w-full">
                <SearchBar id={"Small"} />
              </div>
            </div>
            {operatorData ? (
              <div className="flex lg:space-x-10 lg:flex-row flex-col space-y-3 lg:space-y-0">
                <div className="w-full">
                  <div className="px-6 py-6 bg-white rounded-2xl shadow-2xl shadow-[#EFF0F6]">
                    <div className="flex justify-between">
                      <div className="flex items-center w-full">
                        <img height={90} width={70} className='bg-white bg-contain rounded-md' src={operatorData.logo ? operatorData.logo : LightSvg} />
                        <div className="flex-col pb-4 ml-3 w-full">
                          <div className="flex justify-between w-full">
                            <div className="flex justify-center items-center text-center mb-1">
                              <h1 className="text-[#0B2244 text-lg font-bold tracking-tight mr-1 ml-[0.6px]">
                                {operatorData.name}
                              </h1>
                              {
                                operatorData.type === 'verified_operator'
                                  ?
                                  <img src={VerifiedLogo} height={35} width={35} />
                                  : ''
                              }
                            </div>
                            <span className="bg-[#EEEEEE] px-1 py-1 h-6 rounded-xl cursor-pointer mt-[9px]">
                              <PiKeyFill color="#7D8398" />
                            </span>
                            {/* <div className="flex space-x-10 bg-[#EEEEEE] px-4 py-2 rounded-md justify-center text-center items-center">
                              <span className="text-xs font-medium text-gray-600">{`ID : ${operatorId}`}</span>
                              <span className="text-xs ml-2">
                                <CopyButton value={operatorId} />
                              </span>
                            </div> */}
                          </div>
                          <div className="flex space-x-2">
                            <a href={operatorData.website_url ? operatorData.website_url : ''}>
                              <img src={websiteLogo} height={20} width={20}></img>
                            </a>
                            <a href={operatorData.twitter_url ? operatorData.twitter_url : ''}>
                              <img src={TwitterLogo} height={20} width={20}></img>
                            </a>
                            <a href={operatorData.linkedin_url ? operatorData.linkedin_url : ''}>
                              <img src={LinkedinLogo} height={20} width={20}></img>
                            </a>
                          </div>
                        </div>
                        {/* </div> */}
                        {/* <span className="bg-[#EEEAEE] px-1 py-1 h-6 rounded-xl -mt-3 -mr-2 cursor-pointer">
                          <PiKeyFill color="#7D8398" />
                        </span> */}

                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <div className="px-6 py-6 bg-white rounded-2xl shadow-2xl shadow-[#EFF0F6]">
                    <div className="flex justify-between">
                      <div className="space-y-4 ">
                        <div className="flex space-x-1">
                          <h1 className="text-[#0B2244] text-lg font-bold tracking-tight">
                            Status
                          </h1>
                          <span className="text-sm mt-2">
                            <AiOutlineInfoCircle />
                          </span>
                        </div>
                        <div
                          className={`flex space-x-10 ${operatorData.status === "No validators"
                            ? "bg-[#B9B826]/40"
                            : operatorData.status === "Active"
                              ? "bg-green-100"
                              : "bg-red-100"
                            } px-4 py-2 rounded-md justify-center text-center items-center`}
                        >
                          <span
                            className={`text-xs font-semibold ${operatorData.status === "No validators"
                              ? "text-[#B9B826]"
                              : operatorData.status === "Active"
                                ? "text-green-500"
                                : "text-[red]/60"
                              }`}
                          >
                            {operatorData.status}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <div className="px-6 py-6 bg-white rounded-2xl shadow-2xl shadow-[#EFF0F6]">
                    <div className="flex justify-between">
                      <div className="space-y-4 ">
                        <div className="flex space-x-1">
                          <h1 className="text-[#0B2244] text-lg font-bold tracking-tight">
                            Performance
                          </h1>
                          <span className="text-sm mt-2">
                            <AiOutlineInfoCircle />
                          </span>
                        </div>
                        <div className="text-left ml-1">
                          <span className="text-2xl font-medium text-gray-800">
                            {operatorData?.performance
                              ? getPerformance(
                                swith === "1D"
                                  ? operatorData?.performance["24h"]
                                  : operatorData?.performance["30d"]
                              )
                              : ""}
                          </span>
                        </div>
                      </div>
                      <div className="space-x-2">
                        <button
                          onClick={() => setSwitch("1D")}
                          className={`text-xs font-medium ${swith === "1D" ? "text-[#01BF97]" : ""
                            }`}
                        >
                          1D
                        </button>
                        <button
                          onClick={() => setSwitch("1M")}
                          className={`text-xs font-medium ${swith === "1M" ? "text-[#01BF97]" : ""
                            }`}
                        >
                          1M
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div >
          <div className="space-y-8">
            {operatorData ? (
              <div className="flex lg:space-x-10 lg:flex-row flex-col space-y-3 lg:space-y-0">
                <div className="w-full h-auto">
                  <div className="px-6 py-6 bg-white rounded-2xl shadow-2xl shadow-[#EFF0F6]">
                    <div className="flex justify-between ">
                      <div className="space-y-4">
                        <h1 className="text-[#0B2244] text-lg font-bold tracking-tight">
                          Node Version
                        </h1>
                        <div className="flex space-x-10 px-0 py-2">
                          -
                        </div>
                      </div>
                      <div className="space-y-4 ">
                        <h1 className="text-[#0B2244] text-lg font-bold tracking-tight">
                          ETH2 node client
                        </h1>
                        <div className="flex space-x-10 px-0 py-2">
                          {operatorData.eth2_node_client ? operatorData.eth2_node_client : '-'}
                        </div>
                      </div>
                      <div className="space-y-4 ">
                        <h1 className="text-[#0B2244] text-lg font-bold tracking-tight">
                          ETH1 node client
                        </h1>
                        <div className="flex space-x-10 px-0 py-2">
                          {operatorData.eth1_node_client ? operatorData.eth1_node_client : '-'}
                        </div>
                      </div>
                      <div className="space-y-4 ">
                        <h1 className="text-[#0B2244] text-lg font-bold tracking-tight">
                          Cloud provider
                        </h1>
                        <div className="flex space-x-10 px-0 py-2">
                          -
                        </div>
                      </div>
                      <div className="space-y-4 ">
                        <h1 className="text-[#0B2244] text-lg font-bold tracking-tight">
                          Location
                        </h1>
                        <div className="flex space-x-10 px-0 py-2">
                          {operatorData.location ? operatorData.location : "-"}
                        </div>
                      </div>
                    </div>
                    {MEVOperatorData.length ?
                      <div className="space-y-4 w-fit">
                        <h1 className="text-[#0B2244] text-lg font-bold tracking-tight">
                          MEV relays supported
                        </h1>
                        <div className={`grid grid-flow-col gap-4 w-full`}>
                          {MEVOperatorData.length && (
                            MEVOperatorData.map((key, index) => (
                              <div key={index} className="flex space-x-2 bg-[#EEEEEE] px-2 py-2 rounded-md justify-center text-center items-center w-fit">
                                <img src={key.logo} alt={key.name} />
                                <h4>{key.name}</h4>
                              </div>
                            ))
                          )}
                        </div>
                      </div> : ''
                    }
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="space-y-3">
            <div className="flex space-x-4">
              <div
                onClick={() => setTab("Txns")}
                className={`${Tab === "Txns"
                  ? "bg-[#01BF97] text-white"
                  : "bg-[#E5E5E5] text-[#0B2244]"
                  } rounded-xl font-semibold px-4 py-1.5 w-36 cursor-pointer`}
              >
                <span className="w-full flex text-center justify-center text-lg items-center">
                  Transactions
                </span>
              </div>
              {operator?.operatorInfo?.publicKeys?.length ? (
                <div
                  onClick={() => setTab("Validators")}
                  className={`${Tab === "Validators"
                    ? "bg-[#01BF97] text-white"
                    : "bg-[#E5E5E5] text-[#0B2244]"
                    } rounded-xl font-semibold px-4 py-1.5 w-36 cursor-pointer`}
                >
                  <span className="w-full flex text-center justify-center text-lg items-center">
                    Validators
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>

            {Tab === "Txns" && (
              <StickyHeadTable
                columns={columns}
                tableId={"Operator"}
                rows={operator?.transaction?.transactions}
                isLoading={operator.isLoading}
                pagiNation={true}
                totalRecords={operator?.transaction?.totalRecords}
              />
            )}

            {Tab === "Validators" && (
              <StickyHeadTable
                columns={column}
                tableId={"PublicKey"}
                rows={operator?.operatorInfo?.publicKeys}
                isLoading={operator.isLoading}
                pagiNation={true}
                totalRecords={operator?.operatorInfo?.publicKeys?.length}
              />
            )}
          </div>
        </div >
      )
      }
    </>
  );
}

