import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";

import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import Gpt from "./gpt";
import logo from "../../../Assets/images/logo.svg";
import { Avatar, Button, Skeleton } from "@mui/material";
import { AiOutlinePlus } from "react-icons/ai";
import { RiMenu3Fill, RiMenu2Line, RiDeleteBin5Line } from "react-icons/ri";
import { PiChatCircleText } from "react-icons/pi";
import { FiEdit } from "react-icons/fi";
import { GoHome } from "react-icons/go";
import Chatdelete from "../../../Assets/images/chatdelete.svg";
import ChatEdit from "../../../Assets/images/ChatEdit.svg";
import "./style.css";
import Navbar from "./navbar";
import { useNavigate, useParams } from "react-router-dom";
import ApiServices from "../../../Services/ApiServices";
import { HiXMark } from "react-icons/hi2";
import { IoMdCheckmark } from "react-icons/io";
import { useDispatch } from "react-redux";
import { GetChatData } from "../../../Redux/Apis/ApiService";
const drawerWidth = 300;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
}));

export default function Sidebar({
  open,
  setOpen,
  setisValue,
  GenerateUID,
  ChatData,
  handleDelete,
  setPrevChatData,
  ChatDataLoader,
  setChatloading,
  prevChatData,
  ChatLoader,
  isValue,
  sideLoader,
  chatloading,
}) {
  const theme = useTheme();
  const { id } = useParams();
  const [selectindex, setSelectindex] = React.useState("");
  const [edit, Setedit] = React.useState(false);
  const dispatch = useDispatch();
  const [title, Settitle] = React.useState("");
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const Navigate = useNavigate();
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleSetValue = (newValue) => {
    setSelectindex(newValue);
    localStorage.setItem("Value", newValue);
  };

  const user = localStorage.getItem("Chatuser");
  const userI = JSON.parse(user);
  const userId = userI?.userId;
  console.log("userId1", userId);

  const handleEdit = (userId, id) => {
    ApiServices.post(`/AI/findbyIdandUpdate?userId=${userId}&sessionId=${id}`, {
      title: title,
    })
      .then(() => {
        dispatch(GetChatData({ userId: userId }));
        Setedit(false);
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    // While loading, load the state from the localStorage.
    if (localStorage.getItem("Value"))
      setSelectindex(localStorage.getItem("Value"));
  }, [ChatData]);
  console.log("selectindex", selectindex);
  return (
    <>
      {/* <CssBaseline /> */}
      <div className="shadow-sm fixed z-50 top-0 w-full " open={open}>
        <Toolbar sx={{ backgroundColor: "white", color: "black" }}>
          <IconButton
            color="black"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <RiMenu2Line />
          </IconButton>
          <Navbar />
        </Toolbar>
      </div>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            border: "none",
            boxShadow: "0px 0px 45px -20px grey",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <img
            src={logo}
            className="h-10 ml-2"
            alt="Coindelta Logo"
            onClick={() => Navigate("/")}
          />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? <RiMenu3Fill /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>

        <Divider />
        {/* <div className="flex items-center justify-center pt-4"></div> */}
        <div className="flex flex-col p-4 ml-2 ">
          <span className="font font-thin text-[#7D8398]">My new chat</span>
          <button
            disabled={chatloading ? true : false}
            className="w-48 bg-gray-200 hover:bg-gray-400 text-gray-800 border-0 py-2 px-4 mt-2 rounded-xl inline-flex items-center "
            onClick={() => GenerateUID()}
          >
            <AiOutlinePlus
              style={{
                backgroundColor: "white",
                borderRadius: "10px",
                padding: "2px",
                marginRight: "8px",
              }}
            />
            <span className="pl-2"> New Chat</span>
          </button>
        </div>
        {!ChatDataLoader || sideLoader ? (
          <List style={{ overflow: "auto", height: "-webkit-fill-available" }}>
            {ChatData &&
              ChatData?.length &&
              ChatData?.map((text, index) => (
                <ListItem key={index}>
                  {console.log("text", text)}

                  {edit ? (
                    <ListItem
                      sx={
                        text?.sessionId === id
                          ? {
                              p: 0,
                              borderRadius: 1,
                              px: 1,
                              backgroundColor: " rgba(0, 0, 0, 0.04)",
                            }
                          : { p: 0, borderRadius: 1, px: 1 }
                      }
                    >
                      <ListItem
                        onClick={() => {
                          if (text?.sessionId !== id) {
                            Navigate(`/chat/${text?.sessionId}`);
                            setisValue(true);
                            setSelectindex(id);
                            setPrevChatData("");
                            setChatloading(true);
                            localStorage.setItem("Value", id);
                            Setedit(false);
                          }
                        }}
                        sx={{ p: 0, borderRadius: 1, px: 1 }}
                      >
                        <PiChatCircleText />

                        <ListItemText sx={{ mx: 1 }}>
                          <p className="truncate w-40">
                            <input
                              defaultValue={text?.title}
                              onChange={(e) => {
                                Settitle(e.target.value);
                              }}
                            ></input>
                          </p>
                        </ListItemText>
                      </ListItem>
                      <ListItem>
                        {console.log("selected ", id, text?.sessionId)}
                        {text?.sessionId === id ? (
                          <div className="flex">
                            <IconButton
                              style={{
                                padding: "1px",
                                height: "16px",
                                width: "15px",
                              }}
                              onClick={() => {
                                handleEdit(text?.userId, text?.sessionId);
                              }}
                            >
                              <IoMdCheckmark />
                            </IconButton>
                            <IconButton
                              style={{
                                padding: "1px",
                                height: "16px",
                                width: "15px",
                              }}
                              onClick={(e) => {
                                Setedit(false);
                              }}
                            >
                              <HiXMark />
                            </IconButton>
                          </div>
                        ) : (
                          <></>
                        )}
                      </ListItem>
                    </ListItem>
                  ) : (
                    <ListItem
                      sx={
                        text?.sessionId === id
                          ? {
                              p: 0,
                              borderRadius: 1,
                              px: 1,
                              backgroundColor: " rgba(0, 0, 0, 0.04)",
                            }
                          : { p: 0, borderRadius: 1, px: 1 }
                      }
                    >
                      <ListItem
                        onClick={() => {
                          if (text?.sessionId !== id) {
                            Navigate(`/chat/${text?.sessionId}`);
                            setisValue(true);
                            setSelectindex(id);
                            setPrevChatData("");
                            setChatloading(true);
                            localStorage.setItem("Value", id);
                          }
                        }}
                        sx={{ p: 0, borderRadius: 1, px: 1 }}
                      >
                        <PiChatCircleText />

                        <ListItemText sx={{ mx: 1 }}>
                          <p className="truncate w-40">
                            {text?.title ? text?.title : "Default Chat"}
                          </p>
                        </ListItemText>
                      </ListItem>
                      {text?.sessionId === id ? (
                        <div className="flex">
                          <IconButton
                            style={{
                              padding: "1px",
                              height: "16px",
                              width: "15px",
                            }}
                            onClick={(e) => {
                              Setedit(true);
                            }}
                          >
                            <Avatar
                              src={ChatEdit}
                              style={{
                                padding: "2px",
                                height: "16px",
                                width: "16px",
                                marginRight: "6px",
                              }}
                            />
                          </IconButton>
                          <IconButton
                            style={{
                              padding: "1px",
                              height: "16px",
                              width: "15px",
                            }}
                            onClick={(e) => {
                              handleDelete(text?.userId, text?.sessionId);
                            }}
                          >
                            <Avatar
                              src={Chatdelete}
                              style={{
                                padding: "1px",
                                height: "16px",
                                width: "15px",
                              }}
                            />{" "}
                          </IconButton>
                          {/* <RiDeleteBin5Line /> */}
                        </div>
                      ) : (
                        <></>
                      )}
                    </ListItem>
                  )}
                </ListItem>
              ))}
          </List>
        ) : (
          <List
            style={{
              overflow: "auto",
              height: "-webkit-fill-available",
              padding: "10px",
            }}
          >
            <Skeleton animation="wave" height={50} />
            <Skeleton animation="wave" height={50} />
            <Skeleton animation="wave" height={50} />
          </List>
        )}
        <Divider />
        {/* <div className="flex items-center justify-center pt-4"></div> */}
        <div className="flex flex-col p-4 ml-2 justify-end ">
          <button
            className="w-36 bg-gray-200 hover:bg-gray-400 text-gray-800 border-0 py-2 px-2 rounded-xl inline-flex items-center "
            onClick={() => Navigate("/")}
          >
            <GoHome />
            <span className="pl-2"> Go to Home</span>
          </button>
        </div>
      </Drawer>
      {/* <DrawerHeader /> */}
    </>
  );
}

