
const PreloadAssets = async () => {

    await Promise.all([
        import('../Assets/images/loading.svg'),
        import('../Assets/images/success.svg'),
        import('../Assets/images/logo2.svg'),
        import('../Assets/images/done.gif'),
        import('../Assets/images/logo.svg'),
    ]);
    console.log('Assets preloaded');

};
export default PreloadAssets;