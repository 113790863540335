import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import ApiServices from "../Services/ApiServices";
import { getSearchData } from "../Apis/EndPoints";
import AuthService from "../Services/AuthService";
import { Box, Skeleton } from "@mui/material";
import { BsSearch } from "react-icons/bs";

export default function SearchBar({ id }) {
  const [isSearch, setIsSearch] = useState([]);
  const [focused, setFocused] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const Navigate = useNavigate();
  const { source } = useParams();

  useEffect(() => {
    if (searchVal) {
      GetSearchData(searchVal);
    }
  }, [searchVal]);

  const GetSearchData = (val) => {
    const authService = AuthService.get();
    const datasourceId = authService.getSource();
    let value = searchVal;
    if (val.length === 7 || val.length === 8) {
      value = Number(searchVal).toString(16);
      value = "0x" + value.padStart(6, "0");
    }
    setLoading(true);
    ApiServices.get(
      `${getSearchData}?parameter=${value ? value : val}&datasourceId=${
        datasourceId?._id
      }`
    )
      .then((res) => {
        setLoading(false);
        setIsSearch([res?.data]);
      })
      .catch((err) => {
        setLoading(false);
        setIsSearch([]);
      });
  };

  const NavigateHandler = (e, id, value) => {
    if (id === "hash" || id === "transactionHash") {
      Navigate(`/explorer/${source}/txn/${value}`, {
        state: {
          parameter: id,
          value,
        },
      });
    } else if (id === "blockNumber") {
      Navigate(`/explorer/${source}/blockNumber/${parseInt(value, 16)}`, {
        state: {
          parameter: id,
          value,
        },
      });
    } else if (
      id === "from" ||
      id === "to" ||
      id === "blockHash" ||
      id === "ownerAddress" ||
      id === "address"
    ) {
      Navigate(`/explorer/${source}/address/${value}`, {
        state: {
          parameter: id,
          value,
        },
      });
    } else if (id === "operatorId" || id === "id" || id === "operatorIds") {
      Navigate(`/explorer/${source}/operators/${value}`, {
        state: {
          parameter: id,
          value,
        },
      });
    } else if (id === "publicKey") {
      Navigate(`/explorer/${source}/validators/${value}`, {
        state: {
          parameter: id,
          value,
        },
      });
    }
  };

  return (
    <div className="w-full">
      <div className="flex justify-end sm:px-1 lg:px-2 relative w-full">
        {id === "Small" ? (
          <>
            <input
              value={searchVal}
              onChange={(e) => setSearchVal(e.target.value)}
              onFocus={() => setFocused(true)}
              onMouseEnter={() => setFocused(true)}
              onMouseLeave={() => setFocused(false)}
              type="text"
              className="border w-full border-gray-200 lg:text-base lg:pr-8 pr-2 pl-3 py-2 text-gray-800 rounded-lg focus:outline-none focus:border-[#04C2AE] focus:ring-1 focus:ring-[#04C2AE] placeholder-gray-400 lg:placeholder:text-sm"
              placeholder={`Search by Address / Txn Hash / Block`}
            />
            <span
              className={`absolute lg:text-xl text-xl inset-y-0 lg:right-1 right-1 flex items-center cursor-pointer pr-6 ${
                searchVal || focused ? "text-[#04C2AE]" : "text-gray-400"
              } `}
            >
              <BsSearch />
            </span>
          </>
        ) : (
          <>
            <input
              value={searchVal}
              onChange={(e) => setSearchVal(e.target.value)}
              onFocus={() => setFocused(true)}
              onMouseEnter={() => setFocused(true)}
              onMouseLeave={() => setFocused(false)}
              type="text"
              className="border border-gray-300 lg:text-lg lg:pr-8 pr-2 text-gray-800 rounded-lg lg:p-3 p-2 focus:outline-none focus:border-[#04C2AE] focus:ring-1 focus:ring-[#04C2AE] w-full placeholder-gray-400 lg:placeholder:text-md"
              placeholder={`Search by Address / Txn Hash / Block`}
            />
            <span className="absolute lg:text-3xl text-xl inset-y-0 lg:right-6 right-1 flex items-center cursor-pointer pr-3 text-[#04C2AE]">
              <BsSearch />
            </span>
          </>
        )}
        {focused && searchVal ? (
          <div
            onMouseEnter={() => setFocused(true)}
            onMouseLeave={() => setFocused(false)}
            className={`absolute ${
              id === "Small" ? "lg:mt-11 mt-9" : "lg:mt-14 mt-11"
            } lg:pl-4 w-full  rounded-2xl z-50`}
          >
            <div className="px-2 py-2 bg-white rounded drop-shadow-xl">
              <div className="px-2 py-2">
                <div className="">
                  <h1 className="font-semibold text-gray-500 pb-2">
                    {isSearch?.length ? "Search Results :-" : ""}{" "}
                  </h1>
                  <hr />
                </div>
                <div className="flex flex-col divide-y">
                  {loading ? (
                    <div className="w-full">
                      <Box sx={{ width: "100%" }}>
                        <Skeleton animation="wave" height={50} />
                      </Box>
                    </div>
                  ) : (
                    <div className="space-y-2">
                      {(isSearch?.length &&
                        isSearch.map((item, i) => (
                          <div
                            onClick={(e) =>
                              NavigateHandler(e, item?.searchType, item?.value)
                            }
                            className="px-2 py-3 truncate cursor-pointer"
                            key={i}
                          >
                            <span className="font-semibold text-gray-500 capitalize">
                              {item?.searchType === "from" ||
                              item?.searchType === "to" ||
                              item?.searchType === "address"
                                ? "Address"
                                : item?.searchType === "operatorIds"
                                ? "operatorId"
                                : item?.searchType}{" "}
                              :{" "}
                            </span>
                            <span className="text-gray-400">
                              {item?.searchType === "blockNumber"
                                ? item?.value
                                : item?.value}
                            </span>
                          </div>
                        ))) || (
                        <div className="px-2 py-3 truncate flex justify-center items-center text-center">
                          <span className="text-base font-semibold text-gray-500">
                            No result found !!
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                  <hr />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
