import ApiServices from "../../Services/ApiServices";
import { createAsyncThunk } from "@reduxjs/toolkit";
import AuthService from "../../Services/AuthService";
import {
  getBlockData,
  getDataSource,
  getInputData,
  getLogsData,
  getOperators,
  getOperatorsByAddress,
  getQueryInputData,
  getUserByid,
  getValidatorByAddress,
  getValidators,
  getTextToMql,
  getAlldata,
  getDatabyID,
  getDuties,
} from "../../Apis/EndPoints";

const GetCurrentUser = createAsyncThunk("currentUser", async () => {
  const id = JSON.parse(localStorage.getItem("id"));
  const response = await ApiServices.get(`${getUserByid}?id=${id}`);
  localStorage.setItem("user", JSON.stringify(response.data.data));
  return response.data.data;
});

const GetInputData = createAsyncThunk("getInputData", async (query) => {
  const authService = AuthService.get();
  const datasourceId = authService.getSource();
  const response = await ApiServices.get(
    `${getInputData}${query
      ? `?page=${query.page}&limit=${query.limit}&datasourceId=${datasourceId._id}`
      : `?page=1&limit=10&datasourceId=${datasourceId._id}`
    }`
  );
  return response.data;
});

const GetBlockData = createAsyncThunk("getBlockData", async (query) => {
  const authService = AuthService.get();
  const datasourceId = authService.getSource();
  const response = await ApiServices.get(
    `${getBlockData}${query
      ? `?page=${query.page}&limit=${query.limit}&datasourceId=${datasourceId._id}`
      : `?page=1&limit=10&datasourceId=${datasourceId._id}`
    }`
  );
  return response.data;
});

const GetQueryInputData = createAsyncThunk(
  "getQueryInputData",
  async (query) => {
    const authService = AuthService.get();
    const response = await ApiServices.get(
      `${getQueryInputData}?parameter=${query.parameter}&value=${query.value
      }&page=${query?.page ? query?.page : 1}&limit=${query?.limit ? query?.limit : 5
      }`
    );
    return response.data;
  }
);

const GetLogsData = createAsyncThunk("logs", async (body) => {
  const authService = AuthService.get();
  const datasourceId = authService.getSource();
  const response = await ApiServices.post(
    `${getLogsData}?datasourceId=${datasourceId._id}`,
    body
  );
  return response.data;
});

const GetOperators = createAsyncThunk("Operators", async (query) => {
  const authService = AuthService.get();
  const datasourceId = authService.getSource();
  const response = await ApiServices.get(
    `${getOperators}?page=${query?.page ? query?.page : "1"}&limit=${query?.limit ? query?.limit : "5"
    }${query?.id ? `&id=${query?.id}&datasourceId=${datasourceId._id}` : ""
    }&datasourceId=${datasourceId._id}`
  );
  return response.data;
});

const GetValidators = createAsyncThunk("Validators", async (query) => {
  const authService = AuthService.get();
  const datasourceId = authService.getSource();
  const response = await ApiServices.get(
    `${getValidators}?page=${query?.page ? query?.page : "1"}&limit=${query?.limit ? query?.limit : "5"
    }${query?.key ? `&key=${query?.key}&datasourceId=${datasourceId._id}` : ""
    }&datasourceId=${datasourceId._id}`
  );
  return response.data;
});

const GetValidatorsByOwnerAddress = createAsyncThunk(
  "ValidatorsByAddress",
  async (query) => {
    const authService = AuthService.get();
    const datasourceId = authService.getSource();
    const response = await ApiServices.get(
      `${getValidatorByAddress}?parameter=ownerAddress&value=${query.value ? query.value : ""
      }&limit=${query?.limit ? query?.limit : "5"}&${query?.page
        ? `&page=${query?.page}&datasourceId=${datasourceId._id}`
        : "&page=1"
      }&datasourceId=${datasourceId._id}`
    );
    return response.data;
  }
);

const GetOperatorsByOwnerAddress = createAsyncThunk(
  "OperatorsByAddress",
  async (query) => {
    const authService = AuthService.get();
    const datasourceId = authService.getSource();
    const response = await ApiServices.get(
      `${getOperatorsByAddress}?parameter=ownerAddress&value=${query.value ? query.value : ""
      }&limit=${query?.limit ? query?.limit : "5"}${query?.page
        ? `&page=${query?.page}&datasourceId=${datasourceId._id}`
        : "&page=1"
      }&datasourceId=${datasourceId._id}`
    );
    return response.data;
  }
);

const GetDataSources = createAsyncThunk("dataSources", async (query) => {
  const response = await ApiServices.get(`${getDataSource}`);
  return response.data.data;
});

const GetTextToMql = createAsyncThunk("dataSources", async (query) => {
  const response = await ApiServices.get(`${getTextToMql}`);
  return response.data.data;
});

const GetChatData = createAsyncThunk("AiData", async (query) => {
  const response = await ApiServices.post(
    `${getAlldata}?userId=${query.userId}`
  );
  return response.data.data;
});

const GetChatDataById = createAsyncThunk("dataSources", async (query) => {
  const response = await ApiServices.get(`${getDatabyID}`);
  return response.data.data;
});


const GetDuties = createAsyncThunk("duties", async (query) => {
  const authService = AuthService.get();
  const datasourceId = authService.getSource();
  const path = getDuties(datasourceId.dataSource === "SSV-Testnet" ? "testnet" : "mainnet")
  const response = await ApiServices.get(
    `${path}${query
      ? `?parameter=${query.parameter}&value=${query.key}&page=${query.page ? query.page : 1}&limit=${query.limit ? query.limit : 10}`
      : `?page=1&limit=10`
    }`
  );
  return response.data;
});

export {
  GetCurrentUser,
  GetInputData,
  GetDataSources,
  GetQueryInputData,
  GetBlockData,
  GetLogsData,
  GetOperators,
  GetValidators,
  GetOperatorsByOwnerAddress,
  GetValidatorsByOwnerAddress,
  GetTextToMql,
  GetChatData,
  GetChatDataById,
  GetDuties,
};
