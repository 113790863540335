import { Link, useNavigate, useParams } from 'react-router-dom';
import CopyButton from '../../Helpers/CopyButton';
import SearchBar from '../../Helpers/SearchBar';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';
import { Table } from '.';

export default function ValidatorsTable() {
    const navigate = useNavigate()
    const { source } = useParams()
    const validators = useSelector((state) => state.validator);

    useEffect(() => {
        document.title = "Block Sail | Validators"
    }, [])

    const columns = useMemo(() => [
        {
            id: 'publicKey',
            label: 'PUBLIC KEY',
            minWidth: 170,
            style: {
                color: "#01BF97"
            },
            anchor: true,
            format: (hash) => {
                const has1 = hash?.slice(0, 6);
                const has2 = hash?.slice(hash.length - 6, hash.length)
                return has1 && has2 ? has1 + "..." + has2 : "NA"
            },
        },
        {
            id: 'operatorIds',
            label: 'OPERATORS',
            minWidth: 170,
            align: "center",
            toolTip: false,
            format: (value) => {
                return (
                    <div className='flex space-x-2 justify-center items-center text-center w-full'>
                        {
                            value?.map((item, i) => (
                                <Tooltip title={`Click to see operator details`} placement='top' arrow>
                                    <span
                                        onClick={() => navigate(`/explorer/${source}/operators/${item}`)}
                                        className='px-2 py-1 text-xs cursor-pointer rounded-lg bg-slate-100 border shadow-sm' key={i}>{item}</span>
                                </Tooltip>
                            ))
                        }
                    </div>
                )
            },
        },
        {
            minWidth: 170,
            style: {
                color: "#01BF97"
            },
            align: "right",
            toolTip: false,
            format: (_, row) => {
                return (
                    <div className='flex justify-end space-x-3'>
                        <span className='px-1 py-1 rounded-lg border h-6 border-gray-200 -mt-1 hover:border-[#04D1BE]/50'>
                            <CopyButton value={row.publicKey} />
                        </span>
                        <Link to={`https://prater.beaconcha.in/validator/${row.publicKey}`} target='_blank' className='cursor-pointer flex pr-10'>
                            <span className='px-1 py-1 h-6 rounded-lg -mt-1 -mr-2 border border-gray-200 hover:border-[#04D1BE]/50 '>
                                <svg
                                    className='ml-0.5 mt-0.5 text-[#7D8398] hover:text-[#04D1BE]/50'
                                    width="14" height="11" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M15.888 7.35662C13.3025 1.42948 6.10773 -1.39402 0.181151 1.1927L0.98087 3.02553C5.91549 0.872393 11.902 3.22172 14.0551 8.15634L15.888 7.35662ZM12.2119 9.15284C11.3712 7.22447 9.7956 5.72633 7.77558 4.9336C5.75556 4.14087 3.58157 4.16753 1.65375 5.00947L2.45347 6.84229C3.89225 6.2145 5.52239 6.19679 7.04532 6.79444C8.56788 7.39302 9.75124 8.51378 10.379 9.95256L12.2119 9.15284ZM7.44538 11.2495C7.04187 12.2777 5.88121 12.7842 4.85298 12.3806C3.82476 11.9771 3.31833 10.8165 3.72184 9.78825C4.12535 8.76002 5.28601 8.25359 6.31423 8.65711C7.34246 9.06062 7.84889 10.2213 7.44538 11.2495Z" fill="currentColor" />
                                </svg>
                            </span>
                        </Link>
                    </div>
                )
            },
        },
    ], [validators]);

    return (
        <div className="w-full space-y-6 mb-4">
            <div className='px-3 py-1 flex justify-between w-full items-center lg:flex-row flex-col lg:space-y-0 space-y-2'>
                <span className='text-2xl font-bold text-gray-600 uppercase'>
                    Validators
                </span>
                <div className='max-w-xl w-full'>
                    <SearchBar id={"Small"} />
                </div>
            </div>
            <div className='flex lg:justify-between lg:flex-row flex-col lg:space-y-0 space-y-4 lg:space-x-6'>
                <div className='w-full'>
                    <Table
                        columns={columns}
                        rows={validators?.data?.length ? validators.data : []}
                        isLoading={validators?.isLoading}
                        tableId={"Validator"}
                        pagiNation={true}
                        totalRecords={validators?.totalRecords}
                    />
                </div>
            </div>
        </div>
    )
}
