import ValidatorByAddressReducer from "../Slices/ValidatorByAddressSlice";
import OperatorByAddressReducer from "../Slices/operatorByAddressSlice";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import QueryInputDataReducer from "../Slices/queryInputDataSlice";
import DataSourcesReducer from "../Slices/dataSourcesSlice";
import InputDataReducer from "../Slices/inputDataSlice";
import BlockDataReducer from "../Slices/blockDataSlice";
import validatorReducer from "../Slices/validatorSlice";
import operatorReducer from "../Slices/operatorSlice";
import UserProfileReducer from "../Slices/userSlice";
import LogsReducer from "../Slices/logsSlice";
import AiDataReducer from "../Slices/AiChat";
import DutiesReducer from "../Slices/getDutiesSlice";

const combinedReducer = combineReducers({
  logs: LogsReducer,
  user: UserProfileReducer,
  operator: operatorReducer,
  sources: DataSourcesReducer,
  validator: validatorReducer,
  inputData: InputDataReducer,
  blockData: BlockDataReducer,
  queryInputData: QueryInputDataReducer,
  operatorByAddress: OperatorByAddressReducer,
  validatorByAddress: ValidatorByAddressReducer,
  AiData: AiDataReducer,
  duties: DutiesReducer
});
const RESET_STORE = "RESET_STORE";

const resetStoreMiddleware = (store) => (next) => (action) => {
  if (action.type === RESET_STORE) {
    return next({ type: "@@redux/INIT" }); // Reset the store to initial state
  }
  return next(action);
};

const rootReducer = (state, action) => {
  if (action.type === "user/logout") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(resetStoreMiddleware),
});

export const clearStoreAction = () => ({ type: RESET_STORE });
