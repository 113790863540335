import { GetDataSources } from './Redux/Apis/ApiService';
import { useDispatch, useSelector } from 'react-redux';
import { RotateLoading } from './Assets/Icons/Icons';
import PreloadAssets from './Utils/PreLoadAssets';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Routers from "./Routers";

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const newSourceValue = useSelector(state => {
    if (state?.sources?.data?.length) {
      return state?.sources?.data[0]?.dataSource
    }
  });

  const fetchData = async () => {
    try {
      await dispatch(GetDataSources());
      PreloadAssets()
      await navigate(`/explorer/${newSourceValue ? newSourceValue : ""}`);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("source")) {
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, [dispatch, newSourceValue]);


  return (
    <>
      {
        isLoading
          ?
          <RotateLoading />
          :
          <>
            <ToastContainer position="bottom-right" />
            <Routers />
          </>}
    </>
  );
}

export default App;
