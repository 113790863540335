import React, { useEffect, useState } from "react";
import AppBar from "../../Layout.jsx/AppBar";
import Sidebar from "./sidebar";
import { Box } from "@mui/material";
import Gpt from "./gpt";
import { v4 as uuidv4, v5 as uuidv5 } from "uuid";

import { useDispatch, useSelector } from "react-redux";
import { GetChatData } from "../../../Redux/Apis/ApiService";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import ApiServices from "../../../Services/ApiServices";
import { isAccessTokenExpired } from "../../../Middleware/useAuth";
export default function Index() {
  const [open, setOpen] = useState(true);
  const [isValue, setisValue] = useState(true);
  const [prevChatData, setPrevChatData] = useState();
  const { state } = useLocation();
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const v4Id = uuidv4();
  const [uuid, Setuuid] = useState(v4Id);
  const [istokenexp, setIstokenexp] = useState();
  const [chatloading, setChatloading] = useState(false);
  const [ChatLoader, setChatLoader] = useState(false);
  const [sideLoader, setSideLoader] = useState(false);
  const navigate = useNavigate();
  const GenerateUID = () => {
    Setuuid(v4Id);
    setisValue(false);
    Navigate(`/chat/${v4Id}`);
    setPrevChatData("");
  };

  const ChatDataLoader = useSelector((state) => state?.AiData?.isLoading);
  const ChatData = useSelector((state) => state?.AiData?.data);
  console.log("ChatData", ChatDataLoader);
  let data = ChatData && ChatData.length ? [...ChatData] : [""];

  const user = localStorage.getItem("Chatuser");
  const userI = JSON.parse(user);
  const userId = userI?.userId;
  console.log("userId1", userId);
  useEffect(() => {
    dispatch(GetChatData({ userId: userId }));
  }, [dispatch]);

  useEffect(() => {
    const accessToken = localStorage.getItem("ChataccessToken");
    if (!accessToken || accessToken === null) {
      navigate("/chat/login");
    }
    const isVerifiedtoken = isAccessTokenExpired(accessToken);
    console.log("accessTokenaccessToken", accessToken);
    setIstokenexp(isVerifiedtoken);
    console.log("private route calling .....................", isVerifiedtoken);
  }, [navigate]);

  useEffect(() => {
    console.log("private route calling1 .....................");
    if (istokenexp === true) {
      localStorage.removeItem("ChatrefreshTokenExp");
      localStorage.removeItem("ChataccessTokenExp");
      localStorage.removeItem("ChatrefreshToken");
      localStorage.removeItem("ChataccessToken");
      localStorage.removeItem("Chatuser");
      localStorage.removeItem("Chatid");
      navigate("/chat/login");
    }
    if (ChatData?.length !== 0) {
      setSideLoader(true);
    }
  });
  console.log("sideLoader", sideLoader, data, ChatData, ChatDataLoader);
  const handleDelete = (userId, id) => {
    console.log("handleDelete", userId, id);

    ApiServices.post(`/AI/deletebyId?userId=${userId}&sessionId=${id}`)
      .then(() => {
        setPrevChatData("");
        dispatch(GetChatData({ userId: userId }));
        setisValue(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Box sx={{}}>
      <Sidebar
        open={open}
        setOpen={setOpen}
        setisValue={setisValue}
        GenerateUID={GenerateUID}
        ChatData={data?.reverse()}
        setPrevChatData={setPrevChatData}
        handleDelete={handleDelete}
        ChatDataLoader={ChatDataLoader}
        setChatloading={setChatloading}
        prevChatData={prevChatData}
        ChatLoader={ChatLoader}
        isValue={isValue}
        sideLoader={sideLoader}
        chatloading={chatloading}
      />
      <Gpt
        open={open}
        setisValue={setisValue}
        GenerateUID={GenerateUID}
        isValue={isValue}
        uuid={uuid}
        v4Id={v4Id}
        Setuuid={Setuuid}
        prevChatData={prevChatData}
        setPrevChatData={setPrevChatData}
        ChatDataLoader={ChatDataLoader}
        setChatloading={setChatloading}
        chatloading={chatloading}
        ChatLoader={ChatLoader}
        setChatLoader={setChatLoader}
      />
    </Box>
  );
}
