import { MdOutlineLock, MdOutlineLockOpen } from "react-icons/md";
import {
  changePassword,
  login,
  ChatSignup,
  ChatVerifyEmail,
  ChatSignin,
} from "../../../Apis/EndPoints";
import { setUserInfo } from "../../../Redux/Slices/userSlice";
import { ButtonLoader } from "../../../Assets/Icons/Icons";
import { ChatuserLogin } from "../../../Middleware/useAuth";
import ApiServices from "../../../Services/ApiServices";
import logo from "../../../Assets/images/logo.svg";
import { useNavigate } from "react-router-dom";
import { HiOutlineMail, HiOutlineUser } from "react-icons/hi";
import { useEffect, useState } from "react";
import useRegex from "../../../Utils/useRegex";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import AuthService from "../../../Services/AuthService";

const ChatRegister = () => {
  const [changeDisabledBtn, setChangeDisabledBtn] = useState(false);
  const [isValidPassword, setisValidPassword] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(true);
  const [changeLoading, setChangeLoading] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [confirmNewPass, setConfirmNewPass] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const [hasUppercase, sethasUppercase] = useState(false);
  const [hasLowercase, sethasLowercase] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [showNewPass, setShowNewPass] = useState(true);
  const [errorMessage, setErrorMessage] = useState({});
  const [isVerifiedemail, setIsVerifiedemail] = useState(true);
  const [hasNumber, sethasNumber] = useState(false);
  const [hasSymbol, sethasSymbol] = useState(false);
  const [showPass, setShowPass] = useState(true);
  const [password, setPassword] = useState("");
  const [email, setMail] = useState("");
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [isRegister, setisRegister] = useState(false);
  const navigate = useNavigate();
  let dispatch = useDispatch();

  const handleEmail = (e) => {
    let mail = e.target.value;
    let isValid = useRegex.useEmailRegex(mail);
    if (isValid) {
      setMail(mail);
    } else {
      setMail("");
    }
  };
  const handleCode = (e) => {
    let code = e.target.value;
    setCode(code);
  };
  const handleName = (e) => {
    let name = e.target.value;
    setName(name);
  };
  const handlePassword = (e) => {
    let password = e.target.value;
    setPassword(password);
  };

  const handleChangePassword = (e) => {
    let { value, id } = e.target;
    if (id === "changePass") {
      setPassword(value);
    }
    if (id === "confirmPass") {
      setConfirmNewPass(value);
    }
  };
  const handleFocus = () => {
    setIsTooltipOpen(true);
  };

  const handleBlur = () => {
    setIsTooltipOpen(false);
  };

  useEffect(() => {
    if (!email || !password || !confirmNewPass || !name) {
      setDisabledBtn(true);
    } else {
      setDisabledBtn(false);
    }
  }, [email, password, confirmNewPass, name]);

  useEffect(() => {
    CheckPasswordValid();
  }, [password]);

  useEffect(() => {
    if (!password || !confirmNewPass) {
      setChangeDisabledBtn(true);
    } else if (password !== confirmNewPass) {
      setChangeDisabledBtn(true);
    } else if (!isValidPassword) {
      setChangeDisabledBtn(true);
    } else {
      setChangeDisabledBtn(false);
      setErrorMessage({});
    }
  }, [password, confirmNewPass]);

  useEffect(() => {
    isValid();
  }, [password, confirmNewPass]);

  useEffect(() => {
    const accessToken = localStorage.getItem("ChataccessToken");
    if (accessToken) {
      navigate("/chat");
    }
  }, [changeLoading]);

  const SubmitRegistration = async (e) => {
    e.preventDefault();

    try {
      const body = {
        name: name,
        email: email,
        password: password,
      };
      setLoginLoading(true);
      await ApiServices.post(ChatSignup, body)
        .then(() => {
          setIsVerifiedemail(false);
        })
        .catch((err) => {
          setLoginLoading(false);
          console.log(err);
          toast.error("User already exist");
        });
      //   await ChatuserLogin(ChatSignin, body);
      // dispatch(setUserInfo(isUserValidated.user));
    } catch (error) {
      setLoginLoading(false);
      console.log("error", error);
      toast.error("Something went wrong");
    }
  };

  const submitVerifyEmail = async (e) => {
    e.preventDefault();

    const body = {
      email: email,
      code: code,
    };
    try {
      setChangeLoading(true);

      await ApiServices.post(ChatVerifyEmail, body)
        .then(() => {
          const body = {
            email: email,
            password: password,
          };
          toast.success("Sucessfully Registered");
          ApiServices.post(ChatSignin, body).then((res) => {
            const token = res?.data?.token;
            const authUser = res?.data?.userData;
            localStorage.setItem("ChatrefreshTokenExp", token.refreshTokenExp);
            localStorage.setItem("ChataccessTokenExp", token.accessTokenExp);
            localStorage.setItem("ChatrefreshToken", token.refreshToken);
            localStorage.setItem("Chatid", JSON.stringify(authUser._id));
            localStorage.setItem("ChataccessToken", token.accessToken);
            localStorage.setItem("Chatuser", JSON.stringify(authUser));
            toast.success("Sucessfully login");
            setChangeLoading(false);
            navigate("/chat");
            const result = {
              ...res?.data,
              status: true,
              message: "Login Successfully!",
            };
            return result;
          });
        })
        .catch((err) => {
          setChangeLoading(false);
          console.log(err);
          toast.error("Invalid OTP");
        });
    } catch (error) {
      setLoginLoading(false);
      console.log("error", error);
      toast.error("Something went wrong");
    }

    //   await ChatuserLogin(ChatSignin, body);
    // dispatch(setUserInfo(isUserValidated.user));
  };
  const isValid = () => {
    let isError = false;
    let error = {};
    if (password !== confirmNewPass) {
      error.newpass = "Password doesn't match";
      isError = true;
    } else {
      error.newpass = "";
      isError = true;
    }
    setErrorMessage(error);
    return isError;
  };

  const CheckPasswordValid = () => {
    const hasUpper = /[A-Z]/.test(password);
    if (hasUpper) {
      sethasUppercase(hasUpper);
    } else {
      sethasUppercase(false);
    }
    const hasLower = /[a-z]/.test(password);
    if (hasLower) {
      sethasLowercase(hasLower);
    } else {
      sethasLowercase(false);
    }
    const hasNum = /\d/.test(password);
    if (hasNum) {
      sethasNumber(hasUpper);
    } else {
      sethasNumber(false);
    }
    const hasSmbol = /[-!$%^&@*()_+|~=`{}\[\]:";'<>?,.\/]/.test(password);
    if (hasSmbol) {
      sethasSymbol(hasSmbol);
    } else {
      sethasSymbol(false);
    }
    const isValidPass =
      hasUppercase &&
      hasLowercase &&
      hasNumber &&
      hasSymbol &&
      password.length >= 8;
    if (isValidPass) {
      setisValidPassword(isValidPass);
    } else {
      setisValidPassword(false);
    }
  };

  return (
    <>
      <div
        className="loginBG flex flex-col items-center justify-center"
        style={{ minHeight: "100VH", maxHeight: "100%", padding: "20px" }}
      >
        <div className="w-full bg-white rounded-xl shadow border md:mt-0 sm:max-w-lg xl:p-0 border-[white] p-8 ">
          <div className="p- space-y-6 md:space-y-6 sm:p-12">
            <img className="w-40" src={logo} alt="logo" />

            <>
              {isVerifiedemail ? (
                <>
                  <h1 className="text-xl font-bold leading-tight text-gray-700 md:text-2xl pb-2 lg:text-3xl">
                    Sign Up
                  </h1>
                  <span>Welcome! Please enter your details.</span>
                  <form
                    onSubmit={SubmitRegistration}
                    className="space-y-4 md:space-y-6"
                  >
                    {" "}
                    <div className="relative">
                      <label className="block mb-2 text-lg font-medium ">
                        Full Name
                      </label>
                      <input
                        onChange={(e) => handleName(e)}
                        name="name"
                        className="reletive border text-gray-900 lg:text-lg text-sm rounded-lg block w-full p-2.5 placeholder-gray-400"
                        placeholder="Enter Your Full Name"
                        required=""
                      />
                      <span className="absolute inset-y-0 right-4 mt-9 flex items-center pl-6">
                        <HiOutlineUser color="#04D1BE" className="h-6 w-6" />
                      </span>
                    </div>
                    <div className="relative">
                      <label className="block mb-2 text-lg font-medium ">
                        Email
                      </label>
                      <input
                        onChange={(e) => handleEmail(e)}
                        name="email"
                        className="reletive border text-gray-900 lg:text-lg text-sm rounded-lg block w-full p-2.5 placeholder-gray-400"
                        placeholder="Enter Your Email"
                        required=""
                      />
                      <span className="absolute inset-y-0 right-4 mt-9 flex items-center pl-6">
                        <HiOutlineMail color="#04D1BE" className="h-6 w-6" />
                      </span>
                    </div>
                    <div className="relative">
                      <label className="block mb-2 text-lg font-medium ">
                        Enter your Password
                      </label>
                      <input
                        id="changePass"
                        value={password}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onCopy={(e) => e.preventDefault()}
                        onChange={(e) => handleChangePassword(e)}
                        onPaste={(e) => e.preventDefault()}
                        type={`${showNewPass ? "password" : "text"}`}
                        placeholder="Enter your Password"
                        className="reletive border text-gray-900 lg:text-lg text-sm rounded-lg block w-full p-2.5 placeholder-gray-400"
                        required=""
                      />
                      <span
                        onClick={() => setShowNewPass(!showNewPass)}
                        className="absolute inset-y-0 right-4 mt-6 flex items-center pl-6 cursor-pointer"
                      >
                        {showNewPass ? (
                          <MdOutlineLock color="#04D1BE" className="h-6 w-6" />
                        ) : (
                          <MdOutlineLockOpen
                            color="#04D1BE"
                            className="h-6 w-6"
                          />
                        )}
                      </span>
                      {isTooltipOpen && (
                        <div className="absolute top-full left-0 mt-2 w-full z-40 bg-gray-400 p-2 rounded-lg shadow-lg">
                          <div className="text-white text-sm">
                            Password must contain:
                          </div>
                          <ul className="text-white text-sm mt-1">
                            <li
                              className={hasUppercase ? "text-green-400" : ""}
                            >
                              At least one uppercase letter
                            </li>
                            <li
                              className={hasLowercase ? "text-green-400" : ""}
                            >
                              At least one lowercase letter
                            </li>
                            <li className={hasNumber ? "text-green-400" : ""}>
                              At least one number
                            </li>
                            <li className={hasSymbol ? "text-green-400" : ""}>
                              At least one symbol
                            </li>
                            <li
                              className={
                                password.length >= 8 ? "text-green-400" : ""
                              }
                            >
                              At least 8 characters
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                    <div className="relative">
                      <label className="block mb-2 text-lg font-medium ">
                        Confirm Password
                      </label>
                      <input
                        id="confirmPass"
                        onChange={(e) => handleChangePassword(e)}
                        onCopy={(e) => e.preventDefault()}
                        onPaste={(e) => e.preventDefault()}
                        type={`${showConfirmPass ? "password" : "text"}`}
                        placeholder="Confirm Password"
                        className="reletive border text-gray-900 lg:text-lg text-sm rounded-lg block w-full p-2.5 placeholder-gray-400"
                        required=""
                      />
                      <span
                        onClick={() => setShowConfirmPass(!showConfirmPass)}
                        className="absolute inset-y-0 right-4 mt-6 flex items-center pl-6 cursor-pointer"
                      >
                        {showConfirmPass ? (
                          <MdOutlineLock color="#04D1BE" className="h-6 w-6" />
                        ) : (
                          <MdOutlineLockOpen
                            color="#04D1BE"
                            className="h-6 w-6"
                          />
                        )}
                      </span>
                    </div>
                    <span className="text-[red]/80 text-[12px] h-[15px]">
                      {errorMessage && errorMessage.newpass
                        ? errorMessage.newpass
                        : ""}
                    </span>
                    <button
                      disabled={loginLoading || disabledBtn ? true : false}
                      type="submit"
                      className={`w-full text-white font-semibold rounded-lg text-lg px-5 py-2.5 text-center ${
                        loginLoading || disabledBtn
                          ? "cursor-not-allowed bg-[#04D1BE]/50"
                          : "bg-[#04D1BE]"
                      }`}
                    >
                      {loginLoading ? (
                        <ButtonLoader text={"Signing In"} />
                      ) : (
                        "Sign " + "Up"
                      )}
                    </button>
                  </form>
                  <div className="m-1">
                    <span>
                      Already Registered ?
                      <button
                        className="mx-1 font-bold text-[#01bf97]"
                        onClick={() => navigate("/chat/login")}
                      >
                        Log In
                      </button>
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-700 md:text-2xl pb-2 lg:text-2xl">
                    Verify Email
                  </h1>
                  <form
                    onSubmit={submitVerifyEmail}
                    className="space-y-4 md:space-y-6"
                  >
                    <div className="relative">
                      <label className="block mb-2 text-lg font-medium ">
                        Code
                      </label>
                      <input
                        onChange={(e) => handleCode(e)}
                        name="code"
                        className="reletive border text-gray-900 lg:text-lg text-sm rounded-lg block w-full p-2.5 placeholder-gray-400"
                        placeholder="Enter Your Code"
                        required=""
                      />
                      <span className="absolute inset-y-0 right-4 mt-9 flex items-center pl-6">
                        <MdOutlineLock color="#04D1BE" className="h-6 w-6" />
                      </span>
                    </div>
                    <button
                      disabled={changeLoading ? true : false}
                      type="submit"
                      className={`w-full text-white font-semibold rounded-lg text-sm px-5 py-2.5 text-center ${"bg-[#04D1BE]"}`}
                    >
                      {changeLoading ? (
                        <ButtonLoader text={"Verifing email"} />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </form>
                </>
              )}
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatRegister;
