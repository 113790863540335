import { GetOperatorsByOwnerAddress } from "../Apis/ApiService";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
    totalRecords: null,
    totalPages: null,
    isLoading: false,
    error: null,
};
export const OperatorByAddressSlice = createSlice({
    name: "operatorsByAddress",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetOperatorsByOwnerAddress.pending, (state, action) => {
                if (action?.meta?.arg?.limit && action?.meta?.arg?.page) {
                    if (action?.meta?.arg?.limit * action?.meta?.arg?.page <= state?.data?.length) {
                        state.isLoading = false;
                    } else {
                        state.isLoading = true;
                    }
                }
            })
            .addCase(GetOperatorsByOwnerAddress.fulfilled, (state, action) => {
                state.data = [...state.data, ...action.payload.val.filter(obj2 => !state.data.some(obj1 => obj1._id === obj2._id))];
                state.totalRecords = action.payload.totalRecords;
                state.totalPages = action.payload.totalPages;
                state.isLoading = false;
            })
            .addCase(GetOperatorsByOwnerAddress.rejected, (state, action) => {
                state.error = action.error.message;
                state.isLoading = false;
            });
    },

});

export default OperatorByAddressSlice.reducer;