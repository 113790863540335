import {
  GetBlockData,
  GetDuties,
  GetInputData,
  GetOperators,
  GetOperatorsByOwnerAddress,
  GetQueryInputData,
  GetValidators,
  GetValidatorsByOwnerAddress,
} from "../../Redux/Apis/ApiService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import TableRowsLoader from "../../Helpers/TableLoader";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useDispatch } from "react-redux";
import Table from "@mui/material/Table";
import { Tooltip } from "@mui/material";
import * as React from "react";

export default function StickyHeadTable({
  rows,
  columns,
  heading,
  isLoading,
  tableId,
  pagiNation,
  Footer,
  totalRecords,
  totalPages,
}) {
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { source, operatorId, key, address } = useParams();
  const [page, setPage] = React.useState(0);
  const { state } = useLocation();
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (tableId === "Block") {
      dispatch(GetBlockData({ page: page + 1, limit: rowsPerPage }));
    } else if (tableId === "Txn") {
      dispatch(GetInputData({ page: page + 1, limit: rowsPerPage }));
    } else if (tableId === "AddressTxn") {
      dispatch(
        GetQueryInputData({
          ...state,
          ...{ page: page + 1, limit: rowsPerPage },
        })
      );
    } else if (tableId === "Operator") {
      dispatch(
        GetOperators({
          page: page + 1,
          limit: rowsPerPage,
          id: operatorId ? operatorId : null,
        })
      );
    } else if (tableId === "Validator") {
      dispatch(
        GetValidators({ ...{ page: page + 1, limit: rowsPerPage }, ...{ key } })
      );
    } else if (tableId === "operatorsByAddress") {
      dispatch(
        GetOperatorsByOwnerAddress({
          ...{ page: page + 1, limit: rowsPerPage },
          ...{ value: address },
        })
      );
    } else if (tableId === "validatorsByAddress") {
      dispatch(
        GetValidatorsByOwnerAddress({
          ...{ page: page + 1, limit: rowsPerPage },
          ...{ value: address },
        })
      );
    } else if (tableId === "Duties") {
      dispatch(GetDuties({ ...{ page: page + 1, limit: rowsPerPage }, ...{ key, parameter: "publicKey" } }))
    }
  }, [dispatch, rowsPerPage, page, state, operatorId, key,]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const NavigateHandler = (id, value) => {
    if (id === "hash" || id === "transactionHash") {
      Navigate(`/explorer/${source}/txn/${value}`, {
        state: {
          parameter: id,
          value,
        },
      });
    } else if (id === "blockNumber") {
      Navigate(`/explorer/${source}/blockNumber/${value}`, {
        state: {
          parameter: id,
          value,
        },
      });
    } else if (id === "from" || id === "to" || id === "ownerAddress") {
      Navigate(`/explorer/${source}/address/${value}`, {
        state: {
          parameter: id,
          value,
        },
      });
    } else if (id === "operatorId" || id === "id") {
      Navigate(`/explorer/${source}/operators/${value}`, {
        state: {
          parameter: id,
          value,
        },
      });
    } else if (id === "publicKey") {
      Navigate(`/explorer/${source}/validators/${value}`, {
        state: {
          parameter: id,
          value,
        },
      });
    } else if (id === "blockHash") {
      Navigate(`/explorer/${source}/blockHash/${value}`, {
        state: {
          parameter: id,
          value,
        },
      });
    }
  };

  return (
    <div className="bg-white rounded-3xl shadow-xl px-4 py-2">
      {heading && (
        <div className="px-3 py-4 w-full bg-white border-b border-gray-200 drop-shadow-sm">
          <span className="text-2xl font-extrabold text-gray-800 tracking-tight font-mono uppercase">
            {heading}
          </span>
        </div>
      )}
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCell
                  sx={{
                    fontFamily: "monospace",
                    fontSize: "17px",
                    fontWeight: "800",
                  }}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRowsLoader rowsNum={rowsPerPage} colsNum={columns.length} />
            ) : (
              rows
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={
                              column.style ? column.style : { color: "gray" }
                            }
                          >
                            {/* <div className='flex justify-start space-x-1 text-center items-center'> */}
                            <Tooltip
                              open={column.toolTip}
                              title={value}
                              placement="top"
                              arrow
                            >
                              <span
                                className={`${column.anchor
                                  ? "hover:underline cursor-pointer"
                                  : ""
                                  } font-[550] text-sm `}
                                onClick={() =>
                                  NavigateHandler(column.id, value)
                                }
                              >
                                {column.format
                                  ? column.format(value, row)
                                  : value}
                              </span>
                            </Tooltip>
                            {/* {
                                                                column.copy
                                                                &&
                                                                <CopyButton value={value} />
                                                            } */}
                            {/* </div> */}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {rows.length || isLoading ? (
        ""
      ) : (
        <div className="flex justify-center items-center text-center py-4">
          <span>No Records Found !!</span>
        </div>
      )}
      {pagiNation && rows.length ? (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalRecords ? totalRecords : -1}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
      {Footer && rows.length ? <Footer /> : null}
    </div>
  );
}
