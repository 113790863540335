import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { GetDataSources } from '../../Redux/Apis/ApiService';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../Redux/Slices/userSlice';
import AuthService from '../../Services/AuthService';
import React, { useEffect, useState } from 'react'
import logo from '../../Assets/images/logo.svg'
import { Skeleton } from "@mui/material"

export default function AppBar() {
    const [options, setOptions] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { source } = useParams()
    const dataSource = useSelector((state) => state.sources)

    useEffect(() => {
        dispatch(GetDataSources())
    }, [dispatch])

    const HandleSourceSwitch = (item) => {
        localStorage.setItem("source", JSON.stringify(item))
        navigate('/')
        setTimeout(() => {
            window.location.reload()
        }, 100)
    }


    const SignOutInHandler = (id) => {
        if (id === "LoggedIn") {
            navigate('/login')
        } else if (id === "LoggedOut") {
            AuthService.Logout()
            logout("user/logout")
        }
    }

    return (
        <nav className="border-gray-200 fixed z-[1100] top-0 w-full bg-white shadow-md" >
            <div className="lg:px-28 flex flex-wrap items-center justify-between mx-auto p-4">
                <NavLink to={`/explorer/${source}/`} className="flex items-center">
                    <img src={logo} className="h-8 mr-3" alt="Coindelta Logo" />
                </NavLink>
                <button data-collapse-toggle="navbar-dropdown" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm  rounded-lg md:hidden  focus:outline-none focus:ring-2 text-gray-400 hover:bg-gray-700 focus:ring-gray-600" aria-controls="navbar-dropdown" aria-expanded="false">
                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                    </svg>
                </button>
                <div className="hidden w-full md:block md:w-auto">
                    <ul className="flex flex-col justify-center items-center text-center font-medium p-4 md:p-0 mt-4 md:flex-row md:space-x-8 md:mt-0">
                        <li>
                            <NavLink to={`/explorer/${source}/`} className={`block py-2 pl-3 pr-4 rounded md:p-0 bg-blue-600 md:bg-transparent`}>Home</NavLink>
                        </li>
                        <li>
                            <NavLink to={`/explorer/${source}/blocks`} className="block py-2 pl-3 pr-4 rounded  md:border-0 md:p-0 md:hover:text-blue-500 hover:bg-gray-700 hover:text-white md:hover:bg-transparent">Blocks</NavLink>
                        </li>
                        <li>
                            <NavLink to={`/explorer/${source}/txns`} className="block py-2 pl-3 pr-4 rounded  md:border-0 md:p-0 md:hover:text-blue-500 hover:bg-gray-700 hover:text-white md:hover:bg-transparent">Transactions</NavLink>
                        </li>
                        <li>
                            <NavLink to={`/explorer/${source}/operators`} className="block py-2 pl-3 pr-4 rounded  md:border-0 md:p-0 md:hover:text-blue-500 hover:bg-gray-700 hover:text-white md:hover:bg-transparent">Operators</NavLink>
                        </li>
                        <li>
                            <NavLink to={`/explorer/${source}/validators`} className="block py-2 pl-3 pr-4 rounded  md:border-0 md:p-0 md:hover:text-blue-500 hover:bg-gray-700 hover:text-white md:hover:bg-transparent">Validators</NavLink>
                        </li>
                        <li title='Source Name'>
                            <div
                                className='border-2 relative bg-[#FAFAFB] cursor-pointer border-[#04C2AE] px-3 py-1 flex justify-center items-center text-center rounded-lg '
                                onMouseEnter={() => setOptions(!options)}
                                onMouseLeave={() => setOptions(!options)}
                            >
                                <span className='text-sm text-[#04C2AE]'>{source}</span>
                            </div>
                            {
                                options &&
                                <div
                                    onMouseEnter={() => setOptions(true)}
                                    onMouseLeave={() => setOptions(false)}
                                    className="z-10 absolute font-normal rounded-lg shadow-xl px-3 py-1 bg-gray-100 flex text-center justify-center">
                                    {
                                        dataSource?.isLoading
                                            ?
                                            <span className='w-28 space-y-3 py-1'>
                                                <Skeleton />
                                                <Skeleton animation="wave" />
                                            </span>
                                            :
                                            <ul className="py-1 text-sm text-gray-700">
                                                {
                                                    dataSource?.data?.length && dataSource?.data?.map((item, index) => (
                                                        <li key={index}>
                                                            <button
                                                                disabled={source === item.dataSource ? true : false}
                                                                onClick={() => HandleSourceSwitch(item)} className={`block px-4 py-2  ${source === item.dataSource ? "text-[#04C2AE]" : "hover:text-gray-400"}`}>{item?.dataSource}</button>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                    }

                                </div>
                            }
                        </li>
                        {/* <li>
                            <NavLink to={"/setting"} className="block py-2 pl-3 pr-4 rounded  md:border-0 md:p-0 md:hover:text-blue-500 hover:bg-gray-700 hover:text-white md:hover:bg-transparent">Contact</NavLink>
                        </li>
                        <li>
                            <button
                                onClick={() => SignOutInHandler(localStorage.getItem("accessToken") ? "LoggedOut" : "LoggedIn")}
                                className="flex items-center justify-between w-full py-2 pl-3 pr-4 rounded md:border-0 md:p-0 md:w-aut border-gray-700 hover:bg-gray-700 md:hover:bg-transparent">
                                {localStorage.getItem("accessToken") ? "Log Out" : "Log In"}
                                <svg className="w-2.5 h-2.5 ml-2.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
                                </svg>
                            </button>
                            {
                                options &&
                                <div className="z-10 absolute font-normal  divide-y divide-gray-100 rounded-lg shadow w-44">
                                    <ul className="py-2 text-sm text-gray-700 " aria-labelledby="dropdownLargeButton">
                                        <li>
                                            <NavLink to={'/'} className="block px-4 py-2 hover:bg-gray-600 hover:text-white">Dashboard</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'/'} className="block px-4 py-2 hover:bg-gray-600 hover:text-white">Settings</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={'/'} className="block px-4 py-2 hover:bg-gray-600 hover:text-white">Earnings</NavLink>
                                        </li>
                                    </ul>
                                    <div className="py-1">
                                        <NavLink to={'/'} className="block px-4 py-2 text-sm text-gray-700  hover:bg-gray-600  hover:text-white">Sign out</NavLink>
                                    </div>
                                </div>
                            }
                        </li> */}
                    </ul>
                </div>
            </div>
        </nav>
    )
}
