import { TxnDetails, TxnDetailsByAddress } from "./Components/TxnPages";
import { ValidatorInfo, ValidatorsTable } from "./Components/Validator";
import { OperatorsTable, OperatorInfo } from "./Components/Operator";
import { AllBlockTable, AllTxnTable } from "./Components/Tables";
import MainLayout from "./Components/Layout.jsx/MainLayout";
import { BlockDetails } from "./Components/BlockPage";
import { DataSources } from "./Components/DataSource";
import RedirectHome from "./Middleware/RedirectHome";
import CustomRoutes from "./Middleware/CustomRoutes";
import { Layout } from "./Components/GPTComponents";
import DashboardPage from "./Pages/DashboardPage";
import { Route, Routes } from "react-router-dom";
import LoginPage from "./Pages/LoginPage";
import Page404 from "./Components/404";
import AuthService from "./Services/AuthService";
import ChatLogin from "./Components/GPTComponents/Layout/chatlogin";
import ChatRegister from "./Components/GPTComponents/Layout/chatregister";

const routes = [
  { path: "/", component: DashboardPage },
  { path: "/txn/:txnId", component: TxnDetails },
  { path: "/block/:blockId", component: BlockDetails },
  { path: "/address/:address", component: TxnDetailsByAddress },
  { path: "/blockNumber/:blockNumber", component: TxnDetailsByAddress },
  { path: "/blockHash/:blockHash", component: TxnDetailsByAddress },
  { path: "/validators", component: ValidatorsTable },
  { path: "/operators", component: OperatorsTable },
  { path: "/blocks", component: AllBlockTable },
  { path: "/txns", component: AllTxnTable },
  { path: "/operators/:operatorId", component: OperatorInfo },
  { path: "/validators/:key", component: ValidatorInfo },
];

export default function Routers() {
  const authService = AuthService.get();
  const authType = authService.authenticationType();
  return (
    <>
      <CustomRoutes routes={routes} />
      <Routes>
        {/* Public Routes */}
        <Route exact path="/login" element={<LoginPage />} />
        <Route element={<MainLayout />}>
          <Route exact path="/dataSources" element={<DataSources />} />
        </Route>
        {/* Redirecting Routes */}
        <Route path="/" element={<RedirectHome />} />
        <Route path="/explorer" element={<RedirectHome />} />
        <Route path="/explorer/:source" element={<RedirectHome />} />
        {/* GPT Routes */}
        {authType === "private" ? (
          <></>
        ) : (
          <>
            <Route path="/chat" element={<Layout />} />
            <Route path="/chat/:id" element={<Layout />} />
            <Route path="/chat/login" element={<ChatLogin />} />
            <Route path="/chat/signup" element={<ChatRegister />} />
          </>
        )}
      </Routes>
    </>
  );
}
