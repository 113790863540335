import { GetDataSources } from "../Apis/ApiService";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: {},
    isLoading: false,
    error: null,
};
export const DataSourcesSlice = createSlice({
    name: "dataSources",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetDataSources.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(GetDataSources.fulfilled, (state, action) => {
                state.data = action.payload;
                if (!localStorage.getItem("source")) {
                    localStorage.setItem("source", JSON.stringify(action.payload[0]))
                }
                state.isLoading = false;
                state.error = null;
            })
            .addCase(GetDataSources.rejected, (state, action) => {
                state.error = action.error.message;
                state.data = action.payload;
                state.isLoading = false;
            });
    },
});

export default DataSourcesSlice.reducer;