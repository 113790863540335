import { useDispatch } from "react-redux";
import { createInputData } from "../../Apis/EndPoints";
import { GetDataSources } from "../../Redux/Apis/ApiService";
import ApiServices from "../../Services/ApiServices";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function AddSourceModal({ showModal, setShowModal }) {
    const [disableBTN, setDisableBTN] = useState(false)
    const [sourceName, setSourceName] = useState('')
    const [loading, setLoading] = useState(false)
    const [address, setAddress] = useState('')
    const [nodeUrl, setNodeURL] = useState('')
    const dispatch = useDispatch()

    const OnChangeHandler = (e) => {
        const { id, value } = e.target;
        if (id === "name") {
            setSourceName(value)
        } else if (id === "url") {
            setNodeURL(value)
        } else if (id === "address") {
            setAddress(value)
        }
    }

    useEffect(() => {
        if (!sourceName || !address || !nodeUrl) {
            setDisableBTN(true)
        } else {
            setDisableBTN(false)
        }
    }, [sourceName, address, nodeUrl])

    const SubmitHandler = (e) => {
        e.preventDefault()
        setLoading(true)
        const useId = JSON.parse(localStorage.getItem("id"))
        ApiServices.post(`${createInputData}?userId=${useId}`, { dataSource: sourceName, address, nodeUrl }).then((res) => {
            setShowModal(false)
            setLoading(false)
            dispatch(GetDataSources())
            toast.success("Successfully data source created!!")
        }).catch((err) => {
            console.log(err);
            setLoading(false)
            toast.error("Something went wrong! while creating data source")
        })
    }
    return (
        <>
            {
                showModal
                &&
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto backdrop-invert-0 bg-black/50 fixed inset-0 z-50 outline-none focus:outline-none" >
                    <div className=" w-full max-w-lg md:h-auto">
                        <div className="relative rounded-xl shadow">
                            <button disabled={loading ? true : false}
                                onClick={() => setShowModal(false)}
                                type="button"
                                className={`${loading ? "hidden" : "absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"}`}>
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </button>
                            <div className="px-6 py-2 lg:px-2 bg-white rounded-xl ">
                                <div className="lg:text-left text-center w-full py-8">
                                    <div className="flex items-center justify-center w-full">
                                        <form onSubmit={(e) => SubmitHandler(e)} className="flex flex-col space-y-4 w-full px-10">
                                            <label className="font-bold text-lg" >Source Name</label>
                                            <input onChange={(e) => OnChangeHandler(e)} id="name" type="text" placeholder="Source Name" className="border rounded-lg py-2 px-2 placeholder-white-500" />
                                            <label className="font-bold text-lg ">Node URL</label>
                                            <input onChange={(e) => OnChangeHandler(e)} id="url" type="url" placeholder="NodeURL" className="border rounded-lg py-2 px-2 placeholder-white-500 " />
                                            <label className="font-bold text-lg  " >Address</label>
                                            <input onChange={(e) => OnChangeHandler(e)} id="address" type="text" placeholder="Address" className="border rounded-lg py-2 px-2 placeholder-white-500 " />
                                            <button
                                                disabled={loading || disableBTN ? true : false}
                                                className={`w-full font-semibold rounded-lg text-md px-5 py-3 text-center  ${loading || disableBTN ? "cursor-not-allowed bg-[#04D1BE]/20 disabled text-gray-500 " : "bg-[#04D1BE] text-white "}`} >
                                                {loading ? "Creating Data Source" : "Create"}
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
