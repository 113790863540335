import convertTimestampToRelativeTime from "../../Utils/convertRelativeTimeStamp";
import { GetLogsData, GetQueryInputData } from "../../Redux/Apis/ApiService";
import calculateTransactionFee from "../../Utils/calculateTransactionFee";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MdSettingsBackupRestore } from "react-icons/md";
import { RotateLoading } from "../../Assets/Icons/Icons";
import { useDispatch, useSelector } from "react-redux";
import CopyButton from "../../Helpers/CopyButton";
import { TextareaAutosize } from "@mui/material";
import SearchBar from "../../Helpers/SearchBar";
import { MdAccessTime } from "react-icons/md";
import { useEffect, useState } from "react";
import { FiCodepen } from "react-icons/fi";
import { FaPlus } from "react-icons/fa";

export default function TxnDetails() {
  const headings = [
    ["Transaction Hash:", "Status:", "Block:", "Timestamp:"],
    ["From:", "To"],
    ["Value:", "Transaction Fee", "Gas Price:"],
    ["Other Attributes:", "Input Data:"],
  ];
  const [showDecodedData, setShowDecodedData] = useState(false);
  const [tabing, setTTabing] = useState({ tab: "dec" });
  const [tab, setTab] = useState("Overview");
  const [showMore, setShowMore] = useState(false);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const { source } = useParams();
  const txnDetails = useSelector((state) => state.queryInputData);

  useEffect(() => {
    document.title = "Block Sail | Transaction Details";
  }, []);

  useEffect(() => {
    dispatch(GetQueryInputData(state));
  }, [dispatch]);

  return (
    <>
      {txnDetails.isLoading ? (
        <RotateLoading />
      ) : (
        <div className="w-full mb-8">
          <div className="py-2 mb-4 flex justify-between w-full items-center lg:flex-row flex-col lg:space-y-0 space-y-2">
            <h1 className="text-xl font-[500] text-gray-800">
              Transaction Details
            </h1>
            <div className="max-w-xl w-full">
              <SearchBar id={"Small"} />
            </div>
          </div>
          <div className="space-y-4">
            <div>
              <div className="flex space-x-2">
                <button
                  onClick={() => setTab("Overview")}
                  className={`${tab === "Overview"
                    ? "bg-[#04C2AE] text-white text-center"
                    : "bg-slate-200 text-center"
                    } rounded-md px-2 py-1 font-medium tracking-tight text-sm hover:bg-[#04C2AE] hover:text-white text-center`}
                >
                  Overview
                </button>
                {txnDetails?.data[0]?.logs?.length ? (
                  <button
                    onClick={() => setTab("Logs")}
                    className={`${tab === "Logs"
                      ? "bg-[#04C2AE] text-white text-center"
                      : "bg-slate-200 text-center"
                      } rounded-md px-2 py-1 font-medium tracking-tight text-sm hover:bg-[#04C2AE] hover:text-white text-center`}
                  >
                    {`Logs (${txnDetails?.data?.length &&
                      txnDetails?.data[0]?.logs?.length
                      ? txnDetails?.data[0]?.logs?.length
                      : ""
                      })`}
                  </button>
                ):null}
              </div>
            </div>
            {tab === "Overview" && txnDetails?.data?.length && (
              <div className="space-y-4">
                <div className="w-full rounded-xl px-6 py-6 bg-white border shadow-sm">
                  <div className="divide-y space-y-2">
                    <div className="flex space-x-16 w-full">
                      <div className="flex flex-col w-[300px]">
                        {headings[0]?.map((head, i) => (
                          <span
                            key={i}
                            className="px-2 py-2 text-ellipsis text-gray-500 font-medium"
                          >
                            {head}
                          </span>
                        ))}
                      </div>
                      <div className="flex flex-col">
                        <div className="flex">
                          <span className="px-2 py-2 text-gray-800 text-clip">
                            {txnDetails?.data[0]?.hash}
                          </span>
                          <CopyButton value={txnDetails?.data[0]?.hash} />
                        </div>
                        <div className="py-2 px-2">
                          <span className="px-2 py-1 text-green-600 border text-ellipsis text-xs w-fit  font-medium border-green-500 rounded-md bg-green-100">
                            {"Success"}
                          </span>
                        </div>
                        <span
                          onClick={() =>
                            Navigate(
                              `/explorer/${source}/blockNumber/${txnDetails?.data[0]?.blockNumber}`,
                              {
                                state: {
                                  parameter: "blockNumber",
                                  value: txnDetails?.data[0]?.blockNumber,
                                },
                              }
                            )
                          }
                          className="px-2 py-2 text-[#04C2AE] text-clip cursor-pointer hover:underline"
                        >
                          {txnDetails?.data[0]?.blockNumber}
                        </span>
                        <div className="py-2 px-2 flex space-x-1">
                          <MdAccessTime className="text-gray-600 mt-2" />
                          <span className="py-1 text-gray-800 text-clip">
                            {convertTimestampToRelativeTime(
                              txnDetails?.data[0]?.blockTimeStamp
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex space-x-16 w-full">
                      <div className="flex flex-col w-[300px]">
                        {headings[1]?.map((head, i) => (
                          <span
                            key={i}
                            className="px-2 py-2 text-ellipsis text-gray-500 font-medium"
                          >
                            {head}
                          </span>
                        ))}
                      </div>
                      <div className="flex flex-col">
                        <div className="flex">
                          <span
                            onClick={() =>
                              Navigate(
                                `/explorer/${source}/address/${txnDetails?.data[0]?.from}`,
                                {
                                  state: {
                                    parameter: "from",
                                    value: txnDetails?.data[0]?.from,
                                  },
                                }
                              )
                            }
                            className="px-2 py-2 text-[#04C2AE] text-clip hover:underline cursor-pointer"
                          >
                            {txnDetails?.data[0]?.from}
                          </span>
                          <CopyButton value={txnDetails?.data[0]?.from} />
                        </div>
                        <div className="flex">
                          <span
                            onClick={() =>
                              Navigate(
                                `/explorer/${source}/address/${txnDetails?.data[0]?.to}`,
                                {
                                  state: {
                                    parameter: "to",
                                    value: txnDetails?.data[0]?.to,
                                  },
                                }
                              )
                            }
                            className="px-2 py-2 text-[#04C2AE] text-clip hover:underline cursor-pointer"
                          >
                            {txnDetails?.data[0]?.to}
                          </span>
                          <CopyButton value={txnDetails?.data[0]?.to} />
                        </div>
                      </div>
                    </div>
                    <div className="flex space-x-16 w-full">
                      <div className="flex flex-col w-[300px]">
                        {headings[2]?.map((head, i) => (
                          <span
                            key={i}
                            className="px-2 py-2 text-ellipsis text-gray-500 font-medium"
                          >
                            {head}
                          </span>
                        ))}
                      </div>
                      <div className="flex flex-col">
                        <div className="flex">
                          <span className="px-2 py-2 text-gray-800 text-clip space-x-1">
                            {parseInt(txnDetails?.data[0]?.value, 16)}{" "}
                          </span>{" "}
                          <span className="font-medium py-2 text-gray-600 text-clip ">
                            ETH
                          </span>
                        </div>
                        <div className="flex">
                          <span className="px-2 py-2 text-gray-800 text-clip space-x-1">
                            {calculateTransactionFee(
                              txnDetails?.data[0]?.gasUsed,
                              txnDetails?.data[0]?.gasPrice,
                              16
                            )
                              ? calculateTransactionFee(
                                txnDetails?.data[0]?.gasUsed,
                                txnDetails?.data[0]?.gasPrice
                              )
                              : 0}{" "}
                          </span>
                          <span className="font-medium py-2 text-gray-600 text-clip ">
                            ETH
                          </span>
                        </div>
                        <div className="flex">
                          <span className="px-2 py-2 text-gray-800 text-clip space-x-1">
                            {txnDetails?.data[0]?.gasPrice / 1000000000}{" "}
                          </span>
                          <span className="font-medium px-2 py-2 text-gray-600 text-clip ">
                            Gwei
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full rounded-xl px-6 py-4 bg-white border shadow-sm">
                  <div className="flex w-full flex-col">
                    {showMore ? (
                      <div className="divide-y space-y-2 border-b">
                        {txnDetails?.data[0]?.maxFeePerGas ||
                          txnDetails?.data[0]?.maxPriorityFeePerGas ? (
                          <div className="flex space-x-16">
                            <div className="flex flex-col w-[300px]">
                              <span className="px-2 py-2 text-ellipsis text-gray-500 font-medium">
                                {"Gas Fees:"}
                              </span>
                            </div>
                            <div className="flex flex-col">
                              <div>
                                {txnDetails?.data[0]?.maxFeePerGas && (
                                  <span className="px-2 py-2 text-gray-800 text-clip">
                                    Max :{" "}
                                    {txnDetails?.data[0]?.maxFeePerGas /
                                      1000000000}{" "}
                                    Gwei
                                  </span>
                                )}
                                {txnDetails?.data[0]?.maxPriorityFeePerGas && (
                                  <span className="px-2 py-2 text-gray-800 text-clip">
                                    Max Priority :{" "}
                                    {txnDetails?.data[0]?.maxPriorityFeePerGas /
                                      1000000000}{" "}
                                    Gwei
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="flex space-x-16 py-2">
                          <div className="flex flex-col w-[300px]">
                            {headings[3]?.map((head, i) => (
                              <span
                                key={i}
                                className="px-2 py-2 text-ellipsis text-gray-500 font-medium"
                              >
                                {head}
                              </span>
                            ))}
                          </div>
                          <div className="flex flex-col lg:w-[1000px] justify-between">
                            <div className="flex space-x-2 py-2">
                              <div className="border px-2 py-0.5 w-fit rounded-lg bg-slate-100 ">
                                <div className="flex space-x-1 text-center justify-center items-center">
                                  <span className="text-xs text-gray-500 text-center">
                                    Txn Type :
                                  </span>
                                  <span className="text-sm text-center pt-0.5">
                                    {`${parseInt(txnDetails?.data[0]?.type)} (${parseInt(txnDetails?.data[0]?.type) === 1
                                      ? "EIP-2930"
                                      : parseInt(
                                        txnDetails?.data[0]?.type
                                      ) === 2
                                        ? "EIP-1559"
                                        : "EIP-2718"
                                      })`}
                                  </span>
                                </div>
                              </div>
                              <div className="border px-2 py-0.5 w-fit rounded-lg bg-slate-100">
                                <div className="flex space-x-1 text-center justify-center items-center">
                                  <span className="text-xs text-gray-500 text-center">
                                    Nonce :
                                  </span>
                                  <span className="text-sm text-center pt-0.5">
                                    {parseInt(txnDetails?.data[0]?.nonce)}
                                  </span>
                                </div>
                              </div>
                              <div className="border px-2 py-0.5 w-fit rounded-lg bg-slate-100">
                                <div className="flex space-x-1 text-center justify-center items-center">
                                  <span className="text-xs text-gray-500 text-center">
                                    Position in Block :
                                  </span>
                                  <span className="text-sm text-center pt-0.5">
                                    {txnDetails?.data[0]?.transactionIndex}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="w-full py-2 space-y-1">
                              <TextareaAutosize
                                maxRows={10}
                                value={
                                  showDecodedData
                                    ? JSON.stringify(
                                      txnDetails?.data[0]?.decodeData
                                    )
                                    : txnDetails?.data[0]?.input
                                }
                                disabled
                                className=" w-full p-2 text-xs border-1 rounded-lg bg-slate-100 focus:border-[#04C2AE]/20 ring-2 ring-gray-300 outline-none text-gray-600"
                              />
                              {txnDetails?.data[0]?.decodeData && (
                                <button
                                  onClick={() =>
                                    setShowDecodedData(!showDecodedData)
                                  }
                                  className="px-2 flex py-1 text-gray-600 tracking-tight text-sm font-normal border rounded-md bg-gray-200 hover:bg-gray-100"
                                >
                                  {showDecodedData ? (
                                    <>
                                      <MdSettingsBackupRestore className="text-lg" />
                                      <span className="pl-1">Switch Back</span>
                                    </>
                                  ) : (
                                    <>
                                      <FiCodepen className="text-lg" />
                                      <span className="pl-1">
                                        Decode Input Data
                                      </span>
                                    </>
                                  )}
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="flex w-full space-x-16">
                      <div className="flex flex-col w-[300px]">
                        <span className="px-2 py-2 text-ellipsis text-gray-500 font-medium">
                          More Details
                        </span>
                      </div>
                      <div className="flex justify-start">
                        <button
                          onClick={() => setShowMore(!showMore)}
                          className="px-2 py-2 text-blue-500 text-base font-semibold flex space-x-1 "
                        >
                          <span className="font-bold text-blue-500 mt-1">
                            <FaPlus />
                          </span>
                          <span>
                            Click to show {showMore ? "less" : "more"}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {tab === "Logs" && (
              <div className="w-full rounded-xl px-6 py-6 bg-white border shadow-sm">
                <div className="divide-y space-y-2">
                  <div className="flex w-full flex-col px-2">
                    <p className="text-base text-gray-800">
                      Transaction Receipt Event Logs
                    </p>
                    <div className="flex flex-col space-y-6 divide-y w-full">
                      {txnDetails?.data[0]?.logs?.map((item, i) => (
                        <div className="flex space-x-8 mt-2 py-4" key={i}>
                          <div className="w-14 h-14 rounded-full bg-green-100 flex justify-center text-center items-center font-semibold text-green-400">
                            {parseInt(item?.logIndex)}
                          </div>
                          <div className="mt-2 space-y-2 w-full">
                            <div className="flex space-x-4">
                              <span className="text-base font-medium min-w-[70px]">
                                Address
                              </span>
                              <span
                                className="text-base font-normal text-[#04C2AE] cursor-pointer"
                                onClick={() =>
                                  Navigate(
                                    `/explorer/${source}/address/${item?.address}`,
                                    {
                                      state: {
                                        parameter: "address",
                                        value: item?.address,
                                      },
                                    }
                                  )
                                }
                              >
                                {item?.address}
                              </span>
                            </div>
                            <div className="flex space-x-4">
                              <span className="text-base font-medium text-gray-500 min-w-[70px]">
                                Topics{" "}
                              </span>
                              <div className="flex flex-col space-y-1 w-full">
                                {item?.topics?.length &&
                                  item?.topics.map((val, index) => (
                                    <div key={index} className="flex space-x-2">
                                      <span className="px-2 py-1 text-xs text-center border border-gray-200 bg-gray-100 rounded-md">
                                        {index}
                                      </span>
                                      <span className="text-sm font-normal text-gray-600 cursor-pointer">
                                        {val}
                                      </span>
                                    </div>
                                  ))}
                              </div>
                            </div>
                            <div className="flex space-x-4 w-full">
                              <span className="text-base text-gray-500 font-medium min-w-[70px] py-3">
                                Data{" "}
                              </span>
                              <div className="flex justify-between items-center text-center w-full space-x-52">
                                <div className="flex py-3 px-4 rounded-lg w-full">
                                  <TextareaAutosize
                                    maxRows={10}
                                    value={
                                      tabing.tab === "hex" && tabing.id === i
                                        ? item?.data
                                        : item?.decodeLog?.length
                                          ? JSON.stringify(item?.decodeLog)
                                          : item?.data === "0x"
                                            ? `${"0"}`
                                            : `${parseInt(item?.data, 16)}`
                                    }
                                    disabled
                                    className=" w-full p-2 text-xs border-1 rounded-lg bg-slate-100 focus:border-[#04C2AE]/20 ring-2 ring-gray-300 outline-none text-gray-600"
                                  />
                                </div>
                                <ul className="text-sm font-medium text-center text-gray-500 divide-x divide-gray-200 rounded-lg sm:flex flex max-h-4">
                                  <li className="w-fit">
                                    <button
                                      onClick={() =>
                                        setTTabing({ tab: "dec", id: i })
                                      }
                                      className={`inline-block w-full px-3 py-1 text-gray-900 rounded-l-lg ${tabing.tab == "dec" && tabing.id === i
                                        ? "bg-gray-300"
                                        : "hover:bg-gray-300 bg-gray-100"
                                        } `}
                                    >
                                      Dec
                                    </button>
                                  </li>
                                  <li className="w-fit">
                                    <button
                                      onClick={() =>
                                        setTTabing({ tab: "hex", id: i })
                                      }
                                      className={`inline-block w-full px-3 py-1 text-gray-900 rounded-r-lg ${tabing.tab == "hex" && tabing.id === i
                                        ? "bg-gray-300"
                                        : "hover:bg-gray-300 bg-gray-100"
                                        } `}
                                    >
                                      Hex
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
