import { GetQueryInputData } from "../Apis/ApiService";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
    isLoading: false,
    totalRecords: null,
    totalPages: null,
    error: null,
};
export const Query = createSlice({
    name: "queryInputData",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetQueryInputData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(GetQueryInputData.fulfilled, (state, action) => {
                if (action.payload?.data?.length > 1) {
                    state.data = [...state?.data, ...action?.payload?.data?.filter(obj2 => !state?.data?.some(obj1 => obj1._id === obj2._id))];
                    state.totalRecords = action?.payload?.totalRecords;
                    state.totalPages = action?.payload?.totalPages;
                } else {
                    state.data = action?.payload?.data;
                    state.totalRecords = action?.payload?.totalRecords;
                    state.totalPages = action?.payload?.totalPages;
                }
                state.isLoading = false;
            })
            .addCase(GetQueryInputData.rejected, (state, action) => {
                state.data = action.payload;
                state.error = action.error.message;
                state.isLoading = false;
            });
    },
});

export default Query.reducer;