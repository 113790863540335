import React from "react";

export function Tooltip({ children, tooltipText, orientation, width }) {
    const orientations = {
        right: 'right',
        top: 'top',
        left: 'left',
        bottom: 'bottom',
    }
    const tipRef = React.useRef(null);
    function handleMouseEnter() {
        tipRef.current.style.opacity = 1;
        tipRef.current.style.marginLeft = "20px";
    }
    function handleMouseLeave() {
        tipRef.current.style.opacity = 0;
        tipRef.current.style.marginLeft = "10px";
    }

    const setContainerPosition = (orientation) => {
        let classnames

        switch (orientation) {
            case orientations.right:
                classnames = '-top-1 left-full ml-4'
                break
            case orientations.left:
                classnames = '-top-1 right-full mr-4'
                break
            case orientations.top:
                classnames = 'bottom-full pb-2 -left-[50%] translate-x-[-50%] -translate-y-2'
                break
            case orientations.bottom:
                classnames = 'top-full -left-[50%] translate-x-[-50%] translate-y-2'
                break

            default:
                break
        }

        return classnames
    }

    const setPointerPosition = (orientation) => {
        let classnames

        switch (orientation) {
            case orientations.right:
                classnames = 'left-[-6px]'
                break
            case orientations.left:
                classnames = 'right-[-6px]'
                break
            case orientations.top:
                classnames = 'top-full left-[50%] translate-x-[-50%] -translate-y-2'
                break
            case orientations.bottom:
                classnames = 'bottom-full left-[50%] translate-x-[-50%] translate-y-2'
                break

            default:
                break
        }

        return classnames
    }

    const classContainer = `absolute text-center ${width ? width : "w-max"} z-10 ${setContainerPosition(
        orientation
    )} bg-gray-600 text-white text-sm px-2 py-1 rounded flex items-center transition-all duration-150 pointer-events-none`

    const pointerClasses = `bg-gray-600 h-3 w-3 absolute z-10 ${setPointerPosition(
        orientation
    )} rotate-45 pointer-events-none`

    return (
        <>
            {
                tooltipText
                    ?
                    <div className="relative flex items-center" onPointerEnter={handleMouseEnter} onPointerLeave={handleMouseLeave}>
                        <div className={classContainer} style={{ opacity: 0 }} ref={tipRef}>
                            <div className={pointerClasses} />
                            {tooltipText}
                        </div>
                        {children}
                    </div>
                    :
                    <>
                        {children}
                    </>
            }
        </>

    );
}