import MainLayout from '../Components/Layout.jsx/MainLayout';
import { Routes, Route } from 'react-router-dom';
import React from 'react';

const CustomRoutes = ({ routes }) => {
    return (
        <Routes >
            <Route element={<MainLayout />}>
                {routes.map((route) => (
                    <Route key={route.path} path={`/explorer/:source${route.path}`} element={<route.component />} />
                ))}
            </Route>
        </Routes>
    );
};

export default CustomRoutes;
